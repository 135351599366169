import { ScrollTopService } from './../../core/services/scroll-top.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BrandService } from 'src/app/core/services/brand.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-products',
  templateUrl: './brand-products.component.html',
  styleUrls: ['./brand-products.component.css']
})
export class BrandProductsComponent implements OnInit {

  brands: any;
  brandSlug: any;
  checked = false;
  dataSource: any;
  displayedColumns: any;
  disabled = false;
  error: any;
  filter_data: any;
  filteredItems: any;
  filteredItemsId = new Set();
  filteredAtt = new Set();
  filteredData: any;
  headerText:any;
  items: any;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  min = 100000000;
  max = 0;
  metaData: {
    property: string;
    content: string;
  }[] = [];
  metaTitle: string = '';
  paramsubs: any;
  panelOpenState = false;
  searchName: any;
  slider1: any;
  slider2: any;
  sorting: any;
  serverResponse = false;


  constructor(
    private brandService: BrandService,
    private route: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    private title: Title,
  ) { 
    this.searchName = {
      name: this.route.snapshot.params.name,
    };

    this.paramsubs = this.route.params.subscribe((params: Params) => {
      this.brandSlug = this.route.snapshot.params.brandSlug;
      this.items = [];
      this.serverResponse = false;
      this.searchName.name = params.name;
      this.brandService.getBrandedProducts(this.brandSlug).subscribe(
        (post: any) => {
          if (!post) {
            this.serverResponse = false;
          } else if (post === 500) {
            this.router.navigate(['internal-server-error']);
          } else {
            this.serverResponse = true;
            this.items = post.item_data;
            this.filteredItems = post.item_data;
            this.filter_data = post.filter_data;
            this.brands = post.brands;
            this.metaData = post.meta_data;
            this.metaTitle = post.title;
            this.headerText = post.header_text;

            this.meta.addTags(this.metaData);
            this.setTitle(this.metaTitle);

            this.min = 10000000;
            this.max = 0;
            for (const i in this.items) {
              if (+this.filteredItems[i].sale_price <= this.min) {
                this.min = +this.filteredItems[i].sale_price;
              }
              if (+this.filteredItems[i].sale_price >= this.max) {
                this.max = +this.filteredItems[i].sale_price;
              }
            }
            this.slider1 = this.min;
            this.slider2 = this.max;
            this.dataSource = [{ from: this.min, to: this.max }];
            this.displayedColumns = ['range', 'from', 'to'];
          }
        },
        (error: any) => {
          this.serverResponse = true;
          this.error = error;
        }
      );
    });
  }

  sortingProductList(event: any) {
    if (event === 'latest') {
      this.filteredItems = [...this.filteredItems].reverse();
    } else if (event === 'ascending') {
      this.filteredItems = [...this.filteredItems].sort(
        (a: any, b: any) => a.sale_price - b.sale_price
      );
    } else {
      this.filteredItems = [...this.filteredItems].sort(
        (a: any, b: any) => b.sale_price - a.sale_price
      );
    }
  }

  ngOnInit(): void {
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

}
