import { environment } from 'src/environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-order-history-details-mobile',
  templateUrl: './order-history-details-mobile.component.html',
  styleUrls: ['./order-history-details-mobile.component.css'],
})
export class OrderHistoryDetailsMobileComponent implements OnInit {
  @Input() itemData: any;

  configURL_media = environment.backEndBaseMediaUrl;

  constructor() {}

  ngOnInit(): void {}
}
