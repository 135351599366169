import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { demoItem } from 'src/app/core/model/category.model';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css']
})
export class CompareComponent implements OnInit {

  compareItemWithDescription: any;
  displayedColumns: string[] = [
    'Image',
    'Name',
    'Unit Price',
    'Remove',
  ];
  displayedColumnsMobile: string[] = [
    'Image',
    'Name',
    'Total Price',
  ];
  mobile = false;
  @Input() itemData: demoItem;
  longDescription:string;
  descriptionTableIndex: number;
  descriptionTable: string;
  tableArr:any;
  configURL_media = environment.backEndBaseMediaUrl;

  constructor(
    private dataService: DataStoreService,
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: object,
    ) { }

  ngOnInit(): void {
    this.compareItemWithDescription = this.dataService.getCompareItem();   
  }

  removeFromCompare(deletedItem) {
    this.compareItemWithDescription = this.compareItemWithDescription.filter(
      (obj) => obj !== deletedItem
    );
    
    if (this.compareItemWithDescription === null) {
      this.compareItemWithDescription = [];
    }

    isPlatformBrowser(this.platformId) &&
      localStorage.setItem(
        'compareItems',
        JSON.stringify(this.compareItemWithDescription)
      );

    this.dataService.numberOfCompare.next(this.compareItemWithDescription.length);

    //-------to show SnackBar or Toast
    this.snackBar.open('Removed From Comapre', 'X', {
      duration: 2000,
    });
  }

}
