import { FacebookPixelEventTrackerService } from './../../core/services/facebook-pixel-event-tracker.service';
import { VoucherService } from './../../core/services/voucher.service';
import { PushSaleService } from './../../core/services/push-sale.service';
import { CheckoutAddressService } from './../../core/services/checkout-address.service';
import { OrderService } from './../../core/services/order.service';
import { UserProfileService } from './../../core/services/user-profile.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { environment } from './../../../environments/environment';
import { ConfigurationService } from './../../core/services/configuration.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import {
  CartItems,
  demoItem,
  DemouserInShort,
} from './../../core/model/category.model';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ApirequestService } from 'src/app/core/services/apirequest.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-confirmorder',
  templateUrl: './confirmorder.component.html',
  styleUrls: ['./confirmorder.component.css'],
})
export class ConfirmorderComponent implements OnInit {
  eventsSubject: Subject<void> = new Subject<void>();

  displayedColumns: string[] = [
    'Image',
    'Name',
    'Unit Price',
    'Quantity',
    // 'Total Price',
  ];
  displayedColumnsMobile: string[] = [
    'Image',
    'Name',
    'Quantity',
    'Total Price',
  ];

  i: any;
  j: any;
  theFinalAmount: any;

  cartItemWithQuantity: any[] = [];
  userInfo: any;
  deliveryAddress: any;
  remark: any;
  paymentMethod = '-1';
  checkbox = false;

  division: string = '';
  city: string = '';
  area: string = '';

  orderitem: any;
  orderitems: any;
  totalDeliveryCost = 0;
  totalPrice: any;
  orderPlaceForDigitalPayment: any;
  orderPlaceForCashOnDelivery: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  isSameaddress: any;
  groupedByStoreName: any;

  configURL_media = environment.backEndBaseMediaUrl;
  tempCard: any;

  checkoutInfoForm: any;

  cartItem: any;
  itemExceedsStock: any;

  division_list = [
    {
      id: 1,
      name: 'Barishal',
      name_bn: 'বরিশাল',
      slug: 'barisal-zm5vz',
      type: 'division',
      delivery_charge: 100,
    },
    {
      id: 2,
      name: 'Chittagong',
      name_bn: 'চট্টগ্রাম',
      slug: 'chittagong-793eb',
      type: 'division',
      delivery_charge: 100,
    },
    {
      id: 3,
      name: 'Dhaka',
      name_bn: 'ঢাকা',
      slug: 'dhaka-uruvl',
      type: 'division',
      delivery_charge: 60,
    },
    {
      id: 4,
      name: 'Khulna',
      name_bn: 'খুলনা',
      slug: 'khulna-dwslc',
      type: 'division',
      delivery_charge: 100,
    },
    {
      id: 5,
      name: 'Rajshahi',
      name_bn: 'রাজশাহী',
      slug: 'rajshahi-h711e',
      type: 'division',
      delivery_charge: 100,
    },
    {
      id: 6,
      name: 'Rangpur',
      name_bn: 'রংপুর',
      slug: 'rangpur-7ogcm',
      type: 'division',
      delivery_charge: 100,
    },
    {
      id: 7,
      name: 'Sylhet',
      name_bn: 'সিলেট',
      slug: 'sylhet-psuxx',
      type: 'division',
      delivery_charge: 100,
    },

    {
      id: 8,
      name: 'Mymensingh',
      name_bn: 'ময়মনসিংহ',
      slug: 'mymensingh-r8jxc',
      type: 'division',
      delivery_charge: 100,
    },
  ];

  cityList: any = [];
  areaList: any = [];

  delivery_address: string = '';
  delivery_charge: number = 0;
  productIds: string = '';

  errorResponse: {
    success: boolean;
    msg: string;
  } = { success: true, msg: '' };

  serverError: boolean = false;

  pushSaleProduct: any;
  loading: boolean = false;
  showCashOnDelivery: boolean = true;
  showCardOnDelivery: boolean = true;
  showBkash: boolean = true;
  showIPDC: boolean = true;
  deliveryMode: string = '';
  voucherForm!: FormGroup;
  invalidVoucherMsg: string = '';
  voucherCode: string = '';
  voucherDeductionAmount: number = 0;
  paymentMode: string = '';
  totalValidPrice = 0;
  productPrice = 0;
  // productsId: any = [];
  sslPayment: boolean;
  bKashPayment: boolean;
  cashOnDelivery: boolean;

  constructor(
    private dataStoreService: DataStoreService,
    private orderService: OrderService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private checkoutAddressService: CheckoutAddressService,
    private pushSaleService: PushSaleService,
    @Inject(PLATFORM_ID) private platformId: object,
    private voucherService: VoucherService,
    private facebookPixelEventTrackerService: FacebookPixelEventTrackerService,
    private apiService: ApirequestService,
    private _configuration: ConfigurationService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // get configuration data
    this._configuration.getConfiguration('payment-config').subscribe(
      (configurationRes) => {
        const {
          ssl_payment: sslPayment,
          bkash_payment: bKashPayment,
          cash_on_delivery: cashOnDelivery,
        } = configurationRes;
        this.sslPayment = sslPayment;
        this.bKashPayment = bKashPayment;
        this.cashOnDelivery = cashOnDelivery;
      },
      (errorRes) => {
        const { success: isSuccess, message } = errorRes.error;
        if (!isSuccess) {
          this._snackBar.open(message, 'X', {
            duration: 2000,
          });
        }
      }
    );

    this.cartItemWithQuantity = this.dataStoreService.getCart();
    this.tempCard = this.dataStoreService.getCart();
    this.tempCard.map((p: any) => (this.productIds += `${p.item.id},`));

    // this.productIds &&
    //   this.pushSaleService
    //     .getPushSaleProducts(this.productIds.slice(0, -1))
    //     .subscribe(
    //       (pushSaleProducts: any) => {
    //         this.pushSaleProduct = pushSaleProducts.related_items;
    //       },
    //       (error: any) => {
    //         this.serverError = true;
    //       }
    //     );

    this.theFinalAmount = this.totalCalculation() + this.totalDeliveryCost;
    this.apiService
      .fetchIndivudualPersonData()
      .subscribe((theUserInfo: any) => {
        this.userInfo = theUserInfo;
        if (this.userInfo.city === null) {
          this.userInfo.city = 'N/A';
        }

        if (this.userInfo.district === null) {
          this.userInfo.district = 'N/A';
        }

        if (this.userInfo.postcode === null) {
          this.userInfo.postcode = 'N/A';
        }

        if (this.userInfo.country === null) {
          this.userInfo.country = 'N/A';
        }

        this.deliveryAddress =
          this.userInfo.street_address +
          '\nCity: ' +
          this.userInfo.city +
          '\nCountry: ' +
          this.userInfo.country +
          '.';
      });

    this.isSameaddress = true;
    this.checkoutInfoForm = new FormGroup({
      statement: new FormControl('', []),
      address: new FormControl('', []),
    });

    this.voucherForm = new FormGroup({
      voucher: new FormControl(null, [Validators.required]),
    });
  }
  totalCalculation() {
    let i;
    let totalNumber = 0;

    for (i = 0; i < this.cartItemWithQuantity.length; i++) {
      totalNumber += Number(this.cartItemWithQuantity[i].totalPrice);
    }
    return totalNumber;
  }

  placeOrder() {
    if (this.paymentMethod === '-1') {
      return;
    }
    this.orderitems = [];
    this.totalPrice = 0;
    this.cartItemWithQuantity =
      isPlatformBrowser(this.platformId) &&
      JSON.parse(localStorage.getItem('cartItems')!);

    this.cartItemWithQuantity.forEach((itm) => {
      // const variantsId = [itm.item.variants[0].id, itm.item.variants[1].id]
      let variantsId: any = [];

      itm.item.variants &&
        itm.item.variants.map((v: any) => {
          if (v !== null) {
            variantsId.push(v.id);
          }
        });

      this.orderitem = {
        item_id: itm.item.item_id,
        quantity: itm.quantity,
        // variants: itm.item.variants,
        variants: variantsId,
      };
      this.totalPrice += itm.totalPrice;
      this.orderitems.push(this.orderitem);
    });
    if (this.paymentMethod === '0') {
      // digital payment
      this.orderPlaceForCashOnDelivery = {
        delivery_address:
          this.deliveryMode === 'office-pickup'
            ? 'office-pickup'
            : this.checkoutInfoForm.value.address,
        remarks: this.checkoutInfoForm.value.statement,
        prepayment_amount: this.paymentMode === 'full-payment' ? 100 : 10,
        payment_method: 'digital_payment',

        items: this.orderitems,
        // prepayment_amount: this.paymentMode === 'full-payment' ? 100 : 10,

        // delivery_charge: this.theFinalAmount < 1000 ? this.delivery_charge : 0,
        // voucher: this.voucherCode,
      };
    } else if (this.paymentMethod == '1') {
      this.orderPlaceForCashOnDelivery = {
        // token:
        //   isPlatformBrowser(this.platformId) && localStorage.getItem('token'),
        delivery_address:
          this.deliveryMode === 'office-pickup'
            ? 'office-pickup'
            : this.checkoutInfoForm.value.address,
        payment_method: 'citybank',
        remarks: this.checkoutInfoForm.value.statement,
        prepayment_amount: 0,
        items: this.orderitems,
        // delivery_charge: this.theFinalAmount < 1000 ? this.delivery_charge : 0,
        // voucher: this.voucherCode,
      };
    } else if (this.paymentMethod == '2') {
      this.orderPlaceForCashOnDelivery = {
        // token:
        //   isPlatformBrowser(this.platformId) && localStorage.getItem('token'),
        delivery_address:
          this.deliveryMode === 'office-pickup'
            ? 'office-pickup'
            : this.checkoutInfoForm.value.address,
        payment_method: 'cash_on_delivery',
        remarks: this.checkoutInfoForm.value.statement,
        prepayment_amount: 0,
        items: this.orderitems,
        // delivery_charge: this.theFinalAmount < 1000 ? this.delivery_charge : 0,
        // voucher: this.voucherCode,
      };
    } else if (this.paymentMethod == '3') {
      this.orderPlaceForCashOnDelivery = {
        // token:
        //   isPlatformBrowser(this.platformId) && localStorage.getItem('token'),
        delivery_address:
          this.deliveryMode === 'office-pickup'
            ? 'office-pickup'
            : this.checkoutInfoForm.value.address,
        payment_method: 'ipdc',
        remarks: this.checkoutInfoForm.value.statement,
        prepayment_amount: 0,
        items: this.orderitems,
        // delivery_charge: this.theFinalAmount < 1000 ? this.delivery_charge : 0,
        // voucher: this.voucherCode,
      };
    } else if (this.paymentMethod === '4') {
      this.orderPlaceForCashOnDelivery = {
        // token:
        //   isPlatformBrowser(this.platformId) && localStorage.getItem('token'),
        delivery_address:
          this.deliveryMode === 'office-pickup'
            ? 'office-pickup'
            : this.checkoutInfoForm.value.address,
        remarks: this.checkoutInfoForm.value.statement,
        payment_method: 'card_on_delivery',
        prepayment_amount: 0,
        items: this.orderitems,
        // delivery_charge: this.theFinalAmount < 1000 ? this.delivery_charge : 0,
        // voucher: this.voucherCode,
      };
    }
    // for bKash
    else if (this.paymentMethod === '5') {
      this.orderPlaceForCashOnDelivery = {
        // token:
        //   isPlatformBrowser(this.platformId) && localStorage.getItem('token'),
        delivery_address:
          this.deliveryMode === 'office-pickup'
            ? 'office-pickup'
            : this.checkoutInfoForm.value.address,
        remarks: this.checkoutInfoForm.value.statement,
        payment_method: 'bKash',
        prepayment_amount: 0,
        items: this.orderitems,
        // delivery_charge: this.theFinalAmount < 1000 ? this.delivery_charge : 0,
        // voucher: this.voucherCode,
      };
    }
    if (this.orderitems.length > 0) {
      this.loading = true;
      this.orderService.placeOrder(this.orderPlaceForCashOnDelivery).subscribe(
        (post: any) => {
          if (post.success) {
            this.loading = false;
            this.matSnackBar.open('Order Placed ', post.order_no, {
              duration: 5000,
            });
            this.cartItemWithQuantity = [];
            isPlatformBrowser(this.platformId) &&
              localStorage.setItem(
                'cartItems',
                JSON.stringify(this.cartItemWithQuantity)
              );
            this.dataStoreService.numberOfItemInCart.next(
              this.cartItemWithQuantity.length
            );
            if (this.paymentMethod === '0') {
              isPlatformBrowser(this.platformId) &&
                localStorage.setItem('session', post.session);
              window.location.href = post.gateway;
            } else if (this.paymentMethod === '1') {
              this.router.navigate(['../profile/orderhistory']);
            } else if (this.paymentMethod === '2') {
              this.router.navigate(['../profile/orderhistory']);
            } else if (this.paymentMethod === '3') {
              window.location.href = post.gateway;
            } else if (this.paymentMethod === '4') {
              this.router.navigate(['../profile/orderhistory']);
            } else if (this.paymentMethod === '5') {
              isPlatformBrowser(this.platformId) &&
                localStorage.setItem('bkash_payment_id', post.payment_id);
              window.location.href = post.gateway;
            }

            // else if(this.paymentMethod === '6') {
            //   this.router.navigate(['../eshop/profile/orderhistory']);
            // }
            // FACEBOOK PIXEL START
            this.facebookPixelEventTrackerService.trackEvent('Placed Order', {
              orderNo: post.order_no,
            });
            // FACEBOOK PIXEL END
          } else {
            this.loading = false;
            this.matSnackBar.open('Failed!!! Please, try again. ', 'X', {
              duration: 5000,
            });
            this.router.navigate(['cart']);
          }
        },
        (error: any) => {
          this.loading = false;
          this.errorResponse.msg = error.error.msg;
          this.errorResponse.success = error.error.success;
        }
      );
    }
  }

  handleDivisionChange(divisionSlug: any) {
    this.division = this.checkoutInfoForm.value.division.slice(0, -6);
    this.delivery_address +=
      this.division_list.find((ds) => ds.slug === divisionSlug)?.name + ', ';
    this.checkoutAddressService
      .getDivision(divisionSlug)
      .subscribe((post: any) => {
        this.cityList = post;
      });
  }
  handleCityChange(citySlug: any) {
    if (citySlug === 'dhaka-zgnkn') {
      this.showCardOnDelivery = true;
    } else {
      this.showCashOnDelivery = false;
      this.showCardOnDelivery = false;
      this.showBkash = false;
      this.showIPDC = false;
      this.matSnackBar.open(
        'At least 10% payment required for out of Dhaka',
        'X',
        {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['blue-snackbar'],
        }
      );
    }
    // this.delivery_charge = this.cityList.find(
    //   (ds: any) => ds.slug === citySlug
    // )?.delivery_charge!;
    let selectedCityDeliveryCharge = this.cityList.find(
      (ds: any) => ds.slug === citySlug
    )?.delivery_charge!;

    this.delivery_charge =
      this.theFinalAmount > 1000 ? 0 : selectedCityDeliveryCharge;

    this.city = this.checkoutInfoForm.value.city.slice(0, -6);

    this.delivery_address +=
      this.cityList.find((ds: any) => ds.slug === citySlug)?.name + ', ';
    this.checkoutAddressService.getDivision(citySlug).subscribe((post: any) => {
      this.areaList = post;
    });
  }

  handleAreaChange(area: string) {
    this.area = this.checkoutInfoForm.value.area.slice(0, -6);

    this.delivery_address += area;
  }
  change_payment_method(value: string) {
    this.paymentMethod = value;
  }
  addPushSale() {
    this.cartItemWithQuantity = this.dataStoreService.getCart();
    this.theFinalAmount = this.totalCalculation() + this.totalDeliveryCost;
    this.matSnackBar.open('Product added to the order', 'X', {
      duration: 2000,
    });
  }

  deliveryOptionChange(event: any) {
    this.deliveryMode = event.value;
    this.invalidVoucherMsg = '';
    this.voucherDeductionAmount = 0;
    this.delivery_charge = 0;
    this.voucherForm.reset();
    // this.checkoutInfoForm.reset();
    this.paymentMethod = '-1';
  }

  onVoucherSubmit() {
    let productsId: any = [];
    this.cartItemWithQuantity.forEach((itm) => {
      const product = {
        item_id: itm.item.item_id,
      };
      productsId.push(product.item_id);
    });
    const voucherData = {
      voucher: this.voucherForm.value.voucher.toUpperCase(),
      items: productsId,
    };
    this.voucherService.getVoucher(voucherData).subscribe(
      (res: any) => {
        if (res.valid) {
          this.invalidVoucherMsg = '';
          let totalAmount = this.totalCalculation() + this.delivery_charge;
          if (totalAmount >= res.minimum) {
            this.voucherCode = res.voucher;
            if (res.type === 'BDT') {
              let voucherAmount = res.amount;
              if (totalAmount < voucherAmount) {
                this.invalidVoucherMsg = `The amount of the voucher cannot be more than the total amount`;
                this.voucherDeductionAmount = 0;
              } else {
                this.voucherDeductionAmount = Math.floor(voucherAmount);
              }
            } else {
              if (res.item.length !== 0) {
                for (let i = 0; i < res.item.length; i++) {
                  const voucheredProduct = this.cartItemWithQuantity.find(
                    (product: any) => product.item.id === res.item[i]
                  );

                  this.productPrice =
                    voucheredProduct.item.offer_price === 0
                      ? voucheredProduct.item.sale_price
                      : voucheredProduct.item.offer_price;
                  this.totalValidPrice +=
                    this.productPrice * voucheredProduct.item.quantity;
                }

                this.voucherDeductionAmount = Math.floor(
                  this.totalValidPrice * (res.amount / 100)
                );
              } else {
                let voucherAmount = totalAmount * (res.amount / 100);
                if (totalAmount < voucherAmount) {
                  this.invalidVoucherMsg = `The amount of the voucher cannot be more than the total amount`;
                  this.voucherDeductionAmount = 0;
                } else {
                  this.voucherDeductionAmount = Math.floor(voucherAmount);
                }
              }
            }
          } else {
            this.invalidVoucherMsg = `You have to buy minimum BDT ${res.minimum} to apply this voucher`;
            this.voucherDeductionAmount = 0;
          }
        }
      },
      (error: any) => {
        if (error.status === 400) {
          this.invalidVoucherMsg = 'Invalid voucher';
          this.voucherDeductionAmount = 0;
        }
      }
    );
  }

  paymentOptionChange(event: any) {
    this.paymentMode = event.value;
  }
}
