<div>
  <div class="nav-title">
    <h3 class="nav-title-text">Shop By category</h3>
  </div>
  <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node">
      <button mat-icon-button disabled></button>
      <span>{{ node.name }}</span>
    </cdk-nested-tree-node>
    <cdk-nested-tree-node
      *cdkTreeNodeDef="let node; when: hasChild"
      class="example-tree-node"
    >
      <button
        mat-icon-button
        [attr.aria-label]="'Toggle ' + node.name"
        cdkTreeNodeToggle
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "remove" : "add" }}
        </mat-icon>
      </button>

      <span>{{ node.name }}</span>
      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>
