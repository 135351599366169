import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-sub-order-card',
  templateUrl: './sub-order-card.component.html',
  styleUrls: ['./sub-order-card.component.css'],
})
export class SubOrderCardComponent implements OnInit {
  columns: string[] = ['Name', 'Unit Price', 'Quantity', 'Total Price'];
  @Input() subOrder: any;

  constructor(private router: Router) {}

  ngOnInit(): void 
  { }
  handleSubOrderCancelClick(subOrder: any) {
    this.router.navigate(['/cancel-order', subOrder.sub_order]);
  }
}
