import { environment } from './../../../../environments/environment';
import { Color } from './../../../shared/classes/color';
import { HomeGroupService } from './../../../core/services/home-group.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-group',
  templateUrl: './home-group.component.html',
  styleUrls: ['./home-group.component.css']
})
export class HomeGroupComponent implements OnInit {

  color = new Color();
  homeGroupData: any;
  backgroundColor: any;
  media_URL = environment.backEndBaseMediaUrl;

  constructor(private homeGroupApi: HomeGroupService) { }

  ngOnInit(): void {
    this.backgroundColor = this.color.$primaryBackground;
    this.homeGroupApi.getGroupData().subscribe((homeGroup: any) => {
      this.homeGroupData = homeGroup.sort(function (a: any, b: any) {
        if (a.group_order < b.group_order) {
          return -1;
        }
        if (a.group_order > b.group_order) {
          return 1;
        }
        return 0;
      });
    });
  }

}
