<!--Section: Block Content-->
<section>
  <div class="row">
    <!--Grid column-->
    <div class="col-lg-12">
      <!-- Card -->
      <div class="mb-1 mt-1">
        <div
          class="row m-0 p-1 mb-2"
          style="border: 1px solid rgba(0, 0, 0, 0.1)"
        >
          <div class="col-4">
            <div class="d-flex justify-content-center">
              <img
                class="rounded"
                src="{{ configURL_media }}{{ itemData.item.thumbnail_url }}"
                alt="{{ itemData.item.name }}"
                height="100"
                loading="lazy"
              />
            </div>
          </div>

          <div class="col-8 p-2">
            <a
              [routerLink]="['/', itemData.item.url]"
              class="mb-1"
              style="font-weight: 400; text-decoration: none; color: #292929"
            >
              {{ itemData.item.name }}
              <span *ngFor="let variant of itemData.item.variants">
                <p
                  *ngIf="variant !== null"
                  style="margin-top: 5px; padding-right: 1rem"
                >
                  <span>
                    <b>Variant name: </b> {{ variant.variation_name }}</span
                  >
                </p>
              </span>
            </a>
            <div class="row">
              <div
                class="col-12 mb-2 d-flex justify-content-between align-items-end"
              >
                <div class="def-number-input number-input safari_only">
                  <button
                    mat-flat-button
                    [disabled]="itemData.quantity <= 1 ? 'disabled' : null"
                    class="add-rmv-btn"
                    (click)="quantityBtnHandler('down')"
                  >
                    <span class="material-icons"> remove </span>
                  </button>
                  <input
                    disabled
                    type="text"
                    class="item-qun"
                    value="{{ itemData.quantity }}"
                  />
                  <button
                    mat-flat-button
                    [disabled]="
                      itemData.item.stock <= itemData.quantity
                        ? 'disabled'
                        : null
                    "
                    class="add-rmv-btn"
                    (click)="quantityBtnHandler('up')"
                  >
                    <span class="material-icons"> add </span>
                  </button>
                </div>
                <p
                  *ngIf="itemData.item.offer_price === 0"
                  class="unit-price mb-0 text-muted text-uppercase"
                >
                  ৳ {{ itemData.item.sale_price | number }} X
                  {{ itemData.quantity }}
                </p>
                <p
                  *ngIf="itemData.item.offer_price != 0"
                  class="unit-price mb-0 text-muted text-uppercase"
                >
                  ৳ {{ itemData.item.offer_price | number }} X
                  {{ itemData.quantity }}
                </p>
              </div>
              <div class="col-12">
                <div
                  class="bottom-container d-flex justify-content-between align-items-end"
                >
                  <a
                    type="button"
                    class="card-link-secondary small text-uppercase"
                    (click)="removeItemHandler()"
                    ><i class="fas fa-trash-alt"></i> Remove item
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
