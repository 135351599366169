import { Title } from '@angular/platform-browser';
import { environment } from './../../../environments/environment';
import { demoItem } from './../../core/model/category.model';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { Component, OnInit, Input, PLATFORM_ID, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit {
  displayedColumns: string[] = [
    'Image',
    'Name',
    'Quantity',
    'Unit Price',
    'Remove',
  ];
  displayedColumnsMobile: string[] = [
    'Image',
    'Name',
    'Quantity',
    'Total Price',
  ];

  i: number;
  j: number;

  mobile = false;

  cartItemWithQuantity: {
    item: demoItem;
    quantity: number;
    totalPrice: number;
  }[] = [];

  demo: any;
  metaTitle: string = 'Cart';
  numberOfItemInCart: number;
  configURL_media = environment.backEndBaseMediaUrl;
  smCurrentQun: number = 1;

  @Input() itemData: demoItem;
  totalProductPrice: number;

  constructor(
    private dataService: DataStoreService,
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: object,
    private title: Title
  ) {}

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.cartItemWithQuantity = this.dataService.getCart();
  }

  calculateTotalPrice(orderedItem: any) {
    if (orderedItem.item.offer === 0) {
      return orderedItem.item.price * orderedItem.quantity;
    } else {
      return orderedItem.item.offer * orderedItem.quantity;
    }
  }

  removeFromCart(deletedItem: {
    item: any;
    quantity: number;
    totalPrice: number;
  }) {
    this.cartItemWithQuantity = this.cartItemWithQuantity.filter(
      (obj) => obj !== deletedItem
    );

    if (this.cartItemWithQuantity === null) {
      this.cartItemWithQuantity = [];
    }

    isPlatformBrowser(this.platformId) &&
      localStorage.setItem(
        'cartItems',
        JSON.stringify(this.cartItemWithQuantity)
      );

    this.dataService.numberOfItemInCart.next(this.cartItemWithQuantity.length);

    //-------to show SnackBar or Toast
    this.snackBar.open('Removed From Cart', 'X', {
      duration: 2000,
    });
  }

  quantityUpDown(
    state: string,
    i: { item: any; quantity: any; totalPrice: any }
  ) {
    if (state === 'up') {
      // i.max_order_quantity > i.quantity &&  i.quantity++;
      if (i.item.max_order_quantity > i.quantity) {
        ++i.quantity;
      }

      if (i.item.offer_price === 0) {
        i.totalPrice = i.item.sale_price * i.quantity;
        this.totalProductPrice = i.totalPrice;
        
      } else {
        i.totalPrice = i.item.offer_price * i.quantity;
        this.totalProductPrice = i.totalPrice;
      }

      isPlatformBrowser(this.platformId) &&
        localStorage.setItem(
          'cartItems',
          JSON.stringify(this.cartItemWithQuantity)
        );
        
        
    }
    if (state === 'down') {
      i.quantity--;

      if (i.item.offer_price === 0) {
        i.totalPrice = i.item.sale_price * i.quantity;
        this.totalProductPrice = i.totalPrice;
      } else {
        i.totalPrice = i.item.offer_price * i.quantity;
        this.totalProductPrice = i.totalPrice;
      }

      isPlatformBrowser(this.platformId) &&
        localStorage.setItem(
          'cartItems',
          JSON.stringify(this.cartItemWithQuantity)
        );
        console.log(this.totalProductPrice);
    }
  }

  smQuantityUpDown(i: { item: any; quantity: number; totalPrice: number }) {
    if (i.item.offer_price === 0) {
      i.totalPrice = i.item.sale_price * this.smCurrentQun;
    } else {
      i.totalPrice = i.item.offer_price * this.smCurrentQun;
    }
    isPlatformBrowser(this.platformId) &&
      localStorage.setItem(
        'cartItems',
        JSON.stringify(this.cartItemWithQuantity)
      );
  }

  theNameLengthSelector(s: string, len: number) {
    if (s.length >= len) {
      s = s.slice(0, len - 1) + ' .....';
    }

    return s;
  }

  totalCalculation() {
    // let i;
    let totalNumber = 0;

    for (let i = 0; i < this.cartItemWithQuantity.length; i++) {
      totalNumber += Number(this.cartItemWithQuantity[i].totalPrice);
    }
    return totalNumber;
  }
}
