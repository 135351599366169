export enum ApiPaths {
  // SEARCH_RESULT = 'search/',
  // SEARCH_PRODUCTS = 'eshop-items/?search=',
  // CATEGORIES = 'categories',
  UPDATE_USER_INFO = 'users/update/profile/',
  GET_USER_INFO = 'users/profile/',
  GET_CANCELLATION_REASONS = 'orders/complainlist/',
  POST_CANCELLED_ORDER = 'eshop-order/order-cancel/',
  // PLACE_ORDER = 'eshop-order/place-order/',
  ITEM_REQUEST = 'request-items/',
  ORDER_HISTORY = 'eshop-order/list/',
  ORDER_TACKING = 'eshop-order/list/',
  // HOME_GROUP_PRODUCTS = 'eshop-items/home-group/',
  // HOME_GROUPS = 'eshop-items/home-group/',
  POST_RATING_REVIEW = 'items/rating-review/',
  IPDC_PRODUCTS = 'eshop-items/?ipdc=1',
  VOUCHER = 'voucher/',
  ORDER_REVIEW = 'eshop-order/order-rating/',
  JS_CODE = 'external-code/',
  BKASH_PAYMENT_VALIDATION = 'bkash/bkash-payment-validation/',
  BKASH_PAYMENT_REINITIATE = 'bkash/bkash-payment-reinitiate/',

  // new
  BRANDS = 'brand',
  TICKER = 'home/ticker/',
  OFFERS = 'offers',
  CATAGORIES = 'categories',
  CATEGORY_ITEM = 'items?category=',
  CONTACT = 'contactus',
  HOME_GROUP = 'items/home-group',
  HOME_BRAND = 'brand?home_brand=True',
  HOME_CATEGORY = 'categories?home_category=True',
  ITEM = 'item',
  OFFER_ITEM = 'offeritems',
  OFFER_RELATED_ITEM = 'offers/',
  ORDER_DETAILS = 'order_detail',
  ORDER_LIST = 'order-list',
  PRODUCT_DETAILS = 'items/details/',
  PLACE_ORDER = 'place-order',
  REGISTER = 'register',
  SEARCH = 'items/search/',
  SEARCH_PRODUCTS = 'items?search=',
  USER_PROFILE = 'user-profile',
  UPDATE_PROFILE = 'update-profile',
  USER_EXIST = 'user/exist',
  USER_LOGIN = 'user/login',
  EMAIL_VALIDATION = 'email-validation',
  // OTP_VERIFICATION = 'otp-verification',
  RESET_PASSWORD = 'reset-password',
  MOBILE_OTP_SEND = 'otp-send',
  MOBILE_VERIFICATION = 'otp-verification',
  MOBILE_OTP_RESEND = 'mobile_OTP_resend',
  CONFIGURATION = 'ecom-configuration/',
  GET_ORDER_HISTORY_DETAILS = 'order-details/',
  DIGITAL_PAYMENT_VALIDATION = 'payment/digitalpayment/validation',
  DIGITAL_PAYMENT_REINITIATE = 'payment/digitalpayment/reinitiate',
}
