<div *ngIf="!loading" class="container confirm-ord-container">
  <div class="cart-title d-flex justify-content-center">
    <h2 class="cart-title-text">Checkout</h2>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-12">
      <div class="user-data-container">
        <div class="container mb-4">
          <div class="order-info-container">
            <div class="row">
              <div class="col-md-9">
                <div class="desktop-view">
                  <table
                    mat-table
                    [dataSource]="cartItemWithQuantity"
                    class="mat-elevation-z8"
                  >
                    <ng-container matColumnDef="Image">
                      <th mat-header-cell *matHeaderCellDef>Image</th>
                      <td mat-cell *matCellDef="let element">
                        <img
                          src="{{ configURL_media }}{{
                            element.item.thumbnail_url
                          }}"
                          alt="{{ element.item.name }}"
                          loading="lazy"
                          height="85px"
                        />
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Name">
                      <th mat-header-cell *matHeaderCellDef>Product Name</th>
                      <td
                        style="max-width: 250px"
                        mat-cell
                        *matCellDef="let element"
                      >
                        {{ element.item.name }}
                        <span *ngFor="let variant of element.item.variants">
                          <p
                            *ngIf="variant !== null"
                            style="margin-top: 5px; padding-right: 1rem"
                          >
                            <span>
                              <b>Variant name: </b>
                              {{ variant.variation_name }}</span
                            >
                          </p>
                        </span>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Unit Price">
                      <th
                        style="text-align: right; padding-right: 3rem"
                        mat-header-cell
                        *matHeaderCellDef
                      >
                        Unit Price
                      </th>
                      <td
                        style="text-align: right; padding-right: 3rem"
                        mat-cell
                        *matCellDef="let element"
                      >
                        <span *ngIf="element.item.offer_price === 0"
                          >&#2547;
                          {{ element.item.sale_price.toLocaleString("en-BD") }}
                        </span>
                        <span *ngIf="element.item.offer_price != 0"
                          >&#2547;
                          {{ element.item.offer_price.toLocaleString("en-BD") }}
                        </span>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Quantity">
                      <th
                        style="padding-right: 3rem; text-align: center"
                        mat-header-cell
                        *matHeaderCellDef
                      >
                        Quantity
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <span
                          style="
                            padding-left: 3.1rem;
                            width: 5rem;
                            text-align: right;
                          "
                          >{{ element.quantity }}</span
                        >
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Total Price">
                      <th
                        style="text-align: right"
                        mat-header-cell
                        *matHeaderCellDef
                      >
                        Total Price
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <p style="font-weight: bold; text-align: right">
                          &#2547;
                          {{ element.totalPrice.toLocaleString("en-BD") }}
                        </p>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                  </table>
                </div>

                <div class="mobile-view">
                  <div
                    class="container"
                    style="padding: 0"
                    *ngFor="let cartItem of cartItemWithQuantity"
                  >
                    <app-checkout-mobile-card [itemData]="cartItem">
                    </app-checkout-mobile-card>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mobile-summary">
                <div class="summary-container">
                  <p class="summary-title">Order Summary</p>
                  <div style="display: flex; justify-content: space-between">
                    <p class="quantity-text">
                      Subtotal ({{ cartItemWithQuantity?.length }} items)
                    </p>
                    <p class="quantity-value">
                      &#2547; {{ totalCalculation() | number }}
                    </p>
                  </div>
                  <div
                    *ngIf="voucherDeductionAmount !== 0"
                    style="display: flex; justify-content: space-between"
                  >
                    <p class="quantity-text">Voucher applied</p>
                    <p class="quantity-value">
                      - &#2547; {{ voucherDeductionAmount }}
                    </p>
                  </div>
                  <div
                    *ngIf="theFinalAmount <= 1000 && delivery_charge !== 0"
                    style="display: flex; justify-content: space-between"
                  >
                    <p class="quantity-text">Shipping Fee</p>
                    <p class="quantity-value">&#2547; {{ delivery_charge }}</p>
                  </div>

                  <div
                    *ngIf="theFinalAmount > 1000"
                    style="display: flex; justify-content: space-between"
                  >
                    <p class="delivery-charge">Free shipping</p>
                  </div>
                  <small
                    *ngIf="deliveryMode === 'home-delivery'"
                    style="color: #17a2b8"
                    >To apply voucher you must fill all the required fields
                    first</small
                  >

                  <div
                    *ngIf="deliveryMode === 'home-delivery'"
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin-top: 0.5em;
                    "
                  >
                    <form
                      (ngSubmit)="onVoucherSubmit()"
                      [formGroup]="voucherForm"
                      style="width: 100%"
                    >
                      <input
                        class="voucher-input"
                        type="text"
                        placeholder="Enter voucher code"
                        formControlName="voucher"
                        required
                      />
                      <button
                        [disabled]="
                          !voucherForm.valid || !checkoutInfoForm.valid
                        "
                        type="submit"
                        class="apply-btn"
                      >
                        APPLY
                      </button>
                    </form>
                  </div>

                  <div
                    *ngIf="deliveryMode === 'office-pickup'"
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin-top: 0.5em;
                    "
                  >
                    <form
                      (ngSubmit)="onVoucherSubmit()"
                      [formGroup]="voucherForm"
                      style="width: 100%"
                    >
                      <input
                        class="voucher-input"
                        type="text"
                        placeholder="Enter voucher code"
                        formControlName="voucher"
                        required
                      />

                      <button
                        [disabled]="!voucherForm.valid"
                        type="submit"
                        class="apply-btn"
                      >
                        APPLY
                      </button>
                    </form>
                  </div>
                  <small style="color: rgb(192, 85, 73)">{{
                    invalidVoucherMsg
                  }}</small>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin-top: 0.5rem;
                    "
                  >
                    <p style="color: #202020" class="quantity-text">Total</p>
                    <p style="color: salmon" class="quantity-value">
                      &#2547;
                      {{
                        totalCalculation() +
                          delivery_charge -
                          voucherDeductionAmount | number
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <app-push-sale-products
              (addToCardClick)="addPushSale()"
              [pushSaleProduct]="pushSaleProduct"
              [serverError]="serverError"
            ></app-push-sale-products>
            <div class="row">
              <div class="col-md-12">
                <div class="order-info-title">
                  <p class="order-info" style="color: #000254">
                    Checkout information
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h2 class="delivery-option">Please select an option*</h2>

              <mat-radio-group
                aria-label="Select an option"
                [(ngModel)]="deliveryMode"
              >
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button
                    (change)="deliveryOptionChange($event)"
                    value="home-delivery"
                    >Home delivery</mat-radio-button
                  >
                  <mat-radio-button
                    (change)="deliveryOptionChange($event)"
                    value="office-pickup"
                    >Store pickup</mat-radio-button
                  >
                </mat-radio-group>
              </mat-radio-group>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12 mt-2">
                <div style="display: flex">
                  <div style="margin-right: 0.5rem">
                    <p style="letter-spacing: 0.5px; font-weight: 400">
                      Recipient name:
                    </p>
                  </div>
                  <p style="font-weight: 100; letter-spacing: 1px">
                    {{ userInfo?.name }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 xol-sm-12 mt-2 sm-phone">
                <div class="phone-container">
                  <div style="margin-right: 0.5rem">
                    <p style="letter-spacing: 0.5px; font-weight: 400">
                      Phone number:
                    </p>
                  </div>
                  <p style="font-weight: 100; letter-spacing: 1px">
                    {{ userInfo?.mobile }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="deliveryMode === 'office-pickup'">
              <h2 class="office-pickup">
                *Please receive your parcel from our warehouse.Call 00000000 for
                details.*
              </h2>
            </div>
            <form style="font-size: 12px" [formGroup]="checkoutInfoForm">
              <p
                *ngIf="theFinalAmount <= 1000 && delivery_charge !== 0"
                class="delivery-charge"
              >
                Shipping Charge &#2547; {{ delivery_charge }} has been added
              </p>
              <p *ngIf="theFinalAmount > 1000" class="delivery-charge">
                Free shipping
              </p>
              <div *ngIf="deliveryMode === 'home-delivery'" class="row">
                <div class="col-md-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Address</mat-label>
                    <input
                      type="text"
                      placeholder="Please enter your full name"
                      matInput
                      formControlName="address"
                      required
                    />
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="deliveryMode !== ''" class="row">
                <div class="col-md-12">
                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label>Remarks - add note (optional)</mat-label>
                    <textarea
                      class="specification"
                      matInput
                      placeholder="Enter additional information"
                      formControlName="statement"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="deliveryMode === 'home-delivery'">
            <h2 class="payment-title">Please select a payment method</h2>

            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="paymentMethod"
            >
              <div class="d-flex justify-content-center justify-content-sm-start">
                <div
                  *ngIf="sslPayment"
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 payment-method"
                >
                  <div (click)="change_payment_method('0')">
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/online-payment-icon.jpg"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="0"
                        >Digital Payment</mat-radio-button
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-2 col-lg-3 col-md-4 col-6 payment-method"
                  *ngIf="showCashOnDelivery && cashOnDelivery"
                >
                  <div (click)="change_payment_method('2')">
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/cash_on_delivery.jpeg"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="2"
                        >Cash on Delivery</mat-radio-button
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-2 col-lg-3 col-md-4 col-6 payment-method"
                  *ngIf="showBkash && bKashPayment"
                >
                  <div (click)="change_payment_method('5')">
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/bkash-logo.png"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="5">bKash </mat-radio-button>
                    </div>
                  </div>
                </div>

                <!-- <div
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 payment-method"
                  *ngIf="showCardOnDelivery"
                >
                  <div (click)="change_payment_method('4')">
                    <div class="text-center">
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/card_on_delivery.jpeg"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <mat-radio-button value="4"
                        >Card On Delivery</mat-radio-button
                      >
                    </div>
                  </div>
                </div> -->

                <!-- <div
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 payment-method"
                  *ngIf="showIPDC"
                >
                  <div (click)="change_payment_method('3')">
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/IPDC-logo-fb.png"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="3">IPDC</mat-radio-button>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="d-block d-sm-none partial-sm-10">
                    <div *ngIf="paymentMethod === '5'">
                    <div *ngIf="paymentMethod === '6'">
                      <h2 class="payment-option-bkash  mt-3">
                        bKash Merchant Number  
                        <span style="color: brown;">** 01958510800**</span>
                      </h2>
                    </div>
                  </div> -->
              </div>
            </mat-radio-group>
          </div>

          <!-- mobile -->
          <div *ngIf="deliveryMode === 'office-pickup'">
            <h2 class="payment-title">Please select a payment method</h2>

            <mat-radio-group
              aria-label="Select an option"
              [(ngModel)]="paymentMethod"
            >
              <div style="display: flex; justify-content: start; gap: 30px">
                <div
                  *ngIf="sslPayment"
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 payment-method"
                >
                  <div (click)="change_payment_method('0')">
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/online-payment-icon.jpg"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="0"
                        >Digital Payment</mat-radio-button
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 payment-method"
                  *ngIf="showCashOnDelivery && cashOnDelivery"
                >
                  <div (click)="change_payment_method('2')">
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/cash_on_delivery.jpeg"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="2"
                        >Cash on Delivery</mat-radio-button
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 payment-method"
                  *ngIf="showBkash && bKashPayment"
                >
                  <div (click)="change_payment_method('5')">
                    <!-- <div (click)="change_payment_method('6')"> -->
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/bkash-logo.png"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="5">bKash </mat-radio-button>
                    </div>
                  </div>
                </div>

                <!-- <div
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 payment-method"
                >
                  <div (click)="change_payment_method('4')">
                    <div class="text-center">
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/card_on_delivery.jpeg"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <mat-radio-button value="4"
                        >Card On Delivery</mat-radio-button
                      >
                    </div>
                  </div>
                </div> -->

                <!-- <div
                  class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 payment-method"
                  *ngIf="showIPDC"
                >
                  <div (click)="change_payment_method('3')">
                    <div>
                      <img
                        style="width: 100%"
                        src="assets/payment-method-images/IPDC-logo-fb.png"
                        loading="lazy"
                      />
                    </div>
                    <div class="text-center">
                      <mat-radio-button value="3">IPDC</mat-radio-button>
                    </div>
                  </div>
                </div> -->
                <!-- <div class="d-block d-sm-none mt-2">
                    <div *ngIf="paymentMethod === '5'">
                    <div *ngIf="paymentMethod === '6'">
                      <h2 class="payment-option-bkash  mt-3">
                        bKash Merchant Number  
                        <span style="color: brown;">** 01958510800**</span>
                      </h2>
                    </div>
                  </div> -->
              </div>
            </mat-radio-group>
          </div>

          <div>
            <div *ngIf="paymentMethod === '0' || paymentMethod === '5'">
              <!-- <div *ngIf="paymentMethod === '0'"> -->
              <h2 class="payment-option">
                How much do you want to pay? Please select*
              </h2>

              <mat-radio-group
                aria-label="Select an option"
                [(ngModel)]="paymentMode"
              >
                <div style="padding-top: 0.5rem">
                  <mat-radio-group aria-label="Select an option">
                    <mat-radio-button
                      (change)="paymentOptionChange($event)"
                      value="full-payment"
                      >Full payment (100%)</mat-radio-button
                    >
                    <mat-radio-button
                      (change)="paymentOptionChange($event)"
                      value="partial-payment"
                      >Partial payment(10%)</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </mat-radio-group>
            </div>
          </div>

          <!-- <div class="d-none d-xl-block mt-2">
              <div *ngIf="paymentMethod === '5'">
              <div *ngIf="paymentMethod === '6'">
                <h2 class="payment-option-bkash  mt-3">
                  bKash Merchant Number  
                  <span style="color: brown;">** 01958510800**</span>
                </h2>
              </div>
            </div> -->

          <div
            *ngIf="
              deliveryMode === 'office-pickup' ||
              deliveryMode === 'home-delivery'
            "
            class="desktop-view"
            style="padding-top: 2rem"
          >
            <span *ngIf="!errorResponse?.success">
              <div class="alert alert-warning" role="alert">
                {{ errorResponse?.msg }}
              </div>
            </span>
            <mat-checkbox [(ngModel)]="checkbox"
              >I have read and agreed to the
              <a [routerLink]="['/terms-and-condition']"
                >Terms and Conditions (Warranty Policy)</a
              >,
              <a [routerLink]="['/privacy-policy']">Privacy Policy</a> and
              <a [routerLink]="['/refund-return']"
                >Refund and Return Policy</a
              >
            </mat-checkbox>
          </div>
          <div
            *ngIf="
              deliveryMode === 'office-pickup' ||
              deliveryMode === 'home-delivery'
            "
            class="mobile-view"
            style="padding-top: 2rem"
          >
            <div class="checkbox-div"></div>
            <mat-checkbox [(ngModel)]="checkbox"
              >I have read and agreed to the </mat-checkbox
            ><span>
              <a [routerLink]="['/terms-and-condition']"
                >Terms and Conditions (Warranty Policy)</a
              >,
              <a [routerLink]="['/privacy-policy']">Privacy Policy</a> and
              <a [routerLink]="['/refund-return']"
                >Refund and Return Policy</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="deliveryMode === 'home-delivery'" class="desktop-view row">
    <div class="col-6">
      <button
        [routerLink]="['/cart']"
        mat-raised-button
        color="primary"
        class="shop-checkout"
      >
        <span style="padding-right: 0.5rem" class="material-icons">
          arrow_back </span
        >Back to Cart
      </button>
    </div>
    <div class="col-6">
      <div style="justify-content: end; display: flex">
        <button
          class="shop-checkout"
          [disabled]="
            !checkbox ||
            !checkoutInfoForm.valid ||
            paymentMethod === '-1' ||
            (paymentMethod === '0' && paymentMode === '') 
          "
          mat-raised-button
          color="primary"
          (click)="placeOrder()"
        >
          Place Order
        </button>
      </div>
    </div>
  </div>

  <div class="desktop-view row" *ngIf="deliveryMode === 'office-pickup'">
    <div class="col-6">
      <button
        [routerLink]="['/cart']"
        mat-raised-button
        color="primary"
        class="shop-checkout"
      >
        <span style="padding-right: 0.5rem" class="material-icons">
          arrow_back </span
        >Back to Cart
      </button>
    </div>
    <div class="col-6">
      <div style="justify-content: end; display: flex">
        <button
          class="shop-checkout"
          [disabled]="
            !checkbox ||
            paymentMethod === '-1' ||
            (paymentMethod === '0' && paymentMode === '')
          "
          mat-raised-button
          color="primary"
          (click)="placeOrder()"
        >
          Place Order
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="deliveryMode === 'home-delivery'" class="mobile-view row">
    <div class="col-6">
      <button
        [routerLink]="['/cart']"
        class="bottom-btns"
        mat-raised-button
        color="primary"
      >
        <span style="padding-right: 0.2rem" class="material-icons">
          arrow_back </span
        >Back to cart
      </button>
    </div>
    <div class="col-6">
      <div style="justify-content: end; display: flex">
        <button
          class="bottom-btns"
          [disabled]="
            !checkbox ||
            !checkoutInfoForm.valid ||
            paymentMethod === '-1' ||
            (paymentMethod === '0' && paymentMode === '')
          "
          mat-raised-button
          color="primary"
          (click)="placeOrder()"
        >
          Place Order
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="deliveryMode === 'office-pickup'" class="mobile-view row">
    <div class="col-6">
      <button
        [routerLink]="['/cart']"
        class="bottom-btns"
        mat-raised-button
        color="primary"
      >
        <span style="padding-right: 0.2rem" class="material-icons">
          arrow_back </span
        >Back to cart
      </button>
    </div>
    <div class="col-6">
      <div style="justify-content: end; display: flex">
        <button
          class="bottom-btns"
          [disabled]="
            !checkbox ||
            paymentMethod === '-1' ||
            (paymentMethod === '0' && paymentMode === '')
          "
          mat-raised-button
          color="primary"
          (click)="placeOrder()"
        >
          Place Order
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="container my-3" style="padding-top: 2rem">
  <div class="alert alert-primary" role="alert">
    Dear {{ userInfo?.name }} your order is being placed, please wait for a
    while ...
  </div>
</div>
