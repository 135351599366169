import { CategoryDataService } from './../../../core/services/storage/category-data.service';
import { CategoryService } from './../../../core/services/category.service';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css'],
})
export class MenuBarComponent implements OnInit {
  @Input() menubar!: MatDrawer;
  dataSource: any;
  currentMenuData: any;
  previousMenuData: any = [];
  showAll: any;
  @Output() btnClick = new EventEmitter();

  constructor(
    private categoryDataService: CategoryDataService,
    private router: Router,
    private categoryApi: CategoryService
  ) {
    this.categoryDataService.configObservable.subscribe((value: any) => {
      this.dataSource = value;
      this.currentMenuData = value;
      this.previousMenuData = [];
    });
  }

  ngOnInit(): void {}

  changeData(data: any, menuData: any, navUrl: string) {
    const { children, slug } = data;
    if (children.length > 0) {
      this.currentMenuData = children;
      this.previousMenuData = menuData;
      this.showAll = data;
    } else {
      this.menubar.close();
      this.router.navigate(['category', slug]);
      this.categoryApi.buttonClicked(slug);
    }
  }

  previousMenu(): any {
    if (this.previousMenuData.length === 0) {
      return;
    }
    for (const i in this.dataSource) {
      if (this.previousMenuData[0].parent === null) {
        this.currentMenuData = this.previousMenuData;
        this.previousMenuData = [];
        this.showAll = {};
        break;
      } else {
        if (this.dataSource[i].id === this.previousMenuData[0].root) {
          if (this.previousMenuData[0].parent === this.dataSource[i].id) {
            this.currentMenuData = this.dataSource[i].children;
            this.previousMenuData = this.dataSource;
            this.showAll = this.dataSource[i];
          } else {
            const parentObject = this.previousMenuParent(
              this.previousMenuData[0],
              this.dataSource[i].children
            );
            this.currentMenuData = parentObject.children;
          }
          break;
        }
      }
    }
  }

  previousMenuParent(child: any, parent: any): any {
    for (const i in parent) {
      if (child.parent === parent[i].id) {
        this.previousMenuData = parent;
        this.showAll = parent[i];
        return parent[i];
      } else if (parent[i].children.length > 0) {
        this.previousMenuParent(child, parent[i].children);
      }
    }
  }

  handleCategory(category: any) {
    const { slug } = category;
    this.router.navigate(['category', slug]);
    this.menubar.close();
    this.categoryApi.buttonClicked(slug);
  }
}
