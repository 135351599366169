<div class="card">
        <div class="card-img" (click)="itemDetails()">
            <img src="{{item_pic}}{{ itemData.thumbnail_url }}" alt="Item_pic" />
        </div>
        <div class="cardItemInfo">
            <div class="card-title" style="margin: 2px;">
                <b>{{(itemData.display_name.length>50)?(itemData.display_name | slice:0:50)+'.....':(itemData.display_name)}}</b>
            </div>

            <div class="mobile-card-title">
                <b>{{(itemData.display_name.length>30)?(itemData.display_name | slice:0:30)+'.....':(itemData.display_name)}}</b>
            </div>

            <div  class="card-footer">
                <div *ngIf="itemData.offer_price===0" class="price">
                    <b>৳ {{itemData.base_price.toLocaleString('en-BD')}}</b>
                </div>
    
                <div *ngIf="itemData.offer_price!=0" class="price">
                    <b>{{itemData.offer_price.toLocaleString('en-BD')}}৳ </b>
                    <span class="discount"><b>{{itemData.base_price.toLocaleString('en-BD')}}</b></span>
                </div>
    
            </div>
    
            <div *ngIf="itemData.quantity!=0" class="cart" (click)="onAdd(itemData)">
                <i class="fa fa-shopping-cart" aria-hidden="true" ></i>
                Add to Cart
            </div>
    
            <div *ngIf="itemData.quantity===0" style="color: red;">
                Out of Stock
            </div>
        </div>
    </div>

<script src="https://use.fontawesome.com/c2770935a3.js"></script>
