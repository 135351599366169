import { OfferApiService } from './../../core/services/offer-api.service';
import { Component, OnInit } from '@angular/core';
import { Color } from 'src/app/shared/classes/color';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent implements OnInit {
  color = new Color();
  cardOfferData: any;
  response: boolean = false;
  loading: boolean = true;

  constructor(private offterApi: OfferApiService,) { 
    this.offterApi.getCardOffers().subscribe(
      (cardOffer: any) => {
        this.cardOfferData = cardOffer;
        this.response = true;
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  ngOnInit(): void {
  }

}
