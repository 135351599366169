import { ApiPaths } from './../../enums/api-paths.enum';
import { environment } from './../../../environments/environment';
import { ConfigurationService } from './configuration.service';
import { DataStoreService } from './data-store.service';
import { Xpos, XposCategory, XposItem, EcomCategory, demoItem, UserProfile } from './../model/category.model';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';



@Injectable({
  providedIn: 'root'
})

export class ApirequestService {
  categories: EcomCategory[] = [];
  categoriesSide: EcomCategory[] = [];
  cate: Xpos[] = [];
  item: demoItem[] = [];
  token: string;
  user: UserProfile;
  group: { group_order: number; group_name: string; items: { order: number; item: demoItem; }[]; }[]

  baseUrl = environment.backEndBaseUrl;

  constructor(private httprequest: HttpClient, private category_data: DataStoreService,
    private config: ConfigurationService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }


  fetchOfferData(): any {
    let url = `${this.baseUrl}${ApiPaths.OFFERS}`
    return this.httprequest
      .get(url)
      .pipe(map((posts => {
        for (const item in posts) {
        }
        return posts;
      }))
      );
  }


  dfs(category: EcomCategory, childrenofRoot: EcomCategory[]) {
    // tslint:disable-next-line: forin
    for (const i in childrenofRoot) {
      if (childrenofRoot[i].id === category.parent) {
        childrenofRoot[i].children.push(category);
        return;
      }
      else if (childrenofRoot[i].children.length > 0) {
        this.dfs(category, childrenofRoot[i].children);
      }
    }
    return;
  }

  // fetch(): any {
  //   return this.httprequest
  //     .get('../assets/XPOSCategory1.json')
  //     // tslint:disable-next-line: variable-name
  //     .pipe(map((posts_category => {
  //       // tslint:disable-next-line: forin
  //       // tslint:disable-next-line: forin
  //       // for (const i in posts_category){
  //       //   if (posts_category[i].level === 'root'){
  //       //     this.categories.push(posts_category[i]);
  //       //   }
  //       // }
  //       for (const i in posts_category) {
  //         // if ( posts_category[i].level !== 'root'){
  //         posts_category[i].children = [];
  //         if (posts_category[i].parent === '0') {
  //           this.categories.push(posts_category[i]);
  //           // this.categories[i].children = [];
  //         }
  //         else if (posts_category[i].root === posts_category[i].parent) {
  //           // tslint:disable-next-line: forin
  //           for (const j in this.categories) {
  //             if (this.categories[j].id === posts_category[i].root) {
  //               this.categories[j].children.push(posts_category[i]);
  //             }
  //           }
  //           // this.categories[1].children.push(posts_category[i]);
  //         }
  //         else {
  //           for (const k in this.categories) {
  //             if (this.categories[k].id === posts_category[i].root) {
  //               this.dfs(posts_category[i], this.categories[k].children);
  //               break;
  //             }
  //           }
  //         }
  //       }
  //       // }
  //       // this.category_data.Category = this.categories;
  //       return this.categories;
  //     }))
  //     );
  // }


  fetchCategoryData(): any {
    let url = `${this.baseUrl}${ApiPaths.CATAGORIES}`
    return this.httprequest
      // .get('../assets/XPOSCategory1.json')
      .get(url)
      // tslint:disable-next-line: variable-name
      .pipe(map((posts_category => {
        // tslint:disable-next-line: forin
        // tslint:disable-next-line: forin
        // for (const i in posts_category){
        //   if (posts_category[i].level === 'root'){
        //     this.categories.push(posts_category[i]);
        //   }
        // }
        // console.log(posts_category)
 
        // detecting roots
        for (const k in posts_category) {
          if (posts_category[k].level === 'root') {
            this.categories.push(posts_category[k]);
            posts_category[k].children = [];
          }
        }
        //detecting subtree
        for (const i in posts_category) {
          if (posts_category[i].level !== 'root') {
            posts_category[i].children = [];
 
            if (posts_category[i].root === posts_category[i].parent) {
              // first level children
              for (const j in this.categories) {
                if (this.categories[j].id === posts_category[i].root) {
                  this.categories[j].children.push(posts_category[i]);
                }
              }
            }
            else {
              for (const k in this.categories) {
                if (this.categories[k].id === posts_category[i].root) {
                  this.dfs(posts_category[i], this.categories[k].children);
                  break;
                }
              }
            }
          }
        }
        // this.category_data.Category = this.categories;
        // console.log(this.categories);
        console.log(this.categories)
        return this.categories;
      }))
      );
  } 

  fetchCategoryDataSide(): any {
    let url = `${this.baseUrl}${ApiPaths.CATAGORIES}`
    return this.httprequest
      .get(url)
      // tslint:disable-next-line: variable-name
      .pipe(map((posts_category => {
        // tslint:disable-next-line: forin
        // tslint:disable-next-line: forin

        for (const k in posts_category) {
          if (posts_category[k].level === 'root') {
            // console.log(posts_category[k]);
            this.categoriesSide.push(posts_category[k]);
            posts_category[k].children = [];
          }
        }

        for (const i in posts_category) {
          posts_category[i].children = [];
          if (posts_category[i].parent === '0') {
            this.categoriesSide.push(posts_category[i]);
            // this.catgoriesSide[i].children = [];
          }
          else if (posts_category[i].root === posts_category[i].parent) {
            // tslint:disable-next-line: forin
            for (const j in this.categoriesSide) {
              if (this.categoriesSide[j].id === posts_category[i].root) {
                this.categoriesSide[j].children.push(posts_category[i]);
              }
            }
            // this.categoriesSide[1].children.push(posts_category[i]);
          }
          else {
            for (const k in this.categoriesSide) {
              if (this.categoriesSide[k].id === posts_category[i].root) {
                this.dfs(posts_category[i], this.categoriesSide[k].children);
                break;
              }
            }
          }
        }
        // this.category_data.Category = this.categories;
        return this.categoriesSide;
      }))
      );
  }

  fetchSearchedItemData(name: string): any {
    this.item = [];    
    let url = `${this.baseUrl}${ApiPaths.SEARCH}${name}/`
  
    return this.httprequest.get(url).pipe(
      map((posts => {
        return posts;
      }))
      );
  }


  fetchOfferItemData(name: string): any {
    let url = `${this.baseUrl}${ApiPaths.OFFER_ITEM}/${name}`
    this.item = [];
    // console.log(name);
    return this.httprequest
      .get(url)
      .pipe(map((posts => {
        console.log(posts);
        // tslint:disable-next-line: forin
        return posts;
      }))
      );
  }


  fetchCategorizedItemData(name: string): any {
    let url = `${this.baseUrl}${ApiPaths.CATAGORIES}/${name}`
    return this.httprequest
      .get(url)
      .pipe(
        catchError(error => {
          console.log(error);
          return of(false);
        }),
        map((posts => {
          console.log(posts);
          // tslint:disable-next-line: forin
          return posts;
        }))
      );
  }


  fetchIndivudualPersonData(): any {
    // let token = isPlatformBrowser(this.platformId) && localStorage.getItem('token');
    let url = `${this.baseUrl}${ApiPaths.USER_PROFILE}`
    return this.httprequest
      .get(url)
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map(((userDetails: UserProfile) => {
          return userDetails;
        }))
      );
  }


  fetchOrderHistoryData(): any {
    let token = localStorage.getItem("token")
    let url = `${this.baseUrl}${ApiPaths.ORDER_LIST}/${token}`
    return this.httprequest
      .get(url)
      .pipe(
        catchError(error => {
          console.log(error);
          return of(false);
        }),
        map(((posts) => {
          console.log(posts);
          return posts;
        }))
      );
  }


  fetchOrderHistoryDetailsData(name: string): any {
    let url = `${this.baseUrl}${ApiPaths.ORDER_DETAILS}/${name}`
    return this.httprequest
      .get(url)
      .pipe(
        catchError(error => {
          console.log(error);
          return of(false);
        }),
        map(((posts) => {
          console.log(posts);
          return posts;
        }))
      );
  }



  placeOrder(order: any) {
    const {paid_amount, token, delivery_address, items  } = order
    let url = `${this.baseUrl}${ApiPaths.PLACE_ORDER}`
    return this.httprequest
      .post(url, {

        "paid_amount": paid_amount,
        "token": token,
        "delivery_address": delivery_address,
        "items": items
      })
      .pipe(
        catchError(error => {
          console.log(error);
          return of(error);
        }),
        map(post => {
          console.log(post);
          return post;
        }));
  }


  SendContactUsMsg(msg: any) {
    const {name, message, email} = msg
    let url = `${this.baseUrl}${ApiPaths.CONTACT}`

    return this.httprequest
      .post(url, {
        name,
        message,
        email
      })
      .pipe(
        catchError(error => {
          console.log(error);
          return of(error);
        }),
        map(post => {
          console.log(post);
          return post;
        }));
  }


}


