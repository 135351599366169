<ng-container *ngIf="itemDetails;">
    <div *ngIf="itemDetails.length > 0">
        <div class="row">
            <div style="padding-left: 0;" class="col-md-3">
                <div style="margin-left: 3rem;">
                    <div class="filter price-slider-container">
                        <h3 class="filter-title">Filter by Price</h3>
                        <table mat-table [dataSource]="dataSource" class="price-slider-table">
                            <ng-container matColumnDef="from">
                                <mat-cell *matCellDef="let element" class="range-control">
                                    <input class="price-input-min" type="number" step="10" [(ngModel)]="slider1">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="range">
                                <mat-cell style="padding-left: 0;" *matCellDef="let element" class="range">
                                    <ngx-slider [(value)]="slider1" [(highValue)]="slider2" [options]="{
                                    floor: min,
                                    ceil: max,
                                    step:10,
                                    hideLimitLabels: true,
                                    hidePointerLabels: true
                                }"></ngx-slider>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="to">
                                <mat-cell *matCellDef="let element" class="range-control">
                                    <input class="price-input-max" type="number" step="10" [(ngModel)]="slider2">
                                </mat-cell>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </div>

            <!-- IMPORTANT NOTE: Don't remove -->
            <!-- <div style="padding-left: 0;" *ngIf="itemDetails" class="col-md-9">
                    <div style="flex: 8; display: flex; flex-wrap: wrap; justify-content: center;">
                        <div *ngFor="let itm of itemDetails">
                            <app-card *ngIf="(itm.base_price>slider1 || itm.base_price===slider1)
                            && (itm.base_price < slider2 || itm.base_price===slider2)" style="margin: 15px;"
                                [itemData]="itm">
                            </app-card>
                        </div>
                    </div>
                </div> -->

            <div style="padding-left: 0;" *ngIf="itemDetails" class="col-md-9">
                <cdk-virtual-scroll-viewport
                    style="height: 618px;flex: 8; display: flex; flex-wrap: wrap; justify-content: center;"
                    itemSize="1">
                    <div style="flex: 8; display: flex; flex-wrap: wrap; justify-content: center;">
                        <ng-container *cdkVirtualFor="let itm of itemDetails">
                            <app-card *ngIf="(itm.base_price>slider1 || itm.base_price===slider1)
                                            && (itm.base_price < slider2 || itm.base_price===slider2)"
                                [itemData]="itm">
                            </app-card>
                        </ng-container>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>

    </div>



    <ng-container *ngIf="itemDetails">
        <div style="text-align:center; margin: 2rem" *ngIf="itemDetails.length===0" class="noItemFound">
            <img height="300rem" src="assets/images/no_item_found.svg">
            <br>
            <br>
            <br>
            <h2>Product not found!</h2>
        </div>
    </ng-container>

</ng-container>

<div *ngIf="!itemDetails;">
    <div class="spinner-style">
        <mat-spinner></mat-spinner>
    </div>
</div>