import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from './../../core/services/configuration.service';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  passwordHide = true;
  confirmPasswordHide = true;
  passwordPattern = '(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{3,}';
  userMobile: string;
  registerForm: FormGroup;
  otpVerifyForm: FormGroup;
  mobileverified = true;
  otp;
  otpVerified = false; // to check if submitted opt is correct and disable create account button
  wrongOtp = false; // to check submitted otp wrong and show error messages.
  resendOtp = false; // to show resend limit
  resendMessage: string;
  isResendOTPDisable = true;
  isWaitForResend = false;

  sendOTPRequestResolved = false;
  sendOTPRequestResolvedMessage = '';
  IS_MOBILE = true;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private _configuration: ConfigurationService,
    private _snackBar: MatSnackBar
  ) {}

  passValidate: ValidatorFn = (form: FormGroup): ValidationErrors | null => {
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');
    return password &&
      confirmPassword &&
      password.value !== confirmPassword.value
      ? { passValidate: true }
      : null;
  };

  ngOnInit(): void {
    if (localStorage.getItem('mobile')) {
      this.userMobile = localStorage.getItem('mobile');
    }
    this.registerForm = new FormGroup(
      {
        address: new FormControl(null, [
          Validators.required,
          Validators.maxLength(100),
        ]),
        city: new FormControl(null, [Validators.maxLength(40)]),
        password: new FormControl(null, [
          Validators.required,
          Validators.maxLength(40),
          Validators.minLength(5),
        ]),
        confirmPassword: new FormControl(null, [
          Validators.required,
          Validators.maxLength(40),
        ]),
        district: new FormControl(null, [Validators.maxLength(40)]),
        email: new FormControl(null, [Validators.required, Validators.email]),
        phone: new FormControl(this.userMobile, [Validators.maxLength(40)]),
        // postcode: new FormControl(null, [Validators.maxLength(40)]),
        name: new FormControl(null, [
          Validators.required,
          Validators.maxLength(50),
        ]),
        gender: new FormControl('male'),
      },
      {
        validators: [this.passValidate],
      }
    );
    this.otpVerifyForm = new FormGroup({
      otp: new FormControl(null, [Validators.required]),
    });

    // get configuration data
    // this._configuration.getConfiguration().subscribe(
    //   (configurationRes) => {
    //     const {
    //       mobile_verification: mobileVerification,
    //       email_verification: emailVerification,
    //     } = configurationRes;
    //     this.IS_MOBILE = mobileVerification;
    //     if (!mobileVerification && !emailVerification) {
    //       // this.NO_VERIFICATION_NEEDED = true;
    //       this.NO_VERIFICATION_NEEDED = false;
    //     }
    //   },
    //   (errorRes) => {
    //     const { success: isSuccess, message } = errorRes.error;
    //     if (!isSuccess) {
    //       this._snackBar.open(message, 'X', {
    //         duration: 2000,
    //       });
    //     }
    //   }
    // );
  }

  // send OTP by mobile
  onVerify() {
    const registerForm = this.registerForm.controls;
    this.mobileverified = false;
    console.log(this.IS_MOBILE);
    const contact = this.IS_MOBILE //false
      ? registerForm.phone.value
      : registerForm.email.value;
    this.authService.mobileVerify(contact, this.IS_MOBILE).subscribe(
      (otpRes) => {
        this.sendOTPRequestResolved = true;
        this.sendOTPRequestResolvedMessage = otpRes.message;
        console.log(this.sendOTPRequestResolvedMessage);
        
        setTimeout(() => {
          this.isResendOTPDisable = false;
        }, 5000);
      },
      (errorRes) => {
        this.sendOTPRequestResolved = true;
        this.isResendOTPDisable = false;

        if (errorRes.status === 500) {
          this.sendOTPRequestResolvedMessage = 'Internal Server Error!';
        } else {
          const { success: isSuccess, message: errorMessage } = errorRes.error;
          if (!isSuccess) {
            this.sendOTPRequestResolvedMessage = errorMessage;
          }
        }
      }
    );
  }

  // resend verification code
  onTryAgain() {
    this.authService
      .mobileVerify(this.registerForm.controls.phone.value, this.IS_MOBILE)
      .subscribe(
        (post) => {
          setTimeout(() => {}, 5000);
          if (post.success) {
            this.resendOtp = true;
            this.resendMessage = post.message;
          }
        },
        (errorRes) => {
          this.sendOTPRequestResolved = true;
          this.isResendOTPDisable = false;

          if (errorRes.status === 500) {
            this.sendOTPRequestResolvedMessage = 'Internal Server Error!';
          } else {
            const { success: isSuccess, message: errorMessage } =
              errorRes.error;
            if (!isSuccess) {
              this.isResendOTPDisable = true;
              this.sendOTPRequestResolvedMessage = errorMessage;
            }
          }
        }
      );
  }

  // submit OTP code
  onSubmitOTP() {
    const registerForm = this.registerForm.controls;
    const otpVerifyForm = this.otpVerifyForm.controls;
    const otp = otpVerifyForm.otp.value;
    // this.mobileverified = false;
    const contact = this.IS_MOBILE //false
      ? registerForm.phone.value
      : registerForm.email.value;
    this.authService.onSubmitOtp(contact, otp, this.IS_MOBILE).subscribe(
      (otpSubmitRes) => {
        const { success } = otpSubmitRes;

        if (success) {
          this.otpVerified = true;
        }

        // if (post.success) {
        //   this.otpVerified = true;
        // } else {
        //   this.wrongOtp = true;
        // }
      },
      (errorRes) => {
        const { message, success: isSuccess } = errorRes.error;
        if (!isSuccess) {
          this.sendOTPRequestResolved = true;
          this.sendOTPRequestResolvedMessage = message;
        }
      }
    );
  }

  // finally create and account
  onSubmit() {
    if (this.registerForm.valid) {
      if (
        this.registerForm.controls.password.value ===
        this.registerForm.controls.confirmPassword.value
      ) {
        this.authService.Register(this.registerForm).subscribe(
          (registrationRes) => {
            const { success } = registrationRes;
            if (success) {
              this._snackBar.open(
                `Account successfully created. Please login`,
                'X',
                {
                  duration: 2000,
                }
              );
              this.router.navigate(['/login']);
            }
          },
          (errorRes) => {
            const { success: isSuccess, message } = errorRes.error;
            if (!isSuccess) {
              this._snackBar.open(message, 'X', {
                duration: 2000,
              });
            }
          }
        );
      }
    }
  }
}
