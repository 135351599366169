<!-- <div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-4 my-5">
                <h3 class="brand-name">Netstar Private Limited</h3>
                <p style="color: white">
                    SR #312/4, BCS Computer City IDB Bhaban Agargaon Sher-e-Bangla Nogor
                    Dhaka-1207 Bangladesh
                </p>
                <div class="phone-mail-container">
                    <div class="mt-4" style="display: flex; color: white">
                        <p style="font-size: 17px" class="material-icons">call</p>
                        <p style="font-size: 14px; margin-left: 10px">+8801711644148</p>
                    </div>
                    <div style="display: flex; color: white">
                        <p style="font-size: 17px" class="material-icons">mail_outline</p>
                        <p style="font-size: 14px; margin-left: 10px">
                            info@netstar.com.bd
                        </p>
                    </div>
                    <div class="social-icons-container">
                        <h3 class="social-icon-title">Follow Us On:</h3>
                        <div class="social-icons-inner-container">
                            <a href="https://www.facebook.com/netstarltd" target="_blank"><img class="icons-style"
                                    src="assets/social-icons/facebook.png" alt="Facebook" /></a>
                            <a [routerLink]="['']"><img class="icons-style" src="assets/social-icons/instagram.png"
                                    alt="Instagram" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 my-5 about-us-container">
                <div class="ml-5 about-us-inner-container">
                    <h3 style="font-weight: 600">QUICK LINKS</h3>
                    <ul>
                        <li>
                            <a class="quick-links" [routerLink]="['/about-us']">About Us</a>
                        </li>
                        <li>
                            <a class="quick-links" [routerLink]="['/terms-and-condition']">Terms & Condition</a>
                        </li>
                        <li>
                            <a class="quick-links" [routerLink]="['/privacy-policy']">Privacy Policy</a>
                        </li>
                        <li>
                            <a class="quick-links" [routerLink]="['/refund-return']">Refund and Return Policy</a>
                        </li>
                        <li>
                            <a class="quick-links" [routerLink]="['/branches']">Branches</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4 my-5" style="color: white">
                <h3 style="font-weight: 600; text-transform: uppercase">Contact Us</h3>
                <form #footerContactForm="ngForm" (ngSubmit)="getValues(footerContactForm.value)">
                    <input ngModel class="name-field" type="text" placeholder="Your name" name="name" />
                    <br />
                    <input ngModel class="name-field" type="email" placeholder="Your email address" name="email" />
                    <br />
                    <textarea class="f-message-box" ngModel placeholder="Message..." name="message" cols="37"
                        rows="5"></textarea>
                    <br />
                    <div class="send-btn-container">
                        <button class="submit-field">Send</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->

<footer class="footer-09">
    <div class="row w-100 p-2">
        <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
            <div class="pb-3">
                <img class="footer-logo" style="width: 80px;" src="assets/images/zarif-footer.png" alt="">
            </div>
            <div class="block-23 mb-3 mt-1">
                <ul>
                    <li>
                        <a href="#">
                            <span class="material-icons">location_on</span>
                            <span class="text">8, Rambabu Road, Mymensingh.</span>
                        </a>
                    </li>
                    <li>
                        <a href="#"><span class="material-icons">call</span>
                            <span class="text">+880 1914-099665</span>
                        </a>
                    </li>
                    <li>
                        <a href="#"><span class="material-icons">send</span>
                            <span class="text">zarifcommunicationsbd@gmail.com </span>
                        </a>
                    </li>
                    <li>
                        <a href="http://zarifcommunications.com/" target="_blank">
                            <span class='material-icons'>language</span>
                            <span class="text">www.zarifcommunications.com </span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img class="material-icons mr-2" style="margin-left: -10px;"
                                src="assets/payment-method-images/BKash-Logo.svg" alt="bKash" loading="lazy" />
                            <span class="text">01713-027450 </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
            <div class="pb-3">
                <img class="footer-logo" src="assets/images/mmc-footer.png" alt="">
            </div>
            <div class="block-23 mb-3">
                <ul>
                    <li>
                        <a href="#">
                            <span class="material-icons">location_on</span>
                            <span class="text">1, C.K. Gosh Road, Mymensingh</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="material-icons">call</span>
                            <span class="text">+880 1914-099660</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="material-icons">send</span>
                            <span class="text">mmcomputer75@gmail.com </span>
                        </a>
                    </li>
                    <li>
                        <a href="https://mmcomputerbd.com" target="_blank">
                            <span class='material-icons'>language</span>
                            <span class="text">www.mmcomputerbd.com </span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img class="material-icons mr-2" style="margin-left: -10px;"
                                src="assets/payment-method-images/BKash-Logo.svg" alt="bKash" loading="lazy" />
                            <span class="text">01713-027450 </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
            <div class="pb-3">
                <img class="footer-logo" src="assets/images/z-fresh-farm-s.png" alt="">
            </div>
            <div class="block-23 mb-3">
                <ul>
                    <li>
                        <a href="#">
                            <span class="material-icons">location_on</span>
                            <span class="text">8, Rambabu Road, Mymensingh</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="material-icons">call</span>
                            <span class="text">+880 1914-099670</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="material-icons">send</span>
                            <span class="text">zariffreshfarm@gmail.com </span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img class="material-icons mr-2" style="margin-left: -10px;"
                                src="assets/payment-method-images/BKash-Logo.svg" alt="bKash" loading="lazy" />
                            <span class="text">01713-027450 </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-6 col-lg-3 mb-md-0 mb-4">
            <div class="pb-3">
                <img class="footer-logo" src="assets/images/z-tours-travels-s.png" alt="">
            </div>
            <div class="block-23 mb-3">
                <ul>
                    <li>
                        <a href="#">
                            <span class="material-icons">location_on</span>
                            <span class="text">8, Rambabu Road, Mymensingh</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="material-icons">call</span>
                            <span class="text">+880 1914-099669</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <span class="material-icons">send</span>
                            <span class="text">zariftourstravels@gmail.com </span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img class="material-icons mr-2" style="margin-left: -10px;"
                                src="assets/payment-method-images/BKash-Logo.svg" alt="bKash" loading="lazy" />
                            <span class="text">01713-027450 </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div style="border-top: 1px solid #182432">
        <div class="container pt-3">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                    <p class="menu">
                        <a [routerLink]="['/about-us']" class="py-1 ">About Us</a>
                        <a [routerLink]="['/terms-and-condition']" class="py-1 ">Terms & Condition</a>
                        <a [routerLink]="['/privacy-policy']" class="py-1 ">Privacy Policy</a>
                        <a [routerLink]="['/refund-return']" class="py-1 ">Refund and Return Policy</a>
                    </p>
                    <div class="copyright-container">
                        <ul class="ftco-footer-social p-0">
                            <li class="ftco-animate">
                                <a href="http://www.facebook.com/zarifcommunications" target="_blank"><img
                                        class="icons-style" src="assets/social-icons/fb.png" alt="Facebook" /></a>
                            </li>
                            <li class="ftco-animate">
                                <a href="#" target="_blank"><img class="icons-style"
                                        src="assets/social-icons/instagram.png" alt="Instagram" /></a>
                            </li>
                            <li class="ftco-animate">
                                <a href="#" target="_blank"><img class="icons-style"
                                        src="assets/social-icons/youtube.png" alt="Instagram" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <img class="w-100" src="assets/payment-method-images/pay.png" alt="Payment Method" loading="lazy" />
        <p class="copyright-text text-center mb-0">
            Powered By
            <a href="https://esl.com.bd/" target="_blank">Express Systems Ltd.</a>
        </p>
    </div>
</footer>