import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CategoryDataService {
  categories: any;
  rawCategories: any;
  public configObservable = new Subject<any>();
  public rawCategory = new Subject<any>();

  public gotToFinalStep: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}
  getCategory(): any {
    return this.categories;
  }
  getToken(): any {
    return isPlatformBrowser(this.platformId) && localStorage.getItem('token');
  }

  emitConfig(val: any): any {
    this.configObservable.next(val);
  }
  emitRawCategory(val: any): any {
    this.rawCategory.next(val);
  }

  sortObjectByName(obj: any){
    this.sortName(obj)
    for (let i in obj) {
      if (obj[i].children.length>0){
        this.sortObjectByName(obj[i].children)
      }
    }
  }

  sortObjectByOrder(obj: any){
    this.sortOrder(obj)
    for (let i in obj) {
      if (obj[i].children.length>0){
        this.sortObjectByOrder(obj[i].children)
      }
    }
  }

  sortName(obj:any){
    obj.sort((a,b)=> a.name.localeCompare(b.name))
  }

  sortOrder(obj:any){
    obj.sort((a,b)=>a.order-b.order)
  }
}
