<div class="user-registration">
  <h2 class="mat-display-1 register-title">Create your Account</h2>
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="pb-3">
    <div class="container custom-container">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline"  [ngClass]=" !mobileverified ? 'input-disable' : '' ">
            <mat-label
              >Full Name <span class="register-star">*</span></mat-label
            >
            <input
              type="text"
              placeholder="Please enter your full name"
              matInput
              formControlName="name"
            />
            <mat-error>Please enter your name!</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input matInput formControlName="phone" readonly />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" [ngClass]=" !mobileverified ? 'input-disable' : '' " >
            <mat-label>Password <span class="register-star">*</span></mat-label>
            <input
              placeholder="Password"
              matInput
              formControlName="password"
              [type]="passwordHide ? 'password' : 'text'"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="passwordHide = !passwordHide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="passwordHide"
            >
              <mat-icon>{{
                passwordHide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
            <mat-error>Please enter your password!</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" [ngClass]=" !mobileverified ? 'input-disable' : '' " >
            <mat-label
              >Confirm Password <span class="register-star">*</span></mat-label
            >
            <input
              placeholder="Confirm password"
              matInput
              formControlName="confirmPassword"
              [type]="confirmPasswordHide ? 'password' : 'text'"
            />
            <button
              type="button"
              mat-icon-button
              matSuffix
              (click)="confirmPasswordHide = !confirmPasswordHide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="confirmPasswordHide"
            >
              <mat-icon>{{
                confirmPasswordHide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <mat-error
            *ngIf="
              registerForm.errors?.passValidate &&
              (registerForm.controls.confirmPassword.touched ||
                registerForm.controls.confirmPassword.dirty)
            "
          >
            Password did not match!
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" [ngClass]=" !mobileverified ? 'input-disable' : '' " >
            <mat-label>Email <span class="register-star">*</span></mat-label>
            <input
              placeholder="Please enter your valid email"
              matInput
              formControlName="email"
            />
            <mat-error>Please enter your email!</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" [ngClass]=" !mobileverified ? 'input-disable' : '' " >
            <mat-label
              >Full Address <span class="register-star">*</span></mat-label
            >
            <input
              placeholder="Please enter your address"
              matInput
              formControlName="address"
            />
            <mat-error>Please enter your address!</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" [ngClass]=" !mobileverified ? 'input-disable' : '' " >
            <mat-label>District</mat-label>
            <input
              placeholder="Please enter your district"
              matInput
              formControlName="district"
            />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <!-- <mat-form-field appearance="outline">
            <mat-label>Postcode</mat-label>
            <input
              placeholder="Please enter your postcode"
              matInput
              formControlName="postcode"
            />
          </mat-form-field> -->
        </div>
      </div>
    </div>

    <form
      [formGroup]="otpVerifyForm"
      (ngSubmit)="onVerify()"
      *ngIf="!otpVerified"
    >
      <div class="container custom-container">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline" *ngIf="!mobileverified">
              <mat-label>Verification Code</mat-label>
              <input
                placeholder="Please enter your verification code"
                matInput
                formControlName="otp"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <button
        [disabled]="!registerForm.valid"
        *ngIf="mobileverified"
        style="width: 60%; margin: 2px; box-shadow: none"
        mat-raised-button
        color="primary"
      >
        Verify Your Mobile Number
      </button>

      <!-- send OTP response message  -->
      <p
        class="verification-text"
        style="color: rgba(0, 0, 0, 0.6)"
        *ngIf="sendOTPRequestResolved"
      >
        {{ sendOTPRequestResolvedMessage }}
      </p>

      <p class="verification-text" *ngIf="wrongOtp">
        Wrong Code! Please Check Again Or Request for Resend
      </p>
      <button
        type="button"
        *ngIf="!mobileverified"
        (click)="onSubmitOTP()"
        style="width: 60%; margin: 2px; margin-bottom: 0.7rem; box-shadow: none"
        mat-raised-button
        color="primary"
      >
        Submit OTP
      </button>
      <button
        [disabled]="isResendOTPDisable ? 'disabled' : null"
        type="button"
        *ngIf="!mobileverified"
        (click)="onTryAgain()"
        style="width: 60%; margin: 2px; box-shadow: none"
        mat-raised-button
        color="primary"
      >
        Resend Verification Code
      </button>
    </form>

    <button
      class="create-ac-btn"
      [disabled]="!otpVerified"
      type="submit"
      style="width: 60%"
      mat-raised-button
      color="primary"
    >
      Create an Account
    </button>
  </form>
</div>
