import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollTopService {

  isBrowser: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
   }

   scrollTop(): any {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        var el: Element;
        if (this.isBrowser) {
          setTimeout(function () {
            // if(this.isB)
            el = document.getElementsByTagName('mat-drawer-content')[0];
            el.scrollTo(0, 10);
          }, 200);
        }
      }
    });
  }
}
