import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoryService } from './../../../core/services/category.service';
import { AuthenticationService } from './../../../core/services/authentication.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { ApirequestService } from 'src/app/core/services/apirequest.service';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { SearchService } from 'src/app/core/services/search.service';

@Component({
  selector: 'app-header-category',
  templateUrl: './header-test.component.html',
  styleUrls: ['./header-category.component.css']
})
export class HeaderCategoryComponent implements OnInit {
  @Input() sidenav!: MatSidenav;
  @Input() menubar!: MatDrawer;
  categories: any;
  toggle = false;
  search! : string;
  itemNumberInCart: number;
  itemNumberInCompare: number;
  isLoggedIn: boolean;
  isShowDiv = false;
  timer: any;
  searchedProducts: any = [];
  searchedCategories: any = [];
  selectedItem: any;
  selectedCategory: any;
  searchControl = new FormControl();

  constructor(
    private categoryApi: CategoryService,
    private dataService: DataStoreService,
    private authenticationStatus: AuthenticationService,
    private searchService: SearchService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authenticationStatus.logInStatus.subscribe(value => {
      this.isLoggedIn = value;
    });
    this.categoryApi.fetchCategoryData().subscribe((posts) => {
        this.categories = posts;
      });
    this.dataService.numberOfItemInCart.subscribe((value) => {
      this.itemNumberInCart = value;
    });

    this.dataService.numberOfCompare.subscribe((value) => {
      this.itemNumberInCompare = value;  
    });
  }

  sidenavToggle(): any {
    this.sidenav.toggle();
  }

  toggleDisplayDiv(): any {
    this.isShowDiv = !this.isShowDiv;
  }
  addNumberInCart() {
    this.itemNumberInCart = this.dataService.getCart().length;
  }

  toggleMenu(): any {
    this.menubar.toggle();
  }

  handleSearch(event: any) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.search?.length > 0) {
        this.searchService.getSearchResults(this.search).subscribe(
          (searchResult: any) => {
            this.searchedProducts = searchResult.items;
            this.searchedCategories = searchResult.categories;
          },
          (error: any) => {
            console.log(error.statusText);
          }
        );
      }
    }, 1000);
  }

  filterChange(event: any): any {
    const selected = event.option.value;
    this.selectedItem = this.searchedProducts.filter(
      (item: any) => item.item_name === selected
    )[0];
    this.selectedCategory = this.searchedCategories.filter(
      (category: any) => category.category_name === selected
    )[0];
    if (this.selectedCategory) {
      this.router.navigate([
        '/category',
        this.selectedCategory.slug
      ]);
    }
    if (this.selectedItem) {
      this.router.navigate([
        '/product-details',
        this.selectedItem.slug
      ]);
      this.categoryApi.itemClickHandler(this.selectedItem.slug);
    }
  }

}
