<div #abcd>
  <button
    style="text-align: left"
    mat-button
    *ngIf="previousMenuData.length > 0"
    (click)="previousMenu()"
  >
    <mat-icon>arrow_back_ios</mat-icon> Back
  </button>
  <mat-list>
    <mat-list-item
      (click)="handleCategory(showAll)"
      *ngIf="previousMenuData.length > 0"
      style="font-weight: 500"
    >
      All {{ showAll.name }}
    </mat-list-item>
    <ng-container *ngFor="let data of currentMenuData">
      <mat-list-item (click)="changeData(data, currentMenuData, currentMenuData.url)">
        {{ data.name }}
        <mat-icon *ngIf="data.children.length > 0">arrow_forward_ios</mat-icon>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-list>
</div>
