import { SearchService } from './../../../core/services/search.service';
import { Color } from './../../../shared/classes/color';
import { CategoryService } from './../../../core/services/category.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css'],
})
export class HeaderNavComponent implements OnInit {
  color = new Color();
  categories: any;
  item: any;
  search: any;
  itemNumberInCart: any;
  itemNumberInCompare: number;
  isLoggedIn: any;
  timer: any;
  searchResults: any = [];
  searchedProducts: any = [];
  searchedCategories: any = [];
  selectedItem: any;
  selectedCategory: any;
  searchControl = new FormControl();

  constructor(
    private categoryApi: CategoryService,
    private dataService: DataStoreService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private searchService: SearchService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataService.numberOfItemInCart.next(this.dataService.getCart().length);

    this.dataService.numberOfItemInCart.subscribe((value) => {
      this.itemNumberInCart = value;
    });
    this.authenticationService.logInStatus.subscribe((value) => {
      this.isLoggedIn = value;
      console.log(this.isLoggedIn);
    });

    this.dataService.numberOfCompare.next(this.dataService.getCompareItem().length);

    this.dataService.numberOfCompare.subscribe((value) => {
      this.itemNumberInCompare = value;  
    });
  }

  filterChange(event: any): any {
    const selected = event.option.value;

    this.selectedItem = this.searchedProducts.find(
      (item: { name: string; slug: string }) => item.name === selected
    );

    this.selectedCategory = this.searchedCategories.find(
      (category: { name: string; slug: string }) => category.name === selected
    );

    if (this.selectedCategory) {
      this.router.navigate(['/category', this.selectedCategory.slug]);
    }
    if (this.selectedItem) {
      this.router.navigate(['/product-details', this.selectedItem.slug]);
      this.categoryApi.itemClickHandler(this.selectedItem.slug);
    }
  }
  addNumberInCart() {
    this.itemNumberInCart = this.dataService.getCart().length;
  }
  handleSearch() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.searchControl.value?.length > 0) {
        this.searchService.getSearchResults(this.searchControl.value).subscribe(
          (searchResult: any) => {
            this.searchedProducts = searchResult.items;
            this.searchedCategories = searchResult.categories;
          },
          (error: any) => {
            this.snackBar.open('Error occur in the server!', 'X', {
              duration: 2000,
            });
          }
        );
      }
    }, 1000);
  }

  navigateTo() {
    this.router.navigate(['/search', this.searchControl.value]);
  }
}
