<!-- <div class="product-card">
  <div *ngIf="itemData.tag_type === 'new'" class="ribbon new">
    <div class="theribbon" style="width: 80px">NEW</div>
    <div class="ribbon-background"></div>
  </div>
  <div *ngIf="itemData.tag_type === 'ipdc'" class="ribbon new">
    <div class="theribbon" style="width: 80px">IPDC</div>
    <div class="ribbon-background"></div>
  </div>
  <div *ngIf="itemData.tag_type === 'upcoming'" class="ribbon new">
    <div class="theribbon">UPCOMING</div>
    <div class="ribbon-background"></div>
  </div>
  <div *ngIf="itemData.tag_type === 'prebook'" class="ribbon new">
    <div class="theribbon">PREBOOK</div>
    <div class="ribbon-background"></div>
  </div>
  <div style="position: relative">
    <a [routerLink]="['/product-details', itemData.url]" class="product-tumb">
      <img
        src="{{ item_pic }}{{ itemData.thumbnail }}"
        alt="{{ itemData.image_text }}"
        loading="lazy"
      />
    </a>
    <div
      *ngIf="
        itemData.sale_price - itemData.offer_price > 0 &&
        itemData.tag_type !== 'upcoming' &&
        itemData.offer_price !== 0 &&
        itemData.quantity !== 0
      "
      class="badge"
    >
      Flat
      {{
        Math.floor(
          ((itemData.sale_price - itemData.offer_price) / itemData.sale_price) *
            100
        )
      }}% Off
    </div>
    <div *ngIf="itemData.tag_type !== 'upcoming'" class="rating">
      {{ itemData.rating | number: "1.1-1" }} ★
    </div>
  </div>

  <div class="product-details">
    <a [routerLink]="['/product-details', itemData.slug]">
      <h5 style="word-break: break-word">
        {{
          itemData.name.length > 30
            ? (itemData.name | slice: 0:45) + "....."
            : itemData.name
        }}
      </h5>
    </a>

    <div class="bottom-container-div">
      <div
        [ngStyle]="{
          'margin-bottom': itemData.tag_type === 'upcoming' && '10px'
        }"
        class="bottom-container"
      >
        <div
          *ngIf="
            itemData.offer_price !== 0 &&
              itemData.sale_price !== itemData.offer_price;
            else just_salePrice
          "
        >
          <div *ngIf="itemData.quantity !== 0">
            <span class="price-after-discount"
              >৳ {{ itemData.offer_price | number }}</span
            >
            <span class="price-before-discount"
              >৳ {{ itemData.sale_price | number }}</span
            >
          </div>
        </div>
        <ng-template #just_salePrice>
          <span *ngIf="itemData.quantity !== 0" class="product-price"
            >৳ {{ itemData.sale_price | number }}
          </span>
        </ng-template>
        <div>
          <span
            *ngIf="itemData.tag_type !== 'upcoming' && itemData.quantity > 0"
            class="material-icons" (click)="addToCart(itemData)"
          >
            shopping_cart
          </span>
          <span
            *ngIf="itemData.tag_type === 'upcoming' || itemData.quantity === 0"
            style="color: #f0f0f0; user-select: none;"
            class="material-icons"
          >
            shopping_cart
          </span>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="row justify-content-center">
  <div class="product-container-card mb-3">
    <div class="products-card">
      <div class="wrapper">
        <div class="product-background-color">
          <div class="first">
            <!-- Tag Type -->
            <div>
              <div *ngIf="itemData.tag_type === 'new'" class="ribbon-new">
                <span>NEW</span>
              </div>
              <div *ngIf="itemData.tag_type === 'ipdc'" class="ribbon-new">
                <span>IPDC</span>
              </div>
              <div *ngIf="itemData.tag_type === 'upcoming'" class="ribbon-new">
                <span>UPCOMING</span>
              </div>
              <div *ngIf="itemData.tag_type === 'prebook'" class="ribbon-new">
                <span>PREBOOK</span>
              </div>
            </div>
            <span class="discount-percent" *ngIf="
                  itemData.sale_price - itemData.offer_price > 0 &&
                  itemData.tag_type !== 'upcoming' &&
                  itemData.offer_price !== 0 &&
                  discountPercent > 0
                ">- {{discountPercent}}%
            </span>
          </div>
        </div>
        <div class="product-image" [style.background-image]="'url(' + item_pic + itemData.thumbnail_url + ')'">
        </div>
        <div class="product-info">
          <a [routerLink]="['/product-details', itemData.slug]">
            <p style="word-break: break-word" class="product-name">
              {{
              itemData.name.length > 30
              ? (itemData.name | slice: 0:45) + "....."
              : itemData.name
              }}
            </p>
          </a>
          <div class="actions">
            <div class="price-group">
              <div *ngIf="
                    itemData.offer_price !== 0 &&
                      itemData.sale_price !== itemData.offer_price;
                    else just_salePrice
                  ">
                <div>
                  <p class="price-info sale-price">৳ {{ itemData.sale_price | number }}</p>
                  <p class="price-info offer-price">৳ {{ itemData.offer_price | number }}</p>
                </div>
              </div>
              <ng-template #just_salePrice>
                <p class="price-info offer-price">৳ {{ itemData.sale_price | number }}
                </p>
              </ng-template>
            </div>
            <!-- Add Cart -->
            <!-- <div class="action add-cart">
              <svg class="outCart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                *ngIf="itemData.tag_type !== 'upcoming' && itemData.quantity > 0"
                (click)="addToCart(itemData)"
              >
                <title>Add to Cart</title>
                <path d="M2 6h10l10 40h32l8-24H16"></path>
                <circle cx="23" cy="54" r="4"></circle>
                <circle cx="49" cy="54" r="4"></circle>
              </svg>
              <svg class="inCart" *ngIf="itemData.tag_type === 'upcoming' || itemData.quantity === 0"></svg>
            </div> -->
          </div>
          <div *ngIf="itemData.quantity < 0">
            <p>Out of Stock</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>