<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <!-- <button type="button" class="btn active" data-toggle="button" aria-pressed="true" (click)="scrollToTop()">
        Go Top
    </button> -->

    <button mat-fab style="background-color: #F44336CC;" aria-label="Go Top" (click)="scrollToTop()" >
        <mat-icon>navigation</mat-icon>
    </button>

  </div>
