import { environment } from './../../../../environments/environment';
import { ConfigurationService } from './../../../core/services/configuration.service';
import { Router } from '@angular/router';
import { demoItem } from './../../../core/model/category.model';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { Component, OnInit, Input} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  item_pic2 = 'assets/images/laptop-item.jpg'

  item_pic = environment.backEndBaseMediaUrl;

  date = new Date();


  @Input() itemData: demoItem;

  constructor(private cartdata: DataStoreService, private snackBar: MatSnackBar, private router: Router, private configUrl : ConfigurationService)
  {

  }

  ngOnInit(): void
  { }


  onAdd(cartitem: demoItem){
    this.cartdata.addtoCart(cartitem);

    console.log('added to cart');

    //-------to show SnackBar or Toast

    this.snackBar.open('Added To Cart', "X", {
      duration: 2000,
    });

    console.log('added tocart');
  }


  itemDetails(){
    this.cartdata.itemdetails = this.itemData.item_id;
    this.router.navigate(['/itemdetails', this.itemData.item_id]);
  }

  compareDays(){

    var startDate = new Date(this.itemData.offer_start_date);
    var endDate = new Date(this.itemData.offer_end_date);

    if((startDate.getTime() / 1000) < (this.date.getTime() / 1000) && (this.date.getTime() / 1000) > (endDate.getTime() / 1000))
    {
      this.itemData.offer_price = 0;
      return "Offer Closed"
    }
    else{
      return "Offer On"
    }

 }

}
