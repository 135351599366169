import { DataStoreService } from 'src/app/core/services/data-store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Color } from '../../classes/color';

@Component({
  selector: 'app-related-product-card',
  templateUrl: './related-product-card.component.html',
  styleUrls: ['./related-product-card.component.css']
})
export class RelatedProductCardComponent implements OnInit {
  color = new Color;
  @Input() item: any;
  configURL_media = environment.backEndBaseMediaUrl;
  itemExceedsStock: any;
  cartItem: any;
  clickedSimilarProduct: boolean = false;
  @Output() btnClick = new EventEmitter();

  constructor(
    private matSnackBar: MatSnackBar,
    private dataStoreService: DataStoreService,
  ) { }

  ngOnInit(): void { }

  addToCart() {
    if (this.dataStoreService.itemExceedsStock(this.item)) {
      this.itemExceedsStock = true;
    } else {
      this.itemExceedsStock = false;
      this.cartItem = {
        item_id: this.item.id,
        name: this.item.name,
        thumbnail: this.item.thumbnail,
        ...this.item,
      };
      this.dataStoreService.addtoCart(this.cartItem);
      this.matSnackBar.open('Added To Cart', 'X', {
        duration: 2000,
      });
    }
  }
  handleTitleClick(navUrl: string) {
    this.btnClick.emit({ url: navUrl });
  }

}
