import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OrderService } from 'src/app/core/services/order.service';
@Component({
  selector: 'app-paymentprocessing',
  templateUrl: './paymentprocessing.component.html',
  styleUrls: ['./paymentprocessing.component.css'],
})
export class PaymentprocessingComponent implements OnInit {
  paymentMethod: string = '';
  paymentStatus: string = '';
  identifier: string = '';
  serverMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  removeSession(): void {
    isPlatformBrowser(this.platformId) && localStorage.removeItem('session');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.paymentMethod = params['method'];
      this.paymentStatus = params['status'];
      this.identifier = params['id'];
    });
    if (this.paymentMethod !== '' && this.paymentMethod === 'bkash') {
      this.orderService
        .BkashPaymentValidation(
          this.paymentMethod,
          this.paymentStatus,
          this.identifier
        )
        .subscribe(
          (posts: any) => {
            if (posts.success) {
              this.serverMessage = posts.msg;
              this.snackBar.open(
                'Your payment has been successfully completed',
                posts.order_no,
                {
                  duration: 2000,
                }
              );
              this.router.navigate(['../profile/orderhistory']);
            }

            // else {
            //   let message = (this.paymentStatus === 'cancel' ? 'Payment Cancelled' : 'Payment Failed')
            //   this.snackBar.open(
            //     message + 'Please Try again later',
            //     'close',
            //     {
            //       duration: 5000,
            //     }
            //   );
            // }
          },
          (error: any) => {
            this.serverMessage = error.error.msg;
            let message =
              this.paymentStatus === 'cancel'
                ? 'Payment Cancelled'
                : 'Payment Failed';
            this.snackBar.open(message + '! Please Try again later', 'close', {
              duration: 5000,
            });
          }
        );
      //   setTimeout(() => {
      //     this.router.navigate(['../eshop/profile/orderhistory']);
      // }, 10000);
    } else {
      this.orderService.DigitalPaymentValidation().subscribe((posts: any) => {
        if (posts.success) {
          this.removeSession();
          this.snackBar.open(
            'Your payment has been successfully completed',
            posts.order_no,
            {
              duration: 2000,
            }
          );
          this.router.navigate(['../profile/orderhistory']);
        } else {
          this.removeSession();
          this.snackBar.open(
            'Could Not Proceed your payment,Please try again',
            'X',
            {
              duration: 2000,
            }
          );
          this.router.navigate(['../profile/orderhistory']);
        }
      });
    }
  }
}
