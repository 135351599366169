import { ActivatedRoute, Params } from '@angular/router';
import { OfferApiService } from './../../core/services/offer-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer-items',
  templateUrl: './offer-items.component.html',
  styleUrls: ['./offer-items.component.css']
})
export class OfferItemsComponent implements OnInit {

  dataSource: any;
  description: string = '';
  displayedColumns: any;
  error: any;
  filteredItems: any;
  filterTitle: string = '';
  filter_1 = 0;
  maxPrice = 200000;
  filter_2 = this.maxPrice;
  headerText: any;
  items: any;
  itemDetails: any;
  isUpcomming: boolean = false;
  isUpcommingText: string = '';
  inputtedMinPrice = 0;
  inputtedMaxPrice = this.maxPrice;
  lowHigh: boolean = false;
  max = 0;
  min = 10000000;
  offerSlug: string = '';
  paramsubs: any;
  serverResponse = true;
  slider1: any;
  slider2: any;

  
  itemFiltered() {
    return this.itemDetails.filter(
      (x: any) =>
        (x.offer_price == 0 &&
          x.base_price < this.max &&
          x.base_price >= this.min) ||
        (x.offer_price > 0 &&
          x.offer_price < this.max &&
          x.offer_price >= this.min)
    );
  }

  findMaxPrice() {
    let i = 1;

    let maxValue = 0;

    for (i = 0; i < this.itemDetails.length; i++) {
      if (this.itemDetails[i].base_price > maxValue) {
        maxValue = this.itemDetails[i].base_price;
      }
    }

    let maja = maxValue % 1000;

    if (maja === 0) {
      return maxValue + 1000;
    } else {
      return maxValue + 1000 - maja;
    }

    return maxValue + 1000;
  }

  constructor(
    private route: ActivatedRoute,
    private offerApi: OfferApiService,
  ) { }

  ngOnInit(): void {
    this.paramsubs = this.route.params.subscribe((params: Params) => {
      this.offerSlug = this.route.snapshot.params.offerSlug;
      this.offerApi.getOfferItems(this.offerSlug).subscribe(
        (post: any) => {
          if (!post) {
            this.serverResponse = false;
          } else if(post.hasOwnProperty('upcoming_text')){
            this.isUpcomming = true
            this.isUpcommingText = post.upcoming_text
            
          } else {
            this.filterTitle  = 'FILTER BY'
            this.serverResponse = true;
            // this.items = post;
            // this.filteredItems = post;
            this.items = post.item_data;
            this.filteredItems = post.item_data;
            this.headerText = post.header_text;
            this.description = post.description;

            this.min = 10000000;
            this.max = 0;
            for (const i in this.items) {
              if (+this.filteredItems[i].sale_price <= this.min) {
                this.min = +this.filteredItems[i].sale_price;
              }
              if (+this.filteredItems[i].sale_price >= this.max) {
                this.max = +this.filteredItems[i].sale_price;
              }
            }
            this.slider1 = this.min;
            this.slider2 = this.max;
            this.dataSource = [{ from: this.min, to: this.max }];
            this.displayedColumns = ['range', 'from', 'to'];
          }
        },
        (error: any) => {
          this.serverResponse = true;
          this.error = error;
        }
      );
    });
  }

  onInputChange(event: any, filterNumber: number) {
    if (filterNumber === 1) {
      this.filter_1 = event.value;
    } else {
      this.filter_2 = event.value;
    }

    if (this.filter_1 < this.filter_2) {
      this.inputtedMinPrice = this.filter_1;
      this.inputtedMaxPrice = this.filter_2;
    } else {
      this.inputtedMinPrice = this.filter_2;
      this.inputtedMaxPrice = this.filter_1;
    }
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  sortingProductList() {
    this.lowHigh = !this.lowHigh;

    if (this.lowHigh) {
      this.filteredItems = [...this.filteredItems].sort(
        (a: any, b: any) => a.sale_price - b.sale_price
      );
    } else {
      this.filteredItems = [...this.filteredItems].sort(
        (a: any, b: any) => b.sale_price - a.sale_price
      );
    }
  }

}
