import { Color } from 'src/app/shared/classes/color';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-group-card',
  templateUrl: './home-group-card.component.html',
  styleUrls: ['./home-group-card.component.css']
})
export class HomeGroupCardComponent implements OnInit {

  @Input() itemData: any;
  color= new Color();
  itemName: any;
  item_pic = environment.backEndBaseMediaUrl;
  Math = Math;

  constructor() { }

  ngOnInit(): void {
    this.itemData = {
      ...this.itemData,
      sale_price: parseInt(this.itemData.sale_price),
    };
    this.shortString(this.itemData.name, 30);
  }

  shortString (text:string,max:number) {
    this.itemName = text && text.length > max ? text.slice(0,max).split(' ').slice(0, -1).join(' ') : text;
    return this.itemName;
  }

}
