import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApirequestService } from 'src/app/core/services/apirequest.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  footerText = 'All Right Reserved By  ©eSecureSot Technologies Ltd.';

  social_sites = [
    { ss_icon: 'assets/images/fb-logo.png', ss_link: '#ss1' },
    { ss_icon: 'assets/images/fb-logo.png', ss_link: '#ss2' },
    { ss_icon: 'assets/images/fb-logo.png', ss_link: '#ss3' },
  ];

  constructor(
    private snackBar: MatSnackBar,
    private apiService: ApirequestService
  ) {}

  ngOnInit(): void {}

  getValues(val) {

    if (val.message === '' || val.name === '' || val.email === '') {
      this.snackBar.open('Please Insert All Data', 'X', {
        duration: 2000,
      });
    } else {
      if (val.email.includes('@')) {
        //------- Send OTP -----------

        this.apiService.SendContactUsMsg(val).subscribe((post: any) => {

          if (post.success) {
            this.snackBar.open('Message Sent', 'X', {
              duration: 2000,
            });
          } else {
            this.snackBar.open('Message Not Sent. Please Try again', 'X', {
              duration: 2000,
            });
          }
        });
      } else {
        this.snackBar.open('Please Insert Valid Email', 'X', {
          duration: 2000,
        });
      }
    }
  }
}
