import { RouterModule } from '@angular/router';
import { MaterialModule } from './classes/material-module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './components/card/card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { BrandCardComponent } from './components/brand-card/brand-card.component';
import { OfferCardComponent } from './components/offer-card/offer-card.component';
import { HomeGroupCardComponent } from './components/home-group-card/home-group-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductsPriceSortComponent } from './components/products-price-sort/products-price-sort.component';
import { RatingComponent } from './components/rating/rating.component';
import { RelatedProductCardComponent } from './components/related-product-card/related-product-card.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SubOrderCardComponent } from './components/sub-order-card/sub-order-card.component';
import { CategoryCardComponent } from './components/category-card/category-card.component';

@NgModule({
  declarations: [
    CardComponent,
    BrandCardComponent,
    OfferCardComponent,
    HomeGroupCardComponent,
    ProductCardComponent,
    ProductsPriceSortComponent,
    RatingComponent,
    RelatedProductCardComponent,
    SafeHtmlPipe,
    NotFoundComponent,
    SubOrderCardComponent,
    CategoryCardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatCarouselModule.forRoot(),
    MaterialModule,
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    CommonModule,
    MatCarouselModule,
    NgxSliderModule,
    BrandCardComponent,
    OfferCardComponent,
    HomeGroupCardComponent,
    ProductCardComponent,
    ProductsPriceSortComponent,
    RatingComponent,
    RelatedProductCardComponent,
    SafeHtmlPipe,
    NotFoundComponent,
    SubOrderCardComponent,
    CategoryCardComponent,
  ],
})
export class SharedModule {}
