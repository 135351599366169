<div *ngIf="details" style="overflow: hidden" class="container-fluid">
  <div class="row mt-2">
    <div class="col-12">
      <app-breadcrumb [categorySlug]="categorySlug"></app-breadcrumb>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <div class="p-tag-container">
        <!-- <div *ngIf="details?.tag_type === 'new'" class="ribbon-new">
          <span>NEW</span>
        </div>
        <div *ngIf="details?.tag_type === 'ipdc'" class="ribbon-new">
          <span>IPDC</span>
        </div>
        <div *ngIf="details?.tag_type === 'upcoming'" class="ribbon-new">
          <span>UPCOMING</span>
        </div>
        <div *ngIf="details?.tag_type === 'prebook'" class="ribbon-new">
          <span>PREBOOK</span>
        </div> -->
        <ngx-gallery
          [options]="galleryOptions"
          [images]="galleryImages"
          style="display: flex; justify-content: center; width: 100%"
        ></ngx-gallery>
      </div>
    </div>
    <div class="col-md-6" style="background-color: lavender">
      <div class="outer-product-summary">
        <div class="d-flex">
          <div>
            <a
              [routerLink]="['/brands', details?.brand_url]"
              class="brand-name"
            >
              {{ details?.brand_name }}
            </a>
            <span
              *ngIf="details?.supplier"
              class="rating-and-all"
              style="border-left: 1px solid #ccc; margin-left: 0.5em"
              >{{ details?.supplier }}
            </span>
          </div>
          <div *ngIf="details?.tag_type === 'new'" class="ribbon-newt">
            NEW
          </div>
          <div *ngIf="details?.tag_type === 'ipdc'" class="ribbon-newt">
            IPDC
          </div>
          <div *ngIf="details?.tag_type === 'upcoming'" class="ribbon-newt">
            UPCOMING
          </div>
          <div *ngIf="details?.tag_type === 'prebook'" class="ribbon-newt">
            PREBOOK
          </div>
        </div>

        <div>
          <h1 class="item-title">
            {{ details?.name }}
          </h1>
        </div> 

        <!-- regular price -->
        <div *ngIf="hideSalePrice" class="item-price">
          <span class="price-outer-span">
            <div>
              <p
                style="margin-bottom: 0"
                *ngIf="details?.offer_price === 0 && details?.quantity !== 0"
                class="price"
              >
                <b> &#2547; {{ details?.sale_price | number }}</b>
              </p>

              <div
                *ngIf="details?.offer_price !== 0 && details?.quantity !== 0"
                class="price"
              >
                <b> &#2547; {{ details?.offer_price | number }} </b>
                <span class="discount"
                  ><b> &#2547; {{ details?.sale_price | number }}</b></span
                >
              </div>
            </div>
          </span>
        </div>

        <!-- Variant price -->
        <div *ngIf="showIPDCPrice">
          <div class="item-price">
            <span class="price-outer-span">
              <div>
                <p style="margin-bottom: 0" class="price">
                  <b *ngIf="largerPrice !== 0"
                    >&#2547; {{ largerPrice | number }}</b
                  >
                </p>
              </div>
            </span>
          </div>
        </div>

        <!-- <div
          *ngIf="details?.price_variant.length !== 0 && details?.quantity !== 0"
        >
          <div *ngFor="let variation of details?.price_variant">
            <div *ngIf="variation.variant === 'IPDC'">
              <h2 class="price-option-title">Price options</h2>
              <mat-radio-group
                aria-label="Select an option"
                [(ngModel)]="priceMode"
              >
                <div class="product-price-options">
                  <mat-radio-button
                    class="regular-option"
                    (change)="priceOptionChange($event)"
                    value="0"
                  >
                    <div>
                      <div style="font-size: 14px">Cash/online payment</div>
                      <span> </span>
                    </div>
                  </mat-radio-button>

                  <span *ngFor="let variant_value of variation.value">
                    <mat-radio-button
                      class="other-option"
                      (change)="priceOptionChange($event)"
                      value="{{
                        variant_value.id + '#' + variant_value.variation_price
                      }}"
                    >
                      <div>
                        <span class="regular-option-price">
                          {{ variant_value.variation_name }}</span
                        >
                        <div class="other-payment-medium">
                          Payment Through {{ variation.variant }}
                        </div>
                      </div>
                    </mat-radio-button>
                  </span>
                </div>
              </mat-radio-group>
            </div>
            <div *ngIf="variation.variant === 'Color'">
              <h3 class="color-option-title">Color: {{ currentImageColor }}</h3>
              <div class="product-price-options justify-content-start">
                <button
                  *ngFor="let variant_value of variation.value"
                  class="color-variant"
                >
                  <img
                    class="color-option"
                    (click)="
                      filterImageHandler(
                        variant_value.id,
                        variant_value.related_images_id,
                        variant_value.variation_name,
                        variant_value.variation_price
                      )
                    "
                    src="{{ item_pic }}{{ variant_value.thumbnail_url }}"
                    loading="lazy"
                  />
                </button>
                <div class="d-flex align-items-center">
                  <a
                    (click)="filterImageHandler(0, [-1], '-1', 0)"
                    class="clear-filter"
                    >Clear Filter</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="d-flex">
          <p *ngIf="details?.quantity > 0">
            <span class="in-stock">In stock</span>
          </p>
          <p *ngIf="details?.quantity <= 0">
            <span class="out-stock">Out Of Stock</span>
          </p>

          <p
            style="margin-left: 8px"
            *ngIf="
              1 <= details?.quantity &&
              details?.quantity <= details?.min_stock_quantity
            "
          >
            <span
              style="
                padding-right: 5px;
                padding-left: 5px;
                border-left: 1px solid #ccc;
                color: red;
              "
              >Hurry-up! Only {{ details?.quantity }} left</span
            >
          </p>
        </div>

        <div class="d-flex">
          <app-rating [rating]="details?.rating"></app-rating>
          <span class="rating-and-all">
            <a>
              ({{ details?.rating_and_review?.length || 0 }}  reviews)
            </a>
          </span>
          <span class="rating-and-all">
            {{ details?.code }}
          </span>
        </div>

        <hr
          style="margin-top: 0"
          *ngIf="details?.quantity !== 0 && details?.tag_type !== 'upcoming'"
        />
        <div class="item-summary-container">
          <h2 style="margin-bottom: 10px; text-transform: uppercase;">Key Features</h2>
          <div
            class="short-description"
            [innerHTML]="details?.short_description | safeHtml"
          ></div>
          <small *ngIf="details?.tag_type === 'prebook'" class="pre-book-msg"
            >*To pre-book the product you must pay 10%</small
          >
        </div>
        <div style="margin-top: 1rem">
          <div style="padding-right: 0">
            <div
              *ngIf="
                details?.quantity > 0 && details?.tag_type !== 'upcoming'
              "
              class="all-cart-btn d-flex justify-content-between justify-content-sm-start"
            >
              <select
                class="quantity-select"
                name="quantity"
                [(ngModel)]="quantity"
              >
                <option *ngFor="let quantity of stock" [ngValue]="quantity">
                  {{ quantity }}
                </option>
              </select>
              <button
                (click)="addToCartHandler()"
                class="add-cart-btn rounded-pill d-flex align-items-center justify-content-between mr-2"
              >
                Add to cart
                <span class="features-btn rounded-circle d-flex align-items-center justify-content-center" >
                  <svg
                    class="rounded-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    viewBox="0 0 24 24"
                    width="16"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </span>
              </button>
              <button
                (click)="handleByNowClick()"
                class="buy-btn rounded-pill d-flex align-items-center justify-content-between mr-2"
              >
                Buy Now
                <span class="rounded-circle d-flex align-items-center justify-content-center" >
                  <img class="features-img" src="assets/images/basket.jpg" alt="">
                </span>
              </button>
              
              <button
                (click)="addToCompareHandler()"
                class="add-cart-btn compare-btn rounded-pill d-flex align-items-center justify-content-between mr-2"
              >
                Add to compare
                <span class="features-btn rounded-circle d-flex align-items-center justify-content-center" >
                  <svg
                    class="rounded-circle"
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    viewBox="0 0 24 24"
                    width="16"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="container groupView">
      <div style="margin-top: 1rem">
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="Full Specification">
            <div class="item-summary-container container col-md-12">
              <div
                class="long-table"
                [innerHTML]="details?.long_description | safeHtml"
              ></div>
            </div>
          </mat-tab>
          <mat-tab label="Reviews">
            <div class="container">
              <div class="row p-2">
                <div class="col-md-8">
                  <h2>
                    Reviews ({{ details?.rating_and_review?.length || 0 }})
                  </h2>
                  <p>
                    Reviews for
                    <span style="color: #257992; font-weight: bold">
                      {{ details?.name }}</span
                    >
                  </p>
                </div>
                <div
                  class="col-md-4 d-flex justify-content-md-end justify-content-start align-items-center padding-right: 30px"
                >
                  <div>
                    <button
                      class="submit-review-btn"
                      mat-raised-button
                      (click)="handleWriteReviewClick()"
                    >
                      <span>Give review & rating</span>
                    </button>
                  </div>
                </div>
                <hr style="width: 100%" />
                <div class="col-12" *ngIf="showWriteReview">
                  <div *ngIf="isLoggedIn">
                    <app-review
                      (reviewSubmit)="handleReviewSubmit()"
                      [itemId]="details.id"
                    ></app-review>
                    <hr style="width: 100%" />
                  </div>
                  <div
                    class="alert alert-primary"
                    role="alert"
                    *ngIf="!isLoggedIn"
                  >
                    Please <a [routerLink]="['/login']">login</a> to write
                    review
                  </div>
                </div>

                <div class="col-12">
                  <span *ngFor="let item of details?.rating_and_review">
                    <app-review-card [review]="item"></app-review-card>
                  </span>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div class="row my-3">
    <div
      *ngIf="similarProducts?.length !== 0"
      class="container-fluid similar-container"
    >
      <div class="d-flex align-items-center">
        <h4 class="related-title">Similar Product</h4>
        <div class="label"></div>
      </div>
      <div *ngIf="similarProducts" class="related-product">
        <!-- <cdk-virtual-scroll-viewport
        class="product-wrapper-container"
        itemSize="1"
      >
        <ng-container *cdkVirtualFor="let itm of similarProducts">
          <app-related-product-card
            (btnClick)="changeView($event)"
            [item]="itm"
          ></app-related-product-card>
        </ng-container>
      </cdk-virtual-scroll-viewport> -->
        <div
          class="col-6 col-sm-4 col-md-3 col-lg-3"
          *ngFor="let itm of similarProducts"
          style="margin: 0 -10px"
        >
          <app-related-product-card
            (btnClick)="changeView($event)"
            [item]="itm"
          >
          </app-related-product-card>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile Device -->
  <div *ngIf="similarProducts?.length !== 0" class="row d-block d-sm-none">
    <div class="col-12">
      <div class="related-container">
        <h2 class="related-title">Similar Product</h2>
        <div *ngIf="similarProducts">
          <cdk-virtual-scroll-viewport
            class="product-wrapper-container"
            itemSize="1"
          >
            <ng-container *cdkVirtualFor="let itm of similarProducts">
              <app-related-product-card
                (btnClick)="changeView($event)"
                [item]="itm"
              ></app-related-product-card>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="spinner" *ngIf="!details">
  <div class="spinner-style">
    <mat-spinner></mat-spinner>
  </div>
</div>
