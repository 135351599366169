import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushSaleService {

  constructor(private http: HttpClient) {}

  getPushSaleProducts(productIds: string): any {
    return this.http
      .get(environment.backEndBaseUrl + `related-items/${productIds}/`)
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((pushSaleProducts) => {
          return pushSaleProducts;
        })
      );
  }
}
