import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { Xpos, XposItem, demoItem, UserProfile } from './../model/category.model';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  category: Xpos[] = [];
  item: XposItem[] = [];
  itemdetails: number;
  user: UserProfile;
  userToken: string;
  homeGroupItems;
  itemsFromCart: {
    item: any;
    quantity: number;
    totalPrice: number;
  }[] = [];
  cartItemWithQuantity: { item: demoItem, quantity: number, totalPrice: number }[] = [];
  itemsFromCompare = [];

  public numberOfItemInCart: BehaviorSubject<number> = new BehaviorSubject<number>(13);
  public numberOfCompare: BehaviorSubject<number> = new BehaviorSubject<number>(3);

  ticker: any;
  offerData: any;

  private actionSourceForProfile: Subject<UserProfile> = new Subject<UserProfile>();
  private userTokenSubject: Subject<string> = new Subject<string>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private snackBar: MatSnackBar,
  ) {
    this.actionSourceForProfile.subscribe((userProfile) => {
      this.user = userProfile
    })
    this.userTokenSubject.subscribe((userToken) => {
      this.userToken = userToken
      localStorage.setItem('token', userToken)
    })
  }
  getCategory() {
    return this.category;
  }
  getItem() {
    return this.item;
  }
  getUser() {
    return this.user;
  }
  getHomeItem() {
    return this.homeGroupItems;
  }

  fetchTickerData() { }

  getCart() {
    this.itemsFromCart = isPlatformBrowser(this.platformId) &&
      JSON.parse(localStorage.getItem('cartItems')!);
    if (this.itemsFromCart === null) {
      this.itemsFromCart = [];
    }
    return this.itemsFromCart;
  }

  getCompareItem() {
    this.itemsFromCompare = isPlatformBrowser(this.platformId) &&
      JSON.parse(localStorage.getItem('compareItems')!);
    if (this.itemsFromCompare === null) {
      this.itemsFromCompare = [];
    }
    return this.itemsFromCompare;
  }


  addtoCart(item: any) {
    let j = 0;

    // get current cart
    this.itemsFromCart =
      isPlatformBrowser(this.platformId) &&
      JSON.parse(localStorage.getItem('cartItems')!);

    if (this.itemsFromCart === null) {
      this.itemsFromCart = [];
    }
    for (j = 0; j < this.itemsFromCart.length; j++) {
      if (
        item.item_id === this.itemsFromCart[j].item.item_id && //id of item
        item.name === this.itemsFromCart[j].item.name
      ) {
        // if try to add same item
        if (
          this.itemsFromCart[j].quantity <
          this.itemsFromCart[j].item.quantity &&
          this.itemsFromCart[j].quantity <
          this.itemsFromCart[j].item.max_order_quantity
        ) {
          // for update variant
          this.itemsFromCart[j].item.variants = item.variants;
          this.itemsFromCart[j].item.sale_price = item.sale_price;
          // ---- stock er item er cheye addToCart Press korse kom ba soman ------
          this.itemsFromCart[j].quantity++;
          if (item.offer_price === 0) {
            // this.itemsFromCart[j].totalPrice =
            //   this.itemsFromCart[j].totalPrice + item.sale_price;
            this.itemsFromCart[j].totalPrice = item.sale_price * this.itemsFromCart[j].quantity // 32000 * 2
          } else {
            // this.itemsFromCart[j].totalPrice =
            //   this.itemsFromCart[j].totalPrice + item.offer_price;

            this.itemsFromCart[j].totalPrice = item.offer_price * item.quantity;

          }
        } else {
          this.snackBar.open('You can not order more than 5', 'X', {
            duration: 5000,
          });
        }
        break;
      } else {
        continue;
      }
    }

    if (j === this.itemsFromCart.length) {
      if (item.offer_price === 0) {
        this.itemsFromCart.push({
          item,
          quantity: 1,
          totalPrice: item.sale_price,
        });
      } else {
        this.itemsFromCart.push({
          item,
          quantity: 1,
          totalPrice: item.offer_price,
        });
      }
    }

    isPlatformBrowser(this.platformId) &&
      localStorage.setItem('cartItems', JSON.stringify(this.itemsFromCart));

    this.numberOfItemInCart.next(this.itemsFromCart.length);
  }

  addToCompare(item: any) {
    // get current cart
    this.itemsFromCompare =
      isPlatformBrowser(this.platformId) &&
      JSON.parse(localStorage.getItem('compareItems')!);

    if (this.itemsFromCompare === null) {
      this.itemsFromCompare = [];
    }

    if (this.itemsFromCompare.length != 0) {
      for (let i = 0; i = this.itemsFromCompare.length; i++) {
        if (this.itemsFromCompare[0].category == item.category && this.itemsFromCompare.length <=2 && this.itemsFromCompare[i].id != item.id) {
          this.itemsFromCompare.push(item);
          this.snackBar.open('Added To Compare', 'X', {
            duration: 2000,
          });
        }
        else {
          this.snackBar.open('Category does not match', 'Already added same Item', {
            duration: 5000,
          });  
        }
    }}
    else {
      this.itemsFromCompare.push(item);
    }

    isPlatformBrowser(this.platformId) &&
      localStorage.setItem('compareItems', JSON.stringify(this.itemsFromCompare));

    this.numberOfCompare.next(this.itemsFromCompare.length);

  }

  placeOrder() {
    this.numberOfItemInCart.next(this.cartItemWithQuantity.length);
  }

  // public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  itemExceedsStock(item: any) {
    let exceeds = false;
    this.itemsFromCart =
      isPlatformBrowser(this.platformId) &&
      JSON.parse(localStorage.getItem('cartItems')!);
    if (this.itemsFromCart === null) {
      return exceeds;
    } else {
      this.itemsFromCart.forEach((elem) => {
        if (elem.item.id === item.id && elem.quantity === item.quantity) {
          exceeds = true;
        }
      });
    }
    return exceeds;
  }

  myAccountClicked(user: UserProfile) {
    this.actionSourceForProfile.next(user);
  }
  userTokenUpdated(userToken: string) {
    this.userTokenSubject.next(userToken);
  }


}
