import { retry, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiPaths } from './../../enums/api-paths.enum';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeGroupService {

  baseUrl = environment.backEndBaseUrl;

  constructor(private httpRequest: HttpClient) { }

  getGroupData(): any {
    let url = `${this.baseUrl}${ApiPaths.HOME_GROUP}`;
    return this.httpRequest.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    );
  }

  getHomeGroupProducts(slug: string): any {
    let url = `${this.baseUrl}${ApiPaths.HOME_GROUP}/${slug}`;
    return this.httpRequest.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    );
  }
}
