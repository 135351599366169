import { CategoryDataService } from './../../../core/services/storage/category-data.service';
import { ApirequestService } from 'src/app/core/services/apirequest.service';
import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';

interface FoodNode {
  name: string;
  level: string;
  children?: FoodNode[];
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  treeControl = new NestedTreeControl<FoodNode> (node => node.children);
  dataSource: any;

  hasChild = (_: number, node: any) => node.children && node.children.length > 0;
  constructor(private categoryDataService: CategoryDataService) {
    this.categoryDataService.configObservable.subscribe(value => {
      this.dataSource = value;
    });
  }
  ngOnInit(): void {
  }
}
