<div class="container" style="padding-top: 2rem; padding-bottom: 2rem;">
    <div class="row">
        <div class="col-md-12">
            <h1 class="return-title">RETURN POLICY</h1>
            <span>•	শপ থেকে পণ্য ক্রয়ের ক্ষেত্রে অবশ্যই শপে বিক্রয়কর্মীর সামনে চেক করে কিনবেন। পরবর্তীতে সমস্যা হলে যদি পণ্যে ওয়ারেন্টি থাকে তবে তা ওয়ারেন্টির আওতাভুক্ত হবে।</span> <br>
            <span>• অনলাইন অর্ডারের ক্ষেত্রে পণ্য ডেলিভারি পাবার পর পণ্যে মেনুফেকচারিং ত্রুটি থাকলে আমাদের হটলাইনে ২৪ ঘন্টার মধ্যে জানাতে হবে।</span> <br>
            <span>• ত্রুটিযুক্ত পণ্য আমাদের শপ থেকে পরিবর্তনযোগ্য। এক্ষেত্রে আমাদের এক্সপার্টগন পণ্যে ত্রুটি পর্যবেক্ষণ করে তা পরিবর্তন করার পদক্ষেপ গ্রহন করবেন।</span> <br>
            <span>• ক্রেতা যদি ডেলিভারি ম্যান এর মাধ্যমে ত্রুটিযুক্ত পণ্য পরিবর্তন করতে ইচ্ছুক অথবা পরিবর্তন করতে চান তবে ২০০/- টাকা পরিবর্তন চার্জ প্রযোজ্য হবে। বাইরের ক্ষেত্রে শুধুমাত্র কুরিয়ার চার্জ প্রযোজ্য হবে। পণ্য আনার পর যদি পণ্য ভাঙ্গা অথবা পোড়া/ জ্বলা অবস্থায় পাওয়া যায় তবে সেই পণ্যের সম্পূর্ণ দায়িত্ব ক্রেতাকে বহন করতে হবে।</span> <br>
            <span>• জারিফ কমিউনিকেশনস এর ওয়েবসাইটে থাকা বিবরণী দেখে ক্রয়কৃত পণ্য ডেলিভারি কর্মী থেকে রিসিভ করার পর তা আপনার নির্দিষ্ট ডিভাইসে সাপোর্ট করছে না অথবা তা এখন আর কিনতে ইচ্ছুক নন, এসকল কারনে পণ্য ফেরত অথবা পরিবর্তনযোগ্য নয়।</span> <br>
            <span>• কোন ধরণের সফটওয়্যার/সফটওয়্যার লাইসেন্স ক্রয়ের পর তা রিটার্ন অথবা রিফান্ডযোগ্য নয়।</span> <br>
            <span>• নির্দিষ্ট কারনে পণ্য রিটার্ন দেয়ার পর অথবা গ্রহণযোগ্য কারনে মূল্য রিফান্ড করতে ৭ থেকে ১০ কার্যদিবস সময় লাগতে পারে।</span> <br>
            <span>• সকল প্রকার মোবাইল ফিন্যান্সিয়াল সার্ভিস/ অনলাইন গেটওয়ে / POS পেমেন্ট রিফান্ডের ক্ষেত্রে রিফান্ড চার্জ প্রযোজ্য ।</span> <br>
            <span>• কুরিয়ার এর ক্ষেত্রে ক্রেতা অবশ্যই পণ্য ভাঙ্গা থাকলে অথবা প্যাকেট ছেঁড়া থাকলে কুরিয়ার থেকে পণ্য গ্রহণ করবে না। কুরিয়ারে ক্ষতিগ্রস্ত পণ্য ক্রেতা গ্রহণ করলে তা নিজ দায়িত্বে করতে হবে এবং এই ব্যাপারে পরে কোন অভিযোগ গ্রহণযোগ্য হবে না।</span> <br>
            <span>• সম্মানিত ক্রেতাগন যদি পেমেন্ট করার সময় কোন প্রকার ক্যাশব্যাক পেয়ে থাকেন তাহলে রিফান্ড করার সময় ক্যাশব্যাকের সমপরিমান টাকা কেটে রাখা হবে।</span> <br>
        </div>
    </div>
</div>