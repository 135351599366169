import { AppRoutingModule } from './../app-routing.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { HeaderCategoryComponent } from './components/header-category/header-category.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { RecursivemenuComponent } from './components/header-category/recursivemenu/recursivemenu.component';
import {FormsModule} from '@angular/forms';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';

@NgModule({
  declarations: [HeaderNavComponent, HeaderCategoryComponent,
    SideNavComponent, FooterComponent, RecursivemenuComponent, MenuBarComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    FormsModule
  ],
  exports: [
    RouterModule,
    HeaderNavComponent,
    HeaderCategoryComponent,
    SideNavComponent,
    FooterComponent,
    AppRoutingModule,
    MenuBarComponent,
  ],
})
export class BlockModule { }
