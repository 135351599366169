<div class="container-fluid" style="width: 100%">
    <ng-container *ngIf="itemDetails">
      <div *ngIf="itemDetails.length > 0">
        <div class="row">
          <div class="col-lg-2">
            <div style="background: white; margin-top: 1rem">
              <div class="price-slider-container">
                <div>
                  <h3 class="filter-title">FILTER BY</h3>
                  <div style="display: flex; justify-content: center">
                    <table
                      mat-table
                      [dataSource]="dataSource"
                      class="price-slider-table"
                    >
                      <ng-container matColumnDef="from">
                        <mat-cell
                          style="padding-right: 5px"
                          *matCellDef="let element"
                          class="range-control"
                        >
                          <input
                            class="price-input-min"
                            type="number"
                            step="10"
                            [(ngModel)]="slider1"
                          />
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="range">
                        <mat-cell
                          style="padding-left: 0"
                          *matCellDef="let element"
                          class="range"
                        >
                          <ngx-slider
                            [(value)]="slider1"
                            [(highValue)]="slider2"
                            [options]="{
                              floor: min,
                              ceil: max,
                              step: 1,
                              hideLimitLabels: true,
                              hidePointerLabels: true
                            }"
                          ></ngx-slider>
                        </mat-cell>
                      </ng-container>
  
                      <ng-container matColumnDef="to">
                        <mat-cell *matCellDef="let element" class="range-control">
                          <input
                            class="price-input-max"
                            type="number"
                            step="10"
                            [(ngModel)]="slider2"
                          />
                        </mat-cell>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div *ngIf="itemDetails" class="col-md-10">
            <div class="wrapper-container mb-2">
              <h1 class="title-container m-0">
                <strong class="product-brand">{{ headerName}}</strong>
              </h1>
              <div
              style="
                  min-height: 51px;
                  display: flex;
                  align-items: center;
                  padding: 0 15px;
                "
                class="sorting-container"
                >
                <h2 class="title-container m-0">
                  <strong class="number-of-product">{{ itemDetails.length }}</strong>
                  Products shown
                </h2>
                <div
                  style="cursor: pointer"
                  (click)="sortingProductList()"
                  #tooltip="matTooltip"
                  matTooltip="Sort by price"
                  [matTooltipPosition]="'below'"
                  matTooltipHideDelay="1000"
                >
                  <img style="height: 1rem" src="assets/icons/arrow.png" alt="Sort by price" loading="lazy" />
                </div>
              </div>
            </div>
              <div class="product-container">
                <ng-container *ngFor="let itm of itemDetails">
                  <app-product-card
                    *ngIf="
                      (itm.sale_price > slider1 || itm.sale_price === slider1) &&
                      (itm.sale_price < slider2 || itm.sale_price === slider2)
                    "
                    [itemData]="itm"
                  >
                  </app-product-card>
                </ng-container>
              </div>
          </div>
        </div>
      </div>
  
      <ng-container *ngIf="itemDetails.length === 0">
        <app-not-found [msg]="'No product found'"></app-not-found>
      </ng-container>
    </ng-container>
  
    <div *ngIf="!itemDetails">
      <div class="spinner-style">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
  

<!-- IMPORTANT NOTE: Don't remove -->
<!-- <div style="padding-left: 0;" *ngIf="itemDetails" class="col-md-9">
        <div style="flex: 8; display: flex; flex-wrap: wrap; justify-content: center;">
            <div *ngFor="let itm of itemDetails">
                <app-card *ngIf="(itm.base_price>slider1 || itm.base_price===slider1)
                && (itm.base_price < slider2 || itm.base_price===slider2)" style="margin: 15px;"
                    [itemData]="itm">
                </app-card>
            </div>
        </div>
    </div> -->