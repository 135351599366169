import { CategoryService } from './../../core/services/category.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-categorized-products',
  templateUrl: './categorized-products.component.html',
  styleUrls: ['./categorized-products.component.css']
})
export class CategorizedProductsComponent implements OnInit {

  paramsubs: any;
  items: any;
  categorySlug: any;
  serverResponse = false;
  filteredItems: any;
  filteredItemsId = new Set();
  filteredAtt = new Set();
  filter_data: any;
  brands: any;
  metaData: {
    property: string;
    content: string;
  }[] = [];
  metaTitle: string = '';
  min = 100000000;
  max = 0;
  slider1: any;
  slider2: any;
  dataSource: any;
  displayedColumns: any;
  error: any;
  lowHigh: boolean = false;
  attributeWithParents: any = [];
  headerText:any;
  selectedBrand: number = 0;
  checkedItems = new Set();
  screenSize: any;
  description: string = '';

  constructor(
    private categoryApi: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    private title: Title
  ) { 
    this.categorySlug = this.route.snapshot.params.categorySlug;
  }
  
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }
  
  ngOnInit(): void {
    this.prepareCateogiredPageData(this.categorySlug)
    let that = this;
    this.categoryApi.actionSourceObservable.subscribe( {
      next(url){
        that.prepareCateogiredPageData(url)
      }        
    })
  }

  prepareCateogiredPageData(slug:string){
    this.screenSize = window.innerWidth;
      this.items = [];
      this.serverResponse = false;
      this.categoryApi.getCategorizedFilteredData(slug).subscribe(
        (post: any) => {
          if (!post) {
            this.serverResponse = false;
          } else if (post === 500) {
            this.router.navigate(['internal-server-error']);
          } else {
            this.serverResponse = true;
            // for breadcrumb
            this.categorySlug = this.route.snapshot.params.categorySlug; 
            this.items = post.item_data;
            this.filteredItems = post.item_data;
            this.filter_data = post.filter_data;
            this.brands = post.brands;
            this.metaData = post.meta_data;
            this.metaTitle = post.title;
            this.headerText = post.header_text;
            this.description = post.description;

            this.meta.addTags(this.metaData);
            this.setTitle(this.metaTitle);

            for (let i = 0; i < this.brands.length; i++) {
              this.brands[i].isChecked = true;
            }

            this.min = 10000000;
            this.max = 0;
            for (const i in this.items) {
              if (+this.filteredItems[i].sale_price <= this.min) {
                this.min = +this.filteredItems[i].sale_price;
              }
              if (+this.filteredItems[i].sale_price >= this.max) {
                this.max = +this.filteredItems[i].sale_price;
              }
            }
            this.slider1 = this.min;
            this.slider2 = this.max;
            this.dataSource = [{ from: this.min, to: this.max }];
            this.displayedColumns = ['range', 'from', 'to'];
            //check if url has filter
            this.paramsubs = this.route.queryParams.subscribe((params: Params) => {
              let filter_ids = params['filter']
              let brand_id = params['brand']
              if(brand_id){
                this.filterBrandFromUrl({'id':+brand_id})
              }
              if (filter_ids){
                this.filterAttributeFromUrl(filter_ids.split(",").map(Number))
              }
              else{
                this.filterAttributeFromUrl(filter_ids)
              }
            });
            
          }
        },
        (error: any) => {
          this.serverResponse = true;
          this.error = error;
        }
      );
  }

  sortingProductList() {
    this.lowHigh = !this.lowHigh;

    if (this.lowHigh) {
      this.filteredItems = [...this.filteredItems].sort(
        (a: any, b: any) => a.sale_price - b.sale_price
      );
    } else {
      this.filteredItems = [...this.filteredItems].sort(
        (a: any, b: any) => b.sale_price - a.sale_price
      );
    }
  }

  getProductId(id: any, sl: any) {
    if (sl.attr_items.map((itemId: any) => itemId).includes(id)) return id;
  }

  itemExistence(item: any) {
    return this.filteredItemsId.has(item.id);
  }

  changeAttributeUrl(selectedItem: any) {
    // reflect url changes for attribute
    if (selectedItem.is_checked) {
      this.checkedItems.add(selectedItem.attr_value_id);
    } else {
      this.checkedItems.delete(selectedItem.attr_value_id);
    }
    let filterArray = []
    for (let str of this.checkedItems){
        filterArray.push(str)
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { filter: filterArray.join(),
      },
      queryParamsHandling: 'merge', 
    });
  }

  changeBrandUrl(brandId: number){
    //change url for brand
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { brand: brandId,
      },
      queryParamsHandling: 'merge', 
    });
  }

  filterAttributeFromUrl(filterIds: any){
    this.attributeWithParents = []
    if (filterIds){
      for (let i=0;i<this.filter_data.length;i++){
        let value_parent = this.filter_data[i].attribute_name
        for (let j=0;j<this.filter_data[i].attribute_values.length;j++){
          let arr = this.filter_data[i].attribute_values
          let value_id = arr[j].attr_value_id
          let value_items = arr[j].attr_items
          if(filterIds.includes(value_id)){
            this.filter_data[i].is_expanded = "true"
            this.filter_data[i].attribute_values[j].is_checked = "true"
            this.checkedItems.add(value_id)
            filterIds.splice(filterIds.indexOf(value_id),1)
            if (this.attributeWithParents[value_parent]) {
              this.attributeWithParents[value_parent] = [
                ...this.attributeWithParents[value_parent],
                ...value_items,
              ];
            } else {
              this.attributeWithParents[value_parent] = [
                ...value_items,
              ];
            }
          }
        }
      }
    }

    let intersection: any;
    let index = 0;
    for (let i in this.attributeWithParents) {
      if (index === 0) {
        intersection = this.attributeWithParents[i];
      } else {
        intersection = intersection.filter((x: any) =>
          this.attributeWithParents[i].includes(x)
        );
      }
      index = index + 1;
    }

    if (this.selectedBrand !==0 ){
      this.filteredItems = this.items.filter((item:any) => {
        return item.brand === this.selectedBrand
      })
      this.selectedBrand = 0;
      console.log(this.selectedBrand);
    }
    else {
      this.filteredItems = this.items
    }

    if (index !== 0) {
      this.filteredItems = this.filteredItems.filter((item: any) => {
        return intersection.includes(item.id)
      });
    }

    this.min = 10000000;
    this.max = 0;
    for (const i in this.filteredItems) {
      if (+this.filteredItems[i].sale_price <= this.min) {
        this.min = +this.filteredItems[i].sale_price;
      }
      if (+this.filteredItems[i].sale_price >= this.max) {
        this.max = +this.filteredItems[i].sale_price;
      }
    }
    this.slider1 = this.min;
    this.slider2 = this.max;
    this.dataSource = [{ from: this.min, to: this.max }];
    this.displayedColumns = ['range', 'from', 'to'];
  }

  filterBrandFromUrl(brandId: any) {
    if (brandId.id === 0) {
      this.selectedBrand = 0
      this.brands.forEach((x: any) => {
        x.isChecked = true;
      });
    } else {
      this.selectedBrand = brandId.id
      this.brands.forEach((x: any) => {
        if (x.id !== brandId.id) {
          x.isChecked = true;
        }
        else{
          x.isChecked = false
        }
      });
    }
    if (this.selectedBrand === 0 && this.checkedItems.size === 0){
      this.filteredItems = this.items
    }
    else{
      this.filteredItems = this.filteredItems.filter((item: any) => {
        return item.brand === this.selectedBrand
        });
      }
      this.min = 10000000;
      this.max = 0;
      for (const i in this.filteredItems) {
        if (+this.filteredItems[i].sale_price <= this.min) {
          this.min = +this.filteredItems[i].sale_price;
        }
        if (+this.filteredItems[i].sale_price >= this.max) {
          this.max = +this.filteredItems[i].sale_price;
        }
      }
      this.slider1 = this.min;
      this.slider2 = this.max;
      this.dataSource = [{ from: this.min, to: this.max }];
      this.displayedColumns = ['range', 'from', 'to'];

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = window.innerWidth;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    location.reload() 
  }

}
