<div class="category-header-container">
  <div class="basic-container d-none d-md-block">
    <!-- <button mat-icon-button (click)="sidenavToggle()" fxHide.gt-sm>
      <mat-icon>menu</mat-icon>
    </button> -->
    <!-- <div class="category-items">
      <span class="category-item" *ngFor="let item of categories">
        <span *ngIf="item.children.length === 0">
          <button class="cat-btn" mat-button>
            {{ item.name }}
          </button>
        </span>
        <span *ngIf="item.children.length > 0">
          <button
            class="cat-btn"
            mat-button
            [matMenuTriggerFor]="menu.childMenu"
          >
            {{ item.name }}
            <app-recursivemenu
              #menu
              [items]="item.children"
              [name]="item.name"
            ></app-recursivemenu>
          </button>
        </span>
      </span>
    </div>
    <hr> -->
    <div class="container-fluid">
      <div class="category-items">
        <button class="header-btn" type="button" mat-button (click)="toggleMenu()">
          <mat-icon>list</mat-icon>
          Categories
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <a [routerLink]="['campaigns']" class="header-btn" mat-button>
          Campaign
          <mat-icon>keyboard_arrow_down</mat-icon>
        </a>
        <a class="header-btn" mat-button [routerLink]="['brands']">
          Brands
          <mat-icon>keyboard_arrow_down</mat-icon>
        </a>
      </div>

    </div>
  </div>
  <!-- NOTE: FOR SMALL SCREEN -->
  <div class="sm-topBar d-block d-md-none">
    <div class="row justify-content-between align-items-center">
      <div class="col-1" (click)="menubar.toggle()">
        <button class="menu-icon" mat-icon-button fxHide.gt-sm>
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="col-4">
        <a [routerLink]="['']"><img class="mobile-logo" src="assets/images/zarif-footer.png" alt="Brand Logo" /></a>
      </div>
      <div class="col-7">
        <div class="d-flex justify-content-between align-items-center">
          <a (click)="toggleDisplayDiv()" style="
              transform: scale(0.85, 0.85);" class="material-icons search-icon">search</a>
          <a [routerLink]="['cart']" class="material-icons search-icon">
            <span class="material-icons" height="25px" style="outline-style: none;" alt="Shopping Cart"
              matBadge="{{ itemNumberInCart }}" matBadgeColor="warn">shopping_cart</span>
          </a>
          <a #tooltip="matTooltip" class="material-icons search-icon" matTooltip="Compare"
            [matTooltipPosition]="'below'" matTooltipHideDelay="500" [routerLink]="['compare']">
            <mat-icon height="25px" matBadge="{{ itemNumberInCompare }}" matBadgeColor="warn">add_to_queue
            </mat-icon>
          </a>
          <a class="material-icons search-icon" [routerLink]="['profile']">
            <span class="material-icons" *ngIf="!isLoggedIn" height="25px" alt="profile_pic">person_add</span>
          </a>
          <a [routerLink]="['profile']">
            <img *ngIf="isLoggedIn" height="25px" src="assets/icons/profile_image.png" alt="profile_pic" />
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="isShowDiv" class="row">
      <div class="col-12" class="icons" style="width: 100%">
        <form class="example">
          <input type="text" placeholder="Search for..." name="search" [(ngModel)]="search"
            (ngModelChange)="handleSearch($event)" [formControl]="searchControl" />
          <a [routerLink]="['/search', search]">
            <button type="submit">
              <span class="material-icons"> search </span>
            </button>
          </a>
        </form>
      </div>
    </div>
  </div>

  <!-- <span *ngFor="let item of categories">
  <span >
    <ng-container *ngIf="item.children.length > 0">
    <button [matMenuTriggerFor]="i">{{item.name}}

      <ng-template #estimateTemplate let-lessonsCounter="children" let-ll="name">
        <mat-menu #i="matMenu">
          <button *ngFor="let i of lessonsCounter">{{i.name}}</button>
        </mat-menu>
      </ng-template>

    </button>
      <ng-container 
      *ngTemplateOutlet="estimateTemplate;context:item">
    </ng-container>
    
  </ng-container>

  
  </span>
</span> -->
</div>