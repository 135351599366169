<!-- <div class="home-group-card">
    <div *ngIf="itemData.tag_type === 'new'" class="product-tag">
      <div class="theribbon" style="width: 80px">NEW</div>
      <div class="ribbon-background"></div>
    </div>
    <div *ngIf="itemData.tag_type === 'ipdc'" class="product-tag">
      <div class="theribbon" style="width: 80px">IPDC</div>
      <div class="ribbon-background"></div>
    </div>
    <div *ngIf="itemData.tag_type === 'upcoming'" class="product-tag">
      <div class="theribbon">UPCOMING</div>
      <div class="ribbon-background"></div>
    </div>
    <div *ngIf="itemData.tag_type === 'prebook'" class="product-tag">
      <div class="theribbon">PREBOOK</div>
      <div class="ribbon-background"></div>
    </div>
    <a [routerLink]="['/product-details', itemData.url]">
      <div class="image-box">
        <span
          *ngIf="
            itemData.sale_price - itemData.offer_price > 0 &&
            itemData.tag_type !== 'upcoming' &&
            itemData.offer_price !== 0 &&
            itemData.quantity !== 0
          "
          class="product-disc tag-3"
        >
           Flat
          {{
            Math.floor(
              ((itemData.sale_price - itemData.offer_price) /
                itemData.sale_price) *
                100
            )
          }}% Off</span
        >
        <span
        *ngIf="
          itemData.sale_price - itemData.offer_price > 0 &&
          itemData.tag_type !== 'upcoming' &&
          itemData.offer_price !== 0 &&
          itemData.quantity !== 0
        "
        class="product-disc tag-3"
      >
         Flat
        {{
            ((itemData.sale_price - itemData.offer_price) /
              itemData.sale_price) *
              100
         
        }}% Off</span
      >
        <div *ngIf="itemData.tag_type !== 'upcoming'" class="rating">
          {{ itemData.rating | number: "1.1-1" }} ★
        </div>
        <img
          alt="{{ itemData.image_text }}"
          src="{{ item_pic }}{{ itemData.thumbnail_url }}"
          class="product-image img-responsive loadingImage"
          loading="lazy"
        />
      </div>
      <div class="home-group-product">
        <div class="title">
          <span>{{ itemName + "..." }}</span>
        </div>
    
        <div class="price">
          <div
            *ngIf="
              itemData.offer_price !== 0 &&
                itemData.sale_price !== itemData.offer_price;
              else just_salePrice
            "
          >
            <span> &#2547;{{ itemData.offer_price | number }}</span>
            <span class="discount-price"
              >&#2547; {{ itemData.sale_price | number }}</span
            >
          </div>
          <ng-template #just_salePrice>
            <span *ngIf="itemData?.quantity !== 0" class="product-price">
              ৳ {{ itemData.sale_price | number }}
            </span>
          </ng-template>
        </div>
      </div>
    </a>
  </div> -->

<div class="row">
  <div class="col-12">
    <a [routerLink]="['/product-details', itemData.slug]" class="card">
      <div class="image-container">
        <div class="first">
          <!-- Tag Type -->
          <div>
            <div *ngIf="itemData.tag_type === 'new'" class="ribbon-new">
              <span>NEW</span>
            </div>
            <div *ngIf="itemData.tag_type === 'ipdc'" class="ribbon-new">
              <span>IPDC</span>
            </div>
            <div *ngIf="itemData.tag_type === 'upcoming'" class="ribbon-new">
              <span>UPCOMING</span>
            </div>
            <div *ngIf="itemData.tag_type === 'prebook'" class="ribbon-new">
              <span>PREBOOK</span>
            </div>
            <!-- <i class="fa fa-heart-o"></i> -->
          </div>
          <span class="discount-percent" *ngIf="
                itemData.sale_price - itemData.offer_price > 0 &&
                itemData.tag_type !== 'upcoming' &&
                itemData.offer_price !== 0 
              ">FLAT
            {{
            Math.floor(
            ((itemData.sale_price - itemData.offer_price) /
            itemData.sale_price) *
            100
            )
            }}% OFF
          </span>
        </div>
        <img alt="{{ itemData.image_text }}" src="{{ item_pic }}{{ itemData.thumbnail_url  }}" loading="lazy"
          class="img-fluid rounded thumbnail-image" />
      </div>
      <div class="product-detail-container px-2">
        <!-- <div class="d-flex justify-content-between align-items-center">
        </div> -->
        <h5 class="dress-name">{{ itemName + "..." }}</h5>
        <div>
          <div class="d-flex justify-content-between mb-2" *ngIf="
              itemData.offer_price !== 0 &&
                itemData.sale_price !== itemData.offer_price;
              else just_salePrice
            ">
            <span class="new-price">
              &#2547;{{ itemData.offer_price | number }}</span>
            <small class="old-price text-right">&#2547;{{ itemData.sale_price | number }}</small>
          </div>
          <ng-template #just_salePrice>
            <span class="new-price">
              &#2547;{{ itemData.sale_price | number }}
            </span>
          </ng-template>
        </div>
        <div class="d-flex justify-content-between align-items-center pt-1">
          <div>
            <!-- <i class="fa fa-star-o rating-star"></i> -->
            <!-- <span class="material-icons star-icon rating-star"> star</span> -->
            <span class="rating-number">
              ★{{ itemData.rating | number: "1.1-1" }}
            </span>
          </div>
          <span class="buy">BUY +</span>
        </div>
      </div>
    </a>
  </div>
</div>