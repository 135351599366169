import { Injectable } from '@angular/core';

declare const fbq:Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelEventTrackerService {

  constructor() { }

  public trackEvent(eventName:string,properties: Object) {
    if (typeof fbq === 'undefined') {
      return;
    }
    fbq('track', eventName, properties);
  }
}
