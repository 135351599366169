import { AuthenticationService } from './../authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardsGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> | boolean | UrlTree | Promise<boolean> {
      const url: string = state.url;
      localStorage.setItem('url', url);
      return this.checkAuthenticate(url);

  }

  checkAuthenticate(url: string): true | UrlTree{
    if (this.authService.isAuthenticatedGuard()){
      return true;
    }
    else{
      if (url){
        this.authService.url = localStorage.getItem('url');
      }
      this.router.navigate(['login']);
    }
  }
}
