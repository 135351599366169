import { ApiPaths } from './../../enums/api-paths.enum';
import { retry, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfferApiService {

  baseUrl = environment.backEndBaseUrl;

  constructor(private http: HttpClient) { }

  getSilderOffers(): any {
    let url = `${this.baseUrl}${ApiPaths.OFFERS}`
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    );
  }

  getCardOffers(): any {
    let url = `${this.baseUrl}${ApiPaths.OFFERS}?grid=true`
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    )
  }

  getOfferItems(offerSlug: string): any {
    let url = `${this.baseUrl}${ApiPaths.OFFER_RELATED_ITEM}${offerSlug}`
    return this.http.get(url).pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((posts) => {
          return posts;
        })
      );
  }
}
