import { Color } from './../../shared/classes/color';
import { BrandService } from './../../core/services/brand.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {
  categoryCardsData: any;
  color= new Color();

  constructor(private brandService: BrandService) { }

  ngOnInit(): void {
    this.brandService.getBrands().subscribe((brands: any) => {
      this.categoryCardsData = brands;
    })
  }

}
