import { Component, OnInit } from '@angular/core';
import { ApirequestService } from 'src/app/core/services/apirequest.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { demoItem } from 'src/app/core/model/category.model';

@Component({
  selector: 'app-offer-items-view',
  templateUrl: './offer-items-view.component.html',
  styleUrls: ['./offer-items-view.component.css']
})
export class OfferItemsViewComponent implements OnInit {

  min = 10000000;
  max = 0;
  slider1;
  slider2;
  dataSource;
  displayedColumns;
  serverResponse = true;


  searchName: {name: string};

  itemDetails: demoItem[];

  // itemDetails = [ {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Apple Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 100000, offer: 0, quantity: 100},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Mango Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 120000, offer: 90000, quantity: 100},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Apple Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 100000, offer: 0, quantity: 100},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Mango Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 120000, offer: 100000, quantity: 0},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Apple Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 100000, offer: 0, quantity: 100},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Mango Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 120000, offer: 0, quantity: 100},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Apple Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 100000, offer: 75000, quantity: 0},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Mango Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 120000, offer: 0, quantity: 100},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Apple Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 100000, offer: 0, quantity: 100},
  // {SN: 1, item_pic: "assets/images/laptop-item.jpg", name: "Mango Macbook Air 13.3 inch Core i5, 8GB Ram, 128GB hfiweufhoiwejuofiewjiofje", price: 120000, offer: 95000, quantity: 0},]



  maxPrice = 200000

  // maxPrice = Math.max.apply(Math, this.itemDetails.map(function(o) { return o.price; }));


  filter_1 = 0;
  filter_2 = this.maxPrice;

  inputtedMinPrice = 0;
  inputtedMaxPrice = this.maxPrice;

  //------------

//   itemDetails2: {
//     SN: number;
//     item_pic: string;
//     name: string;
//     price: number;
//     offer: number;
//     quantity: number;
// }[] = null;



  itemFiltered()
  {



    // return this.itemDetails.filter(x => x.offer_price==0 && x.base_price<this.inputtedMaxPrice && x.base_price>=this.inputtedMinPrice || x.offer_price>0 && x.offer_price<this.inputtedMaxPrice && x.offer_price>=this.inputtedMinPrice);
    return this.itemDetails.filter(x => x.offer_price==0 && x.base_price<this.max && x.base_price>=this.min || x.offer_price>0 && x.offer_price<this.max && x.offer_price>=this.min);
  }

  findMaxPrice()
  {
    let i = 1;

    let maxValue = 0;

    for(i=0; i<this.itemDetails.length; i++)
    {
      if(this.itemDetails[i].base_price>maxValue)
      {
        maxValue = this.itemDetails[i].base_price;
      }
    }

    let maja = maxValue%1000;

    if(maja === 0)
    {
      return maxValue+1000;
    }
    else
    {
      return maxValue+1000-maja;
    }

    return maxValue+1000;

  }



  paramsubs: Subscription;

  constructor(private apiService: ApirequestService, private route: ActivatedRoute) {  }

  ngOnInit(): void {

    this.searchName = {
      name: this.route.snapshot.params.id,
    };

    this.paramsubs = this.route.params
    .subscribe(
      (params: Params) => {
        this.searchName.name = params.name;
        this.apiService.fetchOfferItemData(this.searchName.name)
        .subscribe(post => {
          this.itemDetails = post;
          this.min = 10000000;
          this.max = 0;
          // tslint:disable-next-line: forin
          for ( const i in this.itemDetails){
            if ( +this.itemDetails[i].base_price <= this.min ){
              this.min = +this.itemDetails[i].base_price;
            }
            if ( +this.itemDetails[i].base_price >= this.max ){
              this.max = +this.itemDetails[i].base_price;
            }
          }
          console.log(this.min);
          console.log(this.max);
          this.slider1 = this.min;
          this.slider2 = this.max;
          this.dataSource = [{from: this.min, to: this.max}];
          this.displayedColumns = ['range', 'from', 'to'];
        });
      }
    );
  }



  onInputChange(event: any, filterNumber: number) {
    console.log('This is emitted as the thumb slides');
    console.log(event.value);

    if (filterNumber === 1)
    {
      this.filter_1 = event.value;
    }
    else
    {
      this.filter_2 = event.value;
    }

    if (this.filter_1 < this.filter_2)
    {
      this.inputtedMinPrice = this.filter_1;
      this.inputtedMaxPrice = this.filter_2;
    }
    else
    {
      this.inputtedMinPrice = this.filter_2;
      this.inputtedMaxPrice = this.filter_1;
    }
  }




  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }



}
