<br />

<div class="logInBox">
  <h2 class="login-title" style="margin: 16px 10px; text-align: center">
    Login or Register
  </h2>

  <div class="mobileInput" *ngIf="mobileInput">
    <form [formGroup]="loginForm">
      <mat-form-field
        class="form-field"
        id="mobileInputBox"
        appearance="outline"
      >
        <mat-label> Mobile</mat-label>
        <input matInput formControlName="mobile" />
        <mat-error>Input Valid Mobile Number</mat-error>
      </mat-form-field>
    </form>

    <button
      class="py-1"
      style="margin-bottom: 20px"
      mat-raised-button
      color="primary"
      (click)="isRegisteredUser()"
    >
      <span style="margin-right: 0; font-size: 15px">Next</span>
      <span class="material-icons" style="margin-left: 0; margin-bottom: 2px">
        keyboard_arrow_right
      </span>
    </button>
  </div>

  <div style="margin-top: 1rem" *ngIf="!userAuthentication">
    <p style="color: gray; font-size: 1.2rem">
      Don't have an account? Please
      <!-- <p><a [routerLink]="['/register']">Register</a></p> -->
      <a
        style="text-decoration: none; color: #1b00ff"
        [routerLink]="['/register']"
      >
        Register</a
      >
    </p>
  </div>

  <div class="passwordInput" *ngIf="passwordInput">
    <form [formGroup]="loginForm">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label> Password</mat-label>
        <input
          class="input-field"
          matInput
          formControlName="password"
          [type]="passwordHide ? 'password' : 'text'"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="passwordHide = !passwordHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="passwordHide"
        >
          <mat-icon>{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
        <mat-error
          >Password must have at least one number and one uppercase and
          lowercase letter, and at least 8 characters</mat-error
        >
      </mat-form-field>
    </form>

    <div class="forgot-pass-container d-flex justify-content-center">
      <a>
        <p
          style="text-align: end; color: #1b00ff"
          (click)="getEmailForForgattingPassword()"
        >
          <u style="text-decoration: none; cursor: pointer"
            >Forgot your password?</u
          >
        </p>
      </a>
    </div>

    <!-- <p *ngIf="!isPasswordCorrect" style="color: red">Password is Incorrect</p> -->
    <p *ngIf="showPasswordValidationMessage" style="color: red">{{passwordValidationMessage}}</p>

    <button
      class="back-btn"
      mat-raised-button
      color="#ffffff"
      (click)="backButtonPressed()"
    >
      <span class="material-icons" style="margin-bottom: 2px; margin-right: 0">
        keyboard_arrow_left
      </span>
      <span style="margin-right: 5px">Back</span>
    </button>

    <!-- <button *ngIf="isPassBox" mat-raised-button color="primary" type="submit" >Log In</button> -->
    <button
      class="login-btn"
      (click)="passwordAuthentication()"
      mat-raised-button
      color="primary"
    >
      Log In
    </button>
    <br />
  </div>

  <div class="forgetEmailInput" *ngIf="forgetEmailInput">
    <p>Enter a valid email to get OTP</p>

    <form [formGroup]="loginForm">
      <mat-form-field
        class="form-field"
        id="forgetEmailInput"
        appearance="outline"
      >
        <mat-label>E-mail</mat-label>
        <input matInput formControlName="emailForForgattingPassword" />
        <mat-error>Input Valid Email Address</mat-error>
      </mat-form-field>
    </form>

    <p *ngIf="wrongEmail" style="color: red; font-size: smaller">
      {{passwordValidationMessage}}
    </p>

    <button
      mat-raised-button
      color="#ffffff"
      (click)="backButtonPressed()"
      style="margin-right: 10rem"
    >
      <span class="material-icons" style="margin-bottom: 2px">
        keyboard_arrow_left
      </span>
      Back
    </button>

    

    <button mat-raised-button color="primary" (click)="GetOTP()">
      Send OTP
    </button>
  </div>

  <div class="forgetOtpInput" *ngIf="forgetOtpInput">
    <form [formGroup]="loginForm">
      <mat-form-field
        class="form-field"
        id="forgetOtpInput"
        appearance="outline"
      >
        <mat-label>OTP</mat-label>
        <input matInput formControlName="forgetOtpInput" />
        <mat-error>Input Valid OTP</mat-error>
      </mat-form-field>
    </form>

    <p>{{ otpMsg }}</p>

    <button
      mat-raised-button
      color="#ffffff"
      (click)="backButtonPressed()"
      style="margin-right: 10rem"
    >
      < Back
    </button>

    <button mat-raised-button color="primary" (click)="setNewPassword()">
      Next
    </button>
  </div>

  <div class="newPasswordInput" *ngIf="newPasswordInput">
    <form [formGroup]="loginForm">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>New Password</mat-label>
        <input
          matInput
          formControlName="newPassword"
          [type]="passwordHide2 ? 'password' : 'text'"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="passwordHide2 = !passwordHide2"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="passwordHide2"
        >
          <mat-icon>{{
            passwordHide2 ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
        <mat-error
          >Password must have at least one number and one uppercase and
          lowercase letter, and at least 8 characters</mat-error
        >
      </mat-form-field>

      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Confirm New Password</mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          [type]="passwordHide ? 'password' : 'text'"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="passwordHide = !passwordHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="passwordHide"
        >
          <mat-icon>{{
            passwordHide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
        <mat-error
          >Password must have at least one number and one uppercase and
          lowercase letter, and at least 8 characters</mat-error
        >
      </mat-form-field>
    </form>

    <!-- <p *ngIf="notSamePassword" style="color: red">
      Please confirm same password.....
    </p> -->
    <p>{{ updatePassMsg }}</p>

    <button
      mat-raised-button
      color="#ffffff"
      (click)="backButtonPressed()"
      style="margin-right: 10rem"
    >
      < Back
    </button>

    <button mat-raised-button color="primary" (click)="letHimLogIn()">
      Next
    </button>
  </div>
</div>

<br />
