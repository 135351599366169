import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.css']
})
export class CategoryCardComponent implements OnInit {

  @Input() categoryCard: any;
  imgURL: any;
  @Input() index: any;

  constructor() { }

  ngOnInit(): void {
    this.imgURL = environment.backEndBaseMediaUrl;
  }

}
