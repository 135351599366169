<div class="error-container">
    <div class="row w-100">
        <div class="col-12">
            <div class="content-container">
                <div class="error-inner-content">
                    <h2 style="font-size: 22px;">The page you requested cannot be found! </h2>
                </div>
                <div class="contiune-container">
                    <button class="continue-btn">Continue</button>
                </div>
            </div>
            
        </div>
    </div>
</div>