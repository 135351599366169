import { HomeGroupService } from './core/services/home-group.service';
import { ScrollTopService } from './core/services/scroll-top.service';
import { ApirequestService } from 'src/app/core/services/apirequest.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { Component, OnInit } from '@angular/core';
import { hammerjs } from 'node_modules/hammerjs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'xpos-ecommerce';
  hammerjs=hammerjs;
  itemNumberInCart: number;
  isLoggedIn: boolean;

  onActivate(event) {
    document.querySelector('body').scrollTo(0, 0);
  }
  constructor(
    private dataService: DataStoreService, 
    private apiService: ApirequestService,
    private homeGroup: HomeGroupService,
    private authenticationService: AuthenticationService,
    private scrollTopService: ScrollTopService,
    ){
      this.scrollTopService.scrollTop();
    }
  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.dataService.userTokenUpdated(localStorage.getItem('token'))
      this.apiService.fetchIndivudualPersonData().subscribe((post: any) => {
        this.dataService.myAccountClicked(post);
      });
    }
    // if (localStorage.getItem('token')) {
    //   this.authenticationService
    //     .loginWithToken(localStorage.getItem('token'))
    //     .subscribe((posts) => {
    //       if (posts) {
    //         this.authenticationService.logInStatus.next(true);
    //         this.authenticationService.isAuthenticated = true;
    //       }
    //     });
    // }
    // if (!this.dataService.homeGroupItems) {
    //   this.homeGroup.getGroupData().subscribe((groupdata) => {
    //     this.dataService.homeGroupItems = groupdata;
    //     console.log('app',groupdata);
        
    //   });
    // }

    this.dataService.numberOfItemInCart.next(this.dataService.getCart().length);
    this.authenticationService.logInStatus.next(
      this.authenticationService.isAuthenticatedGuard()
    );

    this.dataService.numberOfItemInCart.subscribe((value) => {
      this.itemNumberInCart = value;
    });

    this.authenticationService.logInStatus.subscribe((value) => {
      this.isLoggedIn = value;
    });
  }

  addNumberInCart() {
    this.itemNumberInCart = this.dataService.getCart().length;
  }
}
