import { retry, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiPaths } from './../../enums/api-paths.enum';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  schema = 'nplkxcf79';

  domain = 'http://172.16.9.147:4200/';

  baseUrl = environment.backEndBaseUrl;

  constructor(private http: HttpClient) {}

  getConfiguration(queryParam = '') {
    let url = `${this.baseUrl}${ApiPaths.CONFIGURATION}`;
    if (queryParam !== '') {
      url = `${url}?payment_methods=${true}`;
    }
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map(
        (configurationRes: {
          mobile_verification: boolean;
          email_verification: boolean;
          verification: boolean;
          ssl_payment: boolean;
          bkash_payment: boolean;
          cash_on_delivery: boolean;
        }) => {
          return configurationRes;
        }
      )
    );
  }
}
