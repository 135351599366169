import { ActivatedRoute, NavigationEnd, Router, Params } from '@angular/router';
import { HomeGroupService } from './../../core/services/home-group.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-group-products',
  templateUrl: './home-group-products.component.html',
  styleUrls: ['./home-group-products.component.css']
})
export class HomeGroupProductsComponent implements OnInit {

  searchName: any;
  paramsubs: any;
  items: any;
  filteredItems: any;
  filteredItemsId = new Set();
  filteredAtt = new Set();
  min = 100000000;
  max = 0;
  slider1: any;
  slider2: any;
  dataSource: any;
  displayedColumns: any;
  groupSlug: string;
  serverResponse = false;
  panelOpenState = false;
  error: any;
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  sorting: any;
  filteredData: any;
  brands: any = [];
  brandChecked: boolean = false;
  lowHigh: boolean = false;
  headerText:any;

  constructor(
    private homeGroupApiService: HomeGroupService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.searchName = {
      name: this.route.snapshot.params.name,
    };

    this.paramsubs = this.route.params.subscribe((params: Params) => {
      this.groupSlug = this.route.snapshot.params.groupId;
      this.items = [];
      this.serverResponse = false;
      this.searchName.name = params.name;
      this.homeGroupApiService.getHomeGroupProducts(this.groupSlug).subscribe(
        (post: any) => {
          if (!post) {
            this.serverResponse = false;
          } else if (post === 500) {
            this.router.navigate(['internal-server-error']);
          } else {
            this.serverResponse = true;
            this.items = post;
            this.filteredItems = post;
            this.headerText = post.header_text;
            

            this.min = 10000000;
            this.max = 0;
            for (const i in this.items) {
              if (+this.filteredItems[i].sale_price <= this.min) {
                this.min = +this.filteredItems[i].sale_price;
              }
              if (+this.filteredItems[i].sale_price >= this.max) {
                this.max = +this.filteredItems[i].sale_price;
              }
            }
            this.slider1 = this.min;
            this.slider2 = this.max;
            this.dataSource = [{ from: this.min, to: this.max }];
            this.displayedColumns = ['range', 'from', 'to'];
          }
        },
        (error: any) => {
          this.serverResponse = true;
          this.error = error;
        }
      );
    });
  }

}
