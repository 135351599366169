import { TickerComponent } from './feature/ticker/ticker.component';
import { MatIconModule } from '@angular/material/icon';
import { HeadersInterceptor } from './core/interceptors/headers.interceptor';
import { RouterModule } from '@angular/router';
import { CoreModule } from './core/core.module';
import { FeatureModule } from './feature/feature.module';
import { BlockModule } from './block/block.module';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    BlockModule,
    FeatureModule,
    CoreModule,
    RouterModule,
    BrowserAnimationsModule,
    MatIconModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, TickerComponent],
})
export class AppModule {}
