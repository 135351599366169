<div class="itemsBody" *ngFor="let homeGroupItem of homeGroupData">
  <div class="groupView">
    <div class="home-group-title-container">
      <h4 class="home-group-title">
        {{ homeGroupItem.group_name }}
      </h4>
      <div class="label"></div>
      <a class="text-decoration-none" [routerLink]="['home-group', homeGroupItem.slug]">
        <span class="view-all-text">View all</span>
      </a>
    </div>
    <div class="d-none d-xl-block">
      <div class="product-container mt-3">
        <span *ngFor="let itm of homeGroupItem.items.slice(0, 7)">
          <app-home-group-card [itemData]="itm"></app-home-group-card>
        </span>
      </div>
    </div>
    <div class="d-none d-xl-none d-md-block">
      <div class="product-container mt-3">
        <span *ngFor="let itm of homeGroupItem.items.slice(0, 5)">
          <app-home-group-card [itemData]="itm"></app-home-group-card>
        </span>
      </div>
    </div>
    <div class="d-block d-md-none">
      <div class="product-container mt-3">
        <span *ngFor="let itm of homeGroupItem.items.slice(0, 4)">
          <app-home-group-card [itemData]="itm"></app-home-group-card>
        </span>
      </div>
    </div>
  </div>
</div>