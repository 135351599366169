<a
  [routerLink]="['/campaign', itemData.slug]"
  class="my-card"
>
  <img
    loading="lazy"
    src="{{ imgURL }}{{ itemData.image_url }}"
    class="card-img-top my-card-img-top"
    alt="{{itemData.name}}"
    style="height: 100%;"
    loading="lazy"
  />
</a>
