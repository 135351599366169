import { ApiPaths } from './../../enums/api-paths.enum';
import { environment } from './../../../environments/environment';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { throwError } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isLoggedIn = false;
  isAuthenticated = false;
  userAuthenticated = false;
  passwordAuthenticated = false;
  url: string;
  baseUrl = environment.backEndBaseUrl;

  public logInStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    private dataService: DataStoreService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  isAuthenticatedGuard(): boolean {
    if (localStorage.getItem('token') === null) {
      return false;
    } else {
      return true;
    }
  }

  // service: create and account
  Register(form: FormGroup) {
    let url = `${this.baseUrl}${ApiPaths.REGISTER}`;
    return this.http.post(url, form.value).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((registrationRes: { success: boolean }) => {
        return registrationRes;
      })
    );
  }

  updateUserProfile(form: FormGroup) {
    let url = `${this.baseUrl}${ApiPaths.UPDATE_PROFILE}`;
    return this.http.post(url, form.value).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((profileUpdateRes: { success: boolean; message: string }) => {
        if (profileUpdateRes.success) {
          this.dataService.user = null;
          // return true;
          return profileUpdateRes;
        }

        // else {
        //   return false;
        // }
      })
    );
  }

  // SERVICE: check user exist or not
  login(contactNo: string) {
    let url = `${this.baseUrl}${ApiPaths.USER_EXIST}`;
    return this.http
      .post(url, {
        phone: contactNo,
      })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((customerExistRes: { account_exists: boolean; token: string }) => {
          return customerExistRes;
        })
      );
  }

  // loginWithToken(token: any) {
  //   return this.http
  //     .post(environment.backEndBaseUrl + 'users/exist', {
  //       token: token,
  //     })
  //     .pipe(
  //       retry(1),
  //       catchError((err) => {
  //         return (err);
  //       }),
  //       map((post: any) => {
  //         if (post.customer_exists) {
  //           this.isAuthenticated = true;
  //           this.passwordAuthenticated = true;

  //           this.logInStatus.next(true);
  //           return true;
  //         } else {
  //           return false;
  //         }
  //       })
  //     );
  // }

  passwordAuthenticate(password: string) {
    let url = `${this.baseUrl}${ApiPaths.USER_LOGIN}`;
    let mobile = localStorage.getItem('mobile');
    return this.http
      .post(url, {
        password,
        mobile,
      })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((passAuthRes: { success: boolean; token: string }) => {
          if (passAuthRes.success) {
            this.isAuthenticated = true;
            this.passwordAuthenticated = true;
            this.dataService.userTokenUpdated(passAuthRes.token)
            this.logInStatus.next(true);
            return passAuthRes;
          }
        })
      );
  }
  // send OTP for reset password
  SendOTP(email: string, isMobile: boolean) {
    let url = `${this.baseUrl}${ApiPaths.EMAIL_VALIDATION}`;
    let mobile = localStorage.getItem('mobile');

    return this.http
      .post(url, {
        contact: email,
        is_mobile: isMobile,
        mobile: mobile,
      })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((sendOTPRes: { success: boolean; message: boolean }) => {
          return sendOTPRes;
        })
      );
  }

  CheckOTP(contact: string, otp: string, isMobile: boolean) {
    let url = `${this.baseUrl}${ApiPaths.MOBILE_VERIFICATION}`;
    return this.http
      .post(url, {
        contact: contact,
        otp: otp,
        is_mobile: isMobile,
      })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((checkOTPres: { success: boolean; message: string }) => {
          return checkOTPres;
        })
      );
  }

  UpdateNewPassword(newPassword: string) {
    const url = `${this.baseUrl}${ApiPaths.RESET_PASSWORD}`;
    const contact = localStorage.getItem('mobile');
    return this.http
      .post(url, {
        password: newPassword,
        contact,
      })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((updatePassRes: { success: boolean; message: string }) => {
          return updatePassRes;
        })
      );
  }

  logOut() {
    this.userAuthenticated = false;
    this.passwordAuthenticated = false;
    this.isAuthenticated = false;
    isPlatformBrowser(this.platformId) && localStorage.removeItem('token');
    // localStorage.removeItem('token')
    this.dataService.user = null;
    this.logInStatus.next(false);
  }

  mobileVerify(contactNo: string, isMobile: boolean) {
    let url = `${this.baseUrl}${ApiPaths.MOBILE_OTP_SEND}`;

    return this.http
      .post(url, { contact: contactNo, is_mobile: isMobile })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((otpRes: any) => {
          return otpRes;
        })
      );
  }

  onSubmitOtp(contactNo: string, otp: string, isMobile: boolean) {
    const url = `${this.baseUrl}${ApiPaths.MOBILE_VERIFICATION}`;
    return this.http
      .post(url, {
        contact: contactNo,
        otp: otp,
        is_mobile: isMobile,
      })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((post: any) => {
          return post;
        })
      );
  }

  onResendOtp(contactNo: string) {
    let url = `${this.baseUrl}${ApiPaths.MOBILE_OTP_RESEND}`;
    return this.http
      .post(url, {
        mobile: contactNo,
      })
      .pipe(
        map((post: any) => {
          return post;
        })
      );
  }
}
