<div class="wrapper-container my-2" [ngStyle]="{ backgroundColor: color.$warning}">
    <h1 class="title-container m-0">
      <strong class="product-brand">{{ headerName}}</strong>
    </h1>
    <div class="sorting-container">
      <h2 class="title-container m-0 pr-3">
        <strong class="number-of-product">{{ length }}</strong>
        Products shown
      </h2>
      <div
        style="cursor: pointer"
        (click)="sortingProductListClick()"
        #tooltip="matTooltip"
        matTooltip="Sort by price"
        [matTooltipPosition]="'below'"
        matTooltipHideDelay="1000"
      >
        <img style="height: 1rem" src="assets/icons/arrow.png" alt="Sort by price" loading="lazy" />
      </div>
    </div>
  </div>
  