import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cart-mobile-card',
  templateUrl: './cart-mobile-card.component.html',
  styleUrls: ['./cart-mobile-card.component.css']
})
export class CartMobileCardComponent implements OnInit {

  @Input() itemData: any;
  @Output() removeItem = new EventEmitter();
  @Output() addRmvQuantity = new EventEmitter();

  configURL_media = environment.backEndBaseMediaUrl;

  constructor() { }

  ngOnInit(): void {}

  removeItemHandler() {
    this.removeItem.emit();
  }

  quantityBtnHandler(value: any) {
    this.addRmvQuantity.emit(value);
  }

}
