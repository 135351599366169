import { ApiPaths } from './../../enums/api-paths.enum';
import { environment } from './../../../environments/environment';
import { CategoryDataService } from './storage/category-data.service';
import { HttpClient } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  cat_count = 0;
  categories: any = [];
  baseUrl = environment.backEndBaseUrl;

  private actionSource = new Subject<string>();
  actionSourceObservable = this.actionSource.asObservable();

  private actionSourceForItemDetails = new Subject<string>();
  actionSourceObservableForItemDetails = this.actionSourceForItemDetails.asObservable();


  constructor(
    private http: HttpClient,
    private categoryDataService: CategoryDataService,
  ) { }

  dfs(category: any, childrenofRoot: any[], dept: number): any {
    for (const i in childrenofRoot) {
      if (childrenofRoot[i].id === category.parent) {
        childrenofRoot[i].children.push({ ...category, depth: dept });
        this.cat_count += 1;
        return;
      } else if (childrenofRoot[i].children.length > 0) {
        this.dfs(category, childrenofRoot[i].children, dept + 1);
      }
    }
    return;
  }

  fetchCategoryData(): any {
    let url = `${this.baseUrl}${ApiPaths.CATAGORIES}`
    return this.http.get(url).pipe(
      map((postsCategory: any) => {
        this.categoryDataService.rawCategories = postsCategory;
        this.categoryDataService.emitRawCategory(postsCategory);

        for (const k in postsCategory) {
          if (postsCategory[k].level === 'root') {
            postsCategory[k].children = [];
            this.categories.push({ ...postsCategory[k], depth: 0 });
            this.cat_count += 1;
          }
        }
        for (const i in postsCategory) {
          if (postsCategory[i].level !== 'root') {
            postsCategory[i].children = [];

            if (postsCategory[i].root === postsCategory[i].parent) {
              // first level children
              for (const j in this.categories) {
                if (this.categories[j].id === postsCategory[i].root) {
                  this.categories[j].children.push({
                    ...postsCategory[i],
                    depth: 1,
                  });
                  this.cat_count += 1;
                }
              }
            } else {
              for (const k in this.categories) {
                if (this.categories[k].id === postsCategory[i].root) {
                  this.dfs(postsCategory[i], this.categories[k].children, 2);
                  break;
                }
              }
            }
          }
        }
        this.categoryDataService.sortObjectByName(this.categories)
        this.categoryDataService.categories = this.categories;
        this.categoryDataService.emitConfig(this.categories);
        this.categoryDataService.emitRawCategory(postsCategory);
        return this.categories;
      })
    );
  }

  getCategorizedFilteredData(id: any): any {
    let url = `${this.baseUrl}${ApiPaths.CATEGORY_ITEM}${id}`
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    );
  }

  getCategoryCards(): any {
    let url =  `${this.baseUrl}${ApiPaths.HOME_CATEGORY}`
    return this.http.get(url).pipe(
      map((posts) => {
        return posts;
      })
    );
  }

  buttonClicked(url:string) {
    this.actionSource.next(url)
  }

  itemClickHandler(url:string) {
    this.actionSourceForItemDetails.next(url)
  }
}
