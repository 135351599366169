<h2 class="about-us">Branches</h2>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-4">
      <mat-card class="example-card">
        <mat-card-content>
          <mat-card-title>IDB Branch</mat-card-title>
          <mat-card-subtitle>Netstar Private Limited</mat-card-subtitle>
        </mat-card-content>
        <mat-card-content>
          <p
            style="
              color: #666;
              font-size: 14px;
              font-family: 'Work Sans', Arial, sans-serif;
              line-height: 1.6;
              word-wrap: break-word;
            "
          >
            SR #312/4, BCS Computer City <br />
            IDB Bhaban Agargaon <br />
            Sher-e-Bangla Nogor Dhaka-1207 <br />
            Bangladesh <br />
            Email: info@netstar.com.bd <br />
            Tell: +88 01711-644148, +88 01678-093090 <br />
            +88 01678-093083, +88 01678-093084, <br />
            +88 01678-093085, +88 01678-093093, <br />
            +88 01678-093094
          </p>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-4">
      <mat-card class="example-card">
        <mat-card-content>
          <mat-card-title>ECS BRANCH (Multiplan Center)</mat-card-title>
          <mat-card-subtitle>Netstar Private Limited</mat-card-subtitle>
        </mat-card-content>
        <mat-card-content>
          <p
            style="
              color: #666;
              font-size: 14px;
              font-family: 'Work Sans', Arial, sans-serif;
              line-height: 1.6;
              word-wrap: break-word;
            "
          >
            SR #741-742, ECS Computer City, <br />
            Level # 7, Multiplan Centre, <br />
            New Elephant Road,Dhaka-1205,Bangladesh <br />
            Email: info@netstar.com.bd <br />
            Tell: +88 01678-093082, +88 01678-093092
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
