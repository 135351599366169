<div class="desktop-view col-12 mb-2 px-0 mb-3">
  <div class="row">
    <div class="col-md-8">
      <div class="shop-delivery-container">
        <!-- <h2 class="shop-delivery">Status: {{ itemsByStore.status }}</h2> -->
      </div>
    </div>
    <div class="col-md-6"></div>
  </div>
  <div>
    <table mat-table [dataSource]="subOrder" class="mat-elevation-z1">
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Product Name</th>
        <td style="max-width: 250px" mat-cell *matCellDef="let element">
          {{ element.item_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Unit Price">
        <th mat-header-cell *matHeaderCellDef>Unit Price</th>
        <td mat-cell *matCellDef="let element">
          <span>&#2547;{{ element.unit_price }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.quantity_in_unit }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Total Price">
        <th style="text-align: right" mat-header-cell *matHeaderCellDef>
          Total Price
        </th>
        <td mat-cell style="text-align: right" *matCellDef="let element">
          <span style="font-weight: bold">
            &#2547;{{ element.item_total_price }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
  </div>
</div>
