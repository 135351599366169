<div *ngIf="compareItemWithDescription.length != 0; else emptyCart" class="container py-5 sm-container">
  <div class="row">
    <div class="col-4 bg-white compare-item-card" *ngFor="let item of compareItemWithDescription">
      <img src="{{ configURL_media }}{{ item.thumbnail_url }}" alt="{{ item.name }}" loading="lazy"
        class="compare-item-img" />
      <a style="text-decoration: none; color: #292929" [routerLink]="['/product-details', item.slug]">
        <p style="margin-top: 5px; padding-right: 1rem; text-align: center;">
          {{ item.name }}
        </p>
      </a>
      <div class="row justify-content-around">
        <div class="d-flex justify-content-center mt-1">
          <div class="price" *ngIf="item?.offer_price === 0">
            <span class="offer-price">৳{{ item?.sale_price | number }}</span>
          </div>

          <div class="price" *ngIf="item?.offer_price !== 0">
            <span class="offer-price">৳{{ item?.offer_price | number }}</span>
            <span class="discount">৳{{ item?.sale_price | number }}</span>
          </div>
        </div>
        <p class="material-icons compare-item-delete" (click)="removeFromCompare(item)">
          delete outline icon
        </p>
      </div>
      <div class="compare-item-feature" [innerHTML]="item?.short_description | safeHtml"></div>
      <div class="long-table" [innerHTML]="item.long_description | safeHtml"></div>
    </div>
  </div>
</div>

<!-- Compare IS EMPTY -->
<ng-template #emptyCart>
  <div class="text-center pt-4">
    <!-- <img height="250px" width="300px" src="assets/images/yourCartIsEmpty.png" loading="lazy" /> -->
    <br />

    <button [routerLink]="''" style="
          border: 2px solid black;
          margin: 9px;
          padding: 9px;
          border-radius: 13px;
          cursor: pointer;
        ">
      Add Item for compare
    </button>

    <br />
  </div>
</ng-template>