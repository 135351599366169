import { ProductApiService } from './../../core/services/product-api.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-item-results',
  templateUrl: './item-results.component.html',
  styleUrls: ['./item-results.component.css'],
})
export class ItemResultsComponent implements OnInit {
  min = 10000000;
  max = 0;
  slider1;
  slider2;
  dataSource;
  displayedColumns;
  serverResponse = true;
  searchName: { name: string };
  itemDetails: any;
  maxPrice = 200000;
  filter_1 = 0;
  filter_2 = this.maxPrice;
  inputtedMinPrice = 0;
  inputtedMaxPrice = this.maxPrice;
  headerName: any;
  metaTitle: string = 'Search Result(s)';
  paramsubs: any;
  lowHigh: any;

  itemFiltered() {
    // return this.itemDetails.filter(x => x.offer_price==0 && x.base_price<this.inputtedMaxPrice && x.base_price>=this.inputtedMinPrice || x.offer_price>0 && x.offer_price<this.inputtedMaxPrice && x.offer_price>=this.inputtedMinPrice);
    return this.itemDetails.filter(
      (x: any) =>
        (x.offer_pricec == 0 &&
          x.base_price < this.max &&
          x.base_price >= this.min) ||
        (x.offer_price > 0 &&
          x.offer_price < this.max &&
          x.offer_price >= this.min)
    );
  }

  findMaxPrice() {
    let i = 1;

    let maxValue = 0;

    for (i = 0; i < this.itemDetails.length; i++) {
      if (this.itemDetails[i].base_price > maxValue) {
        maxValue = this.itemDetails[i].base_price;
      }
    }

    let maja = maxValue % 1000;

    if (maja === 0) {
      return maxValue + 1000;
    } else {
      return maxValue + 1000 - maja;
    }

    return maxValue + 1000;
  }

  constructor(
    private apiService: ProductApiService,
    private route: ActivatedRoute,
    private title: Title
  ) {}

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.setTitle(this.metaTitle);
    this.searchName = {
      name: this.route.snapshot.params.id,
    };

    this.paramsubs = this.route.params.subscribe((params: Params) => {
      this.searchName.name = params.name;
      this.itemDetails = undefined;
      this.apiService
        .fetchSearchedItemData(this.searchName.name)
        .subscribe((post: any) => {
          this.itemDetails = post.item_data;
          this.headerName = post.header_text;
          this.min = 10000000;
          this.max = 0;
          for (const i in this.itemDetails) {
            if (+this.itemDetails[i].sale_price <= this.min) {
              this.min = +this.itemDetails[i].sale_price;
            }
            if (+this.itemDetails[i].sale_price >= this.max) {
              this.max = +this.itemDetails[i].sale_price;
            }
          }
          this.slider1 = this.min;
          this.slider2 = this.max;
          this.dataSource = [{ from: this.min, to: this.max }];
          this.displayedColumns = ['range', 'from', 'to'];
        });
    });
  }

  onInputChange(event: any, filterNumber: number) {
    if (filterNumber === 1) {
      this.filter_1 = event.value;
    } else {
      this.filter_2 = event.value;
    }

    if (this.filter_1 < this.filter_2) {
      this.inputtedMinPrice = this.filter_1;
      this.inputtedMaxPrice = this.filter_2;
    } else {
      this.inputtedMinPrice = this.filter_2;
      this.inputtedMaxPrice = this.filter_1;
    }
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

  sortingProductList() {
    this.lowHigh = !this.lowHigh;

    if (this.lowHigh) {
      this.itemDetails = [...this.itemDetails].sort(
        (a: any, b: any) => a.sale_price - b.sale_price
      );
    } else {
      this.itemDetails = [...this.itemDetails].sort(
        (a: any, b: any) => b.sale_price - a.sale_price
      );
    }
  }
}
