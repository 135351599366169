import { demoItem } from 'src/app/core/model/category.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/core/services/apirequest.service';
import { Options } from "@angular-slider/ngx-slider";
import { MatTableDataSource } from '@angular/material/table';


const test: demoItem[] = [];

const ELEMENT_DATA = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-categoryproducts',
  templateUrl: './categoryproducts.component.html',
  styleUrls: ['./categoryproducts.component.css']
})
export class CategoryproductsComponent implements OnInit {
  searchName: {name: string};
  paramsubs: Subscription;
  items: demoItem[] = [];
  min = 100000000;
  max = 0;
  slider1;
  slider2;
  dataSource;
  displayedColumns;
  serverResponse = false;



  constructor(private apiService: ApirequestService, private route: ActivatedRoute) {  }

  ngOnInit(): void {
    this.searchName = {
      name: this.route.snapshot.params.name,
    };

    this.paramsubs = this.route.params
    .subscribe(
      (params: Params) => {
        // this.serverResponse = true;

        this.searchName.name = params.name;
        this.apiService.fetchCategorizedItemData(this.searchName.name)
        .subscribe(post => {
          if(!post){
            // this.serverResponse = false;
          }
          else{
            this.items = post.items;
            this.serverResponse = true;
            this.min = 10000000;
            this.max = 0;
            // tslint:disable-next-line: forin
            for ( const i in this.items){
              if ( +this.items[i].base_price <= this.min ){
                this.min = +this.items[i].base_price;
              }
              if ( +this.items[i].base_price >= this.max ){
                this.max = +this.items[i].base_price;
              }
            }
            this.slider1 = this.min;
            this.slider2 = this.max;
            this.dataSource = [{from: this.min, to: this.max},

            ];
            this.displayedColumns = ['range', 'from', 'to'];
          }
          });
      }
    );
  }

}
