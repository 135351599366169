import { TickerService } from './../../core/services/ticker.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.css'],
})
export class TickerComponent implements OnInit {
  tickerMessages: any;

  constructor(private apiService: TickerService) {}

  ngOnInit(): void {
    this.apiService.fetchTickerData().subscribe((tickerMessage: any) => {
      if (tickerMessage.length > 0) {
        this.tickerMessages = tickerMessage;
        // this.dataService.ticker = tickerMessage;
      }
    });
  }
}
