export const environment = {
  production: false,

  schema: 'mmcpwn89',
  // backEndBaseUrl: 'http://192.168.30.124:8000/ecommerce/api/',
  // backEndBaseUrl: 'http://119.40.90.107:8080/ecommerce/api/',
  // backEndBaseUrl: 'http://36.255.69.139/ecommerce/api/',
  backEndBaseUrl: 'https://securepos.com.bd/ecommerce/api/',
  backEndBaseMediaUrl: '',
};
