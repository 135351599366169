<div class="home-group-title-container my-3" *ngIf="categoryCardsData !== 0">
  <h4 class="home-group-title">
    Shop by brands
    <a class="view-all-container" [routerLink]="['brands']">
      <span class="view-all-text">View all</span>
    </a>
  </h4>
</div>
<div class="mb-3 brand-container">
  <div *ngFor="let categoryCardItem of categoryCardsData">
    <app-brand-card [brand]="categoryCardItem"></app-brand-card>
  </div>
</div>