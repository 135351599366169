
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: ['./brand-card.component.css']
})
export class BrandCardComponent implements OnInit {
  @Input() brand: any;
  mediaURL: any;

  constructor() { }

  ngOnInit(): void {
    this.mediaURL = environment.backEndBaseMediaUrl;
  }

}
