import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.css']
})
export class OfferCardComponent implements OnInit {
  @Input() itemData: any; 
  imgURL: any;

  constructor() { }

  ngOnInit(): void {
    this.imgURL = environment.backEndBaseMediaUrl;
  }

}
