<div *ngIf="cartItemWithQuantity.length != 0; else emptyCart" class="container py-5 sm-container">
  <div class="d-flex align-items-center mb-3">
    <h1 class="cart-title-text mb-0">Shopping Cart</h1>
    <div class="label"></div>
  </div>
  <!-- Table view for Desktop -->
  <div class="desktop-view">
    <div class="row">
      <div class="col-12 ct-sm-col">
        <table mat-table [dataSource]="cartItemWithQuantity" class="mat-elevation-z8 table-bordered">
          <ng-container matColumnDef="Image">
            <th mat-header-cell *matHeaderCellDef>Image</th>
            <td class="ct-sm-image" mat-cell *matCellDef="let element">
              <img src="{{ configURL_media }}{{ element.item.thumbnail_url }}" alt="{{ element.item.name }}"
                height="85px" loading="lazy" style="display: flex; margin: 0 auto; border: 1px solid #d6d4d4" />
            </td>
            <td mat-footer-cell *matFooterCellDef class="d-none"></td>
          </ng-container>

          <ng-container matColumnDef="Name">
            <th class="c-unit-price sm-c-unit-price" mat-header-cell *matHeaderCellDef>
              Product Name
            </th>
            <td style="max-width: 250px" mat-cell *matCellDef="let element">
              <a style="text-decoration: none; color: #292929" [routerLink]="['/product-details', element.item.slug]">
                <p style="margin-top: 5px; padding-right: 1rem">
                  {{ element.item.name }}
                </p>
                <span *ngFor="let variant of element.item.variants">
                  <p *ngIf="variant !== null" style="margin-top: 5px; padding-right: 1rem">
                    <span>
                      <b>Variant name: </b> {{ variant.variation_name }}</span>
                  </p>
                </span>
              </a>
            </td>
            <td mat-footer-cell *matFooterCellDef class="d-none"></td>
          </ng-container>

          <ng-container matColumnDef="Quantity">
            <th style="text-align: center; width: 110px" mat-header-cell *matHeaderCellDef>
              Quantity
            </th>
            <td class="hide-qun-lg text-center" mat-cell *matCellDef="let element">
              <input class="item-qun my-1" disabled type="text" value="{{ element.quantity }}" />
              <div class="sm-qun">
                <button [disabled]="element.quantity <= 1 ? 'disabled' : null" mat-raised-button
                  class="add-rmv-btn mr-1" (click)="quantityUpDown('down', element)">
                  <span class="material-icons">remove</span>
                </button>
                <button mat-raised-button [disabled]="
                    element.item.quantity <= element.quantity
                      ? 'disabled'
                      : null
                  " class="add-rmv-btn" (click)="quantityUpDown('up', element)">
                  <span class="material-icons">add</span>
                </button>
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>

          <div style="display: none">
            <ng-container matColumnDef="Unit Price">
              <th class="c-unit-price sm-c-unit-price" mat-header-cell *matHeaderCellDef>
                Unit Price
              </th>
              <td class="c-unit-price" mat-cell *matCellDef="let element">
                <input *ngIf="element.item.offer_price === 0" disabled class="unit-price" type="text"
                  value="&#2547; {{ element.item.sale_price | number }}" />
                <input *ngIf="element.item.offer_price != 0" disabled class="unit-price" type="text" name="" id=""
                  value="&#2547; {{ element.item.offer_price | number }}" />
              </td>
              <td mat-footer-cell *matFooterCellDef colspan="2" class="text-right pr-3 text-uppercase font-weight-bold">
                Total
              </td>
            </ng-container>
          </div>

          <ng-container matColumnDef="Remove">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="p-0 text-center">
              <p style="margin-top: 0.8rem; cursor: pointer; width: 2rem" class="material-icons"
                (click)="removeFromCart(element)">
                delete outline icon
              </p>
            </td>
            <td mat-footer-cell *matFooterCellDef class="total-price pr-0">
              &#2547;{{ totalCalculation() | number }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
      </div>
      <div class="col-3">
        <!-- <div class="summary-container">
          <p class="summary-title">Order Summary</p>
          <div style="display: flex; justify-content: space-between">
            <p class="quantity-text">
              Subtotal ({{ cartItemWithQuantity?.length }} items)
            </p>
            <p class="quantity-value">
              &#2547; {{ totalCalculation() | number }}
            </p>
          </div>
          <div style="
              display: flex;
              justify-content: space-between;
              margin-top: 0.5rem;
            ">
            <p style="color: #202020" class="quantity-text">Total</p>
            <p style="color: salmon" class="quantity-value">
              &#2547; {{ totalCalculation() | number }}
            </p>
          </div>

          <div>
            <a [routerLink]="['../confirm-order']" mat-raised-button color="primary" class="shop-checkout"
              style="width: 100%">
              Proceed to checkout
            </a>
          </div>
        </div> -->
      </div>
    </div>

    <div class="row mt-3 justify-content-around">
      <button [routerLink]="''" mat-raised-button color="primary" class="shop-checkout">
        <span style="padding-right: 0.5rem" class="material-icons">
          arrow_back
        </span>
        Continue Shopping
      </button>
      <a [routerLink]="['../confirm-order']" mat-raised-button color="primary" class="shop-checkout">
        Proceed to checkout
      </a>
    </div>
  </div>

  <!-- Cart view for Mobile -->
  <div class="mobile-view">
    <div class="container" *ngFor="let cartItem of cartItemWithQuantity">
      <app-cart-mobile-card [itemData]="cartItem" (removeItem)="removeFromCart(cartItem)"
        (addRmvQuantity)="quantityUpDown($event, cartItem)">
      </app-cart-mobile-card>
    </div>
    <div class="col-12">
      <div class="summary-container">
        <p class="summary-title">Order Summary</p>
        <div style="display: flex; justify-content: space-between">
          <p class="quantity-text">
            Subtotal ({{ cartItemWithQuantity?.length }} items)
          </p>
          <p class="quantity-value">
            &#2547; {{ totalCalculation() | number }}
          </p>
        </div>
        <div style="
            display: flex;
            justify-content: space-between;
            margin-top: 0.5rem;
          ">
          <p style="color: #202020; margin-bottom: 0" class="quantity-text">
            Total
          </p>
          <p style="color: salmon; margin-bottom: 0" class="quantity-value">
            &#2547; {{ totalCalculation() | number }}
          </p>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6">
        <button [routerLink]="''" class="bottom-btns" mat-raised-button color="primary">
          <span style="padding-right: 0.2rem" class="material-icons">
            arrow_back
          </span>
          Continue Shopping
        </button>
      </div>
      <div class="col-6">
        <div style="justify-content: end; display: flex">
          <button class="bottom-btns" [routerLink]="['../confirm-order']" mat-raised-button color="primary">
            Proceed to Checkout
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CART IS EMPTY -->
<ng-template #emptyCart>
  <div class="text-center pt-4">
    <img height="250px" width="300px" src="assets/images/yourCartIsEmpty.png" loading="lazy" />
    <br />

    <button [routerLink]="''" style="
        border: 2px solid black;
        margin: 9px;
        padding: 9px;
        border-radius: 13px;
        cursor: pointer;
      ">
      Add some Item
    </button>

    <br />
  </div>
</ng-template>