import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoginCheckLazyGuard implements CanLoad {
  constructor(private authService: AuthenticationService, private router: Router){}
  canLoad(
    next: Route,
    state: UrlSegment[]): Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> | boolean | UrlTree
    {
      const url: string = next.path;
      return this.checkLoginStatus(url);  }

      checkLoginStatus(url: string): any{
        if (this.authService.isAuthenticatedGuard()){
          // this.router.navigate(['profile']);
          return true;
        }
        else{
          this.router.navigate(['login']);
          // return false;
        }
      }
  }
