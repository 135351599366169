import { Color } from 'src/app/shared/classes/color';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataStoreService } from './../../../core/services/data-store.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent implements OnInit {

  color = new Color();
  @Input() itemData: any;
  item_pic = environment.backEndBaseMediaUrl;
  cartItem: any;
  Math = Math;
  itemExceedsStock: any;
  discount: any;
  discountPercent: any;

  constructor(
    public matDialog: MatDialog,
    private dataStoreService: DataStoreService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.itemData = {
      ...this.itemData,
      sale_price: parseInt(this.itemData.sale_price),
    }; 
    this.discount = this.itemData.sale_price - this.itemData.offer_price
    this.discountPercent = Math.floor((this.discount/this.itemData.sale_price) * 100);
  }
  addToCart = (obj: any) => {
    if (this.dataStoreService.itemExceedsStock(obj)) {
      this.itemExceedsStock = true;
    } else {
      if (obj.quantity === 0) {
        // this.matSnackBar.open('Currently, this product is out of stock!', 'X', {
        //   duration: 2000,
        // });
      } else {
        this.itemExceedsStock = false;
        this.cartItem = {
          ...obj,
          item_id: this.itemData.id,
          name: this.itemData.name,
          thumbnail: this.itemData.thumbnail,
        };
        this.dataStoreService.addtoCart(this.cartItem);
        this.matSnackBar.open('Added To Cart', 'X', {
          duration: 2000,
        });
      }
    }
  };

}
