<div class="container-fluid">
  <div style="text-align: center; margin: 2rem" *ngIf="error">
    <div class="alert alert-danger spinner-style">
      <h1>Error occur in the server</h1>
      <p>{{ error.statusText }}</p>
    </div>
  </div>

  <div style="text-align: center; margin: 2rem" *ngIf="!serverResponse">
    <div class="spinner-style">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div *ngIf="serverResponse" class="row">
    <div class="col-lg-2">
      <div style="background: white; margin-top: 1rem">
        <div class="price-slider-container">
          <div *ngIf="screenSize > 500">
            <h3 class="filter-title">FILTER BY</h3>
            <div class="mobile-price-filter">
              <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="range">
                  <mat-cell *matCellDef="let element" class="range">
                    <ngx-slider [(value)]="slider1" [(highValue)]="slider2" [options]="{
                        floor: min,
                        ceil: max,
                        step: 1,
                        hideLimitLabels: true,
                        hidePointerLabels: true
                      }"></ngx-slider>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="from">
                  <mat-cell style="padding-right: 5px" *matCellDef="let element" class="range-control">
                    <input class="price-input-min" type="number" step="10" [(ngModel)]="slider1" disabled />
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="to">
                  <mat-cell *matCellDef="let element" class="range-control">
                    <input class="price-input-max" type="number" step="10" [(ngModel)]="slider2" disabled />
                  </mat-cell>
                </ng-container>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
            <div style="padding: 14px; padding-top: 0">
              <mat-accordion *ngFor="let filter_data_item of filter_data">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                  [expanded]="filter_data_item.is_expanded">
                  <mat-expansion-panel-header style="
                      border-top: 1px solid #e1e1e1;
                      border-bottom: 2px solid #0F4D8B;
                      border-radius: 0;
                    ">
                    <mat-panel-title>
                      {{ filter_data_item.attribute_name }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <section class="example-section">
                    <mat-checkbox style="margin-left: 0.5rem" *ngFor="let item of filter_data_item.attribute_values"
                      class="example-margin" [(ngModel)]="item.is_checked" (ngModelChange)="changeAttributeUrl(item)">{{
                      item.attr_value_name
                      }}{{ item.checked }}</mat-checkbox>
                  </section>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <!-- mobile device -->
          <div *ngIf="screenSize <= 500">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h3 class="filter-title">FILTER BY</h3>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="mobile-price-filter">
                  <table mat-table [dataSource]="dataSource" class="mobile-price">
                    <ng-container matColumnDef="range">
                      <mat-cell *matCellDef="let element" class="range">
                        <ngx-slider [(value)]="slider1" [(highValue)]="slider2" [options]="{
                            floor: min,
                            ceil: max,
                            step: 1,
                            hideLimitLabels: true,
                            hidePointerLabels: true
                          }"></ngx-slider>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="from">
                      <mat-cell style="padding-right: 5px" *matCellDef="let element" class="range-control">
                        <input class="price-input-min" type="number" step="10" [(ngModel)]="slider1" disabled />
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="to">
                      <mat-cell *matCellDef="let element" class="range-control" style="float: right">
                        <input class="price-input-max" type="number" step="10" [(ngModel)]="slider2" disabled />
                      </mat-cell>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                </div>
                <div style="padding: 0px">
                  <mat-accordion *ngFor="let filter_data_item of filter_data">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                      <mat-expansion-panel-header style="
                          border-top: 1px solid #e1e1e1;
                          border-bottom: 2px solid #0F4D8B;
                          border-radius: 0;
                        ">
                        <mat-panel-title>
                          {{ filter_data_item.attribute_name }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <section class="example-section">
                        <mat-checkbox style="margin-left: 0.5rem" *ngFor="let item of filter_data_item.attribute_values"
                          class="example-margin" [(ngModel)]="item.is_checked"
                          (ngModelChange)="changeAttributeUrl(item)">{{ item.attr_value_name }}</mat-checkbox>
                      </section>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="filteredItems" class="col-lg-10">
      <app-products-price-sort (priceSortClick)="sortingProductList()" [length]="filteredItems.length"
        [headerName]="headerText">
      </app-products-price-sort>

      <mat-chip-list>
        <span style="margin-bottom: 0.5rem">
          <mat-chip color="accent" selected style="border-radius: 10px; cursor: pointer"
            [ngStyle]="{ 'background-color': selectedBrand === 0 ? '#0F4D8B' : 'grey'}" [disabled]=""
            (click)="changeBrandUrl(0)">
            <input type="checkbox" />
            <label style="cursor: pointer; margin-bottom: 0" for="sample">
              All
            </label>
          </mat-chip>
        </span>
        <span style="margin-bottom: 0.5rem" *ngFor="let brand of brands; let i = index">
          <mat-chip color="accent" selected style="border-radius: 10px; cursor: pointer; padding: 0 20px;"
            [ngStyle]="{ 'background-color': brand.isChecked ? 'grey' : '#0F4D8B'}" (click)="changeBrandUrl(brand.id)">
            <input type="checkbox" />
            <label style="cursor: pointer; margin-bottom: 0" for="sample">{{
              brand.name
              }}</label>
          </mat-chip>
        </span>
      </mat-chip-list>

      <app-breadcrumb [categorySlug]="categorySlug"></app-breadcrumb>

      <div *ngIf="filteredItems.length > 0">
        <div class="product-container">
          <ng-container *ngFor="let itm of filteredItems">
            <app-product-card pp-product-card *ngIf="
                (itm.sale_price >= slider1 || itm.sale_price === slider1) &&
                (itm.sale_price <= slider2 || itm.sale_price === slider2)
              " [itemData]="itm">
            </app-product-card>
          </ng-container>
        </div>
      </div>
      <div *ngIf="description?.length !== 0" class="description" [innerHTML]="description | safeHtml"></div>
      <ng-container *ngIf="serverResponse && filteredItems && filteredItems.length === 0">
        <app-not-found [msg]="'Product not found'"></app-not-found>
      </ng-container>
    </div>
  </div>
</div>