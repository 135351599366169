import { EcomCategory } from './../../../../core/model/category.model';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-recursivemenu',
  templateUrl: './recursivemenu.component.html',
  styleUrls: ['./recursivemenu.component.css']
})
export class RecursivemenuComponent implements OnInit {
  @Input() items: EcomCategory[];
  @Input() name: string;
  @ViewChild('childMenu', {static: true}) public childMenu: any;
  constructor() { }

  ngOnInit(): void {
  }

}
