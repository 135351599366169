import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';


//to stop logged in users to view login and register page

@Injectable({
  providedIn: 'root'
})
export class LoginCheckGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean | UrlTree {
      const url: string = state.url;
      return this.checkLoginStatus(url);  }

      checkLoginStatus(url: string): true | UrlTree{
        if (this.authService.isAuthenticatedGuard()){
          this.router.navigate(['']);
        }
        else{
          this.authService.url = localStorage.getItem('url');
          return true;
        }
      }
}
