import { isPlatformBrowser } from '@angular/common';
import { CategoryDataService } from './../../core/services/storage/category-data.service';
import { Meta, Title } from '@angular/platform-browser';
import { CategoryService } from './../../core/services/category.service';
import { AuthenticationService } from './../../core/services/authentication.service';
import { ProductApiService } from './../../core/services/product-api.service';
import { environment } from './../../../environments/environment';
import { DataStoreService } from 'src/app/core/services/data-store.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
  NgxGalleryImageSize,
} from 'ngx-gallery-9';

@Component({
  selector: 'app-itemdetails',
  templateUrl: './itemdetails.component.html',
  styleUrls: ['./itemdetails.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemdetailsComponent implements OnInit {
  item_pic = environment.backEndBaseMediaUrl;
  date = new Date();
  slides = [];
  productSlug: any;
  itemName: any;
  itemId: any;
  details: any;
  itemProviders: any;
  itemProvidersNotFound = true;
  paramsubs: any;
  geo: any;
  longitude: any;
  latitude: any;
  cartItem: any;
  categoryLine = [];
  galleryImagesTemp: any = [];
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];
  temp = {
    small: '',
    medium: '',
    big: '',
  };
  itemExceedsStock: any;
  isLoggedIn: any;
  showWriteReview = false;
  stock: any = [];
  quantity: any = 1;
  error: any;
  similarProducts: any = [];
  priceMode: string = '0';
  categorySlug: string;
  raw_category: any;
  raws: any = [];
  images: any = [];
  slug: string;
  relatedImage: any = [];
  imagesForVariation: { id: number; url: string }[] = [];
  currentImageColor: string = '';
  showIPDCPrice: boolean = false;
  hideSalePrice: boolean = true;
  ipdcPrice: number;
  largerPrice: number;
  colorVariationPrice: number;
  selectedColorVariantId: number;
  compareItem: any;
  longDescription:string;
  descriptionTableIndex: number;
  descriptionTable: string;

  constructor(
    private productsApiService: ProductApiService,
    private route: ActivatedRoute,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private dataStoreService: DataStoreService,
    private authenticationService: AuthenticationService,
    private categoryApi: CategoryService,
    private meta: Meta,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId: object,
    private categoryDataService: CategoryDataService
  ) {
    this.ipdcPrice = 0;
    this.largerPrice = 0;
    this.colorVariationPrice = 0;
    this.selectedColorVariantId = 0;

    route.params.subscribe((params) => {
      this.slug = params['productSlug'];
    });
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  preparedPageData = (slug: string) => {
    this.stock = [];
    // this.categoryId = this.details;
    this.authenticationService.logInStatus.subscribe((value) => {
      this.isLoggedIn = value;
    });
    this.galleryOptions = [
      {
        width: '500px',
        height: '500px',
        imageSize: NgxGalleryImageSize.Contain,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewZoom: true,
        previewRotate: true,
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '400px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        imageSize: NgxGalleryImageSize.Contain,
      },
      {
        breakpoint: 400,
        preview: false,
        imageSize: NgxGalleryImageSize.Contain,
      },
    ];

    // take param from related product component
    this.productsApiService.fetchProductDetails(slug).subscribe(
      (post: any) => {
        this.categorySlug = post.category_url;
        this.galleryImages = [];
        this.ipdcPrice =
          post.offer_price === 0 ? post.sale_price : post.offer_price;
        this.largerPrice = this.ipdcPrice;
        this.imagesForVariation = post.images;
        this.raws = [];
        this.details = post;
        this.raw_category = this.categoryDataService.rawCategories;
        // this.generate(post.category);
        this.meta.addTags(this.details.item_meta_tag);
        let itemNameAsTitle =
          this.details.name.length > 65
            ? this.details.name.slice(0, 60)
            : this.details.name;
        let validTitle =
          this.details.title === '' ? itemNameAsTitle : this.details.title;
        this.setTitle(validTitle);
        for (let i = 1; i <= post.quantity; i++) {
          if (i > 10) {
            break;
          }
          this.stock.push(i);
        }

        this.cartItem = {
          item_id: post.id,
          name: post.display_name,
          base_price: post.base_price,
          offer_price: post.offer_price,
          thumbnail_url: post.thumbnail_url,
          max_sale_quantity: post.max_sale_quantity,
          quantity: post.quantity,
          categoryId: post.category,
          category_url: post.category_url,
        };
        this.cartItem?.category_url &&
          this.categoryApi
            .getCategorizedFilteredData(this.cartItem.category_url)
            .subscribe(
              (post: any) => {
                this.similarProducts = post.item_data.filter(
                  (id: any) => id.id !== this.cartItem.item_id
                );
              },
              (error: any) => {
                this.error = error;
              }
            );

        this.details = post;
        this.compareDays();

        this.images = this.details.images;
        // push thumbnail into images arr
        let tempImages = [{ id: 1, url: post.thumbnail_url }, ...post.images];
        tempImages.map((fimg: { id: number; url: string }) => {
          let temp = {
            // small: `${this.item_pic}${fimg.url}`,
            // medium: `${this.item_pic}${fimg.url}`,
            // big: `${this.item_pic}${fimg.url}`,
            small: `${fimg.url}`,
            medium: `${fimg.url}`,
            big: `${fimg.url}`,
          };
          this.galleryImages.push(temp);
        });
      },
      (errorRes) => {
        const { success: isSuccess, message } = errorRes.error;
        if (!isSuccess) {
          this.router.navigate(['']);
          this.matSnackBar.open(message, 'X', {
            duration: 2000,
          });
        }
      }
    );
  };

  ngOnInit(): void {
    // take param from url
    this.preparedPageData(this.route.snapshot.params.productSlug);

    let that = this;
    this.categoryApi.actionSourceObservableForItemDetails.subscribe({
      next(url) {
        that.preparedPageData(url);
      },
    });
  }

  changeView($event: any) {
    this.preparedPageData($event.url);
  }

  compareDays() {
    let startDate = new Date(this.details.offer_start_date);
    let endDate = new Date(this.details.offer_end_date);

    if (
      startDate.getTime() / 1000 < this.date.getTime() / 1000 &&
      this.date.getTime() / 1000 > endDate.getTime() / 1000
    ) {
      this.details.offer_price = 0;
      this.cartItem.offer_price = 0;
      return 'Offer Closed';
    } else {
      return 'Offer On';
    }
  }

  addToCart = () => {
    if (this.dataStoreService.itemExceedsStock(this.details)) {
      this.itemExceedsStock = true;
    } else {
      this.itemExceedsStock = false;
      this.cartItem = {
        item_id: this.details.id,
        name: this.details.name,
        thumbnail_url: this.details.thumbnail_url,
        variants: [],
        id: this.details.id,
        max_order_quantity: this.details.max_order_quantity,
        quantity: this.details.quantity,
        sale_price: this.largerPrice,
        offer_price: 0,
        slug: this.details.slug,

      };
      this.dataStoreService.addtoCart(this.cartItem);
    }
  };

  addToCompareHandler() {
    this.longDescription = this.details.long_description;;
    this.descriptionTableIndex = this.longDescription.search('<table');
    this.descriptionTable = this.longDescription.slice(this.descriptionTableIndex, );

    this.compareItem = {
      item_id: this.details.id,
      name: this.details.short_name,
      thumbnail_url: this.details.thumbnail_url,
      id: this.details.id,
      category: this.details.category,
      sale_price: this.details.sale_price,
      offer_price: this.details.offer_price,
      slug: this.details.slug,
      short_description: this.details.short_description,
      long_description: this.descriptionTable,
    };
    
    this.dataStoreService.addToCompare(this.compareItem);
    // this.matSnackBar.open('Added To Compare', 'X', {
    //   duration: 2000,
    // });
  }

  handleWriteReviewClick() {
    this.showWriteReview = !this.showWriteReview;
  }

  handleByNowClick() {
    isPlatformBrowser(this.platformId) && localStorage.removeItem('cartItems');
    if (this.priceMode === '0' && this.selectedColorVariantId === 0) {
      for (let i = 0; i < this.quantity; i++) {
        this.addToCart();
      }
    } else {
      let selectedVariantId = this.priceMode.split('#')[0];
      if (this.dataStoreService.itemExceedsStock(this.details)) {
        this.itemExceedsStock = true;
      } else {
        let variantsData: any = [];
        if (this.details.price_variant.length !== 0) {
          this.details.price_variant.map((pv: any) => {
            if (pv.type === 'price') {
              // ipdc
              let selectedIpdcVariant = pv.value.find(
                (pvValue: any) => pvValue.id === +selectedVariantId
              );
              variantsData.push(selectedIpdcVariant);
              // const variantsData = [selectedIpdcVariant];
            } else if (pv.type === 'property') {
              // color
              let selectedColorVariant = pv.value.find(
                (pvValue: any) => pvValue.id === this.selectedColorVariantId
              );
              variantsData.push(selectedColorVariant);
            }
          });
        }

        this.itemExceedsStock = false;
        this.cartItem = {
          item_id: this.details.id,
          name: this.details.name,
          thumbnail_url: this.details.thumbnail_url,
          variants: variantsData,
          sale_price: this.largerPrice,
          offer_price: 0,
          id: this.details.id,
          max_order_quantity: this.details.max_order_quantity,
          quantity: this.details.quantity,
          slug: this.details.slug,
        };
        this.dataStoreService.addtoCart(this.cartItem);
      }
    }
    this.router.navigate(['/confirm-order']);
  }

  addToCartHandler() {
    this.priceMode;
    if (this.priceMode === '0' && this.selectedColorVariantId === 0) {
      for (let i = 0; i < this.quantity; i++) {
        this.addToCart();
        this.matSnackBar.open('Added To Cart', 'X', {
          duration: 2000,
        });
      }
    } else {
      let selectedVariantId = this.priceMode.split('#')[0];
      if (this.dataStoreService.itemExceedsStock(this.details)) {
        this.itemExceedsStock = true;
      } else {
        let variantsData: any = [];
        if (this.details.price_variant.length !== 0) {
          this.details.price_variant.map((pv: any) => {
            if (pv.type === 'price') {
              // ipdc
              let selectedIpdcVariant = pv.value.find(
                (pvValue: any) => pvValue.id === +selectedVariantId
              );
              variantsData.push(selectedIpdcVariant);
              // const variantsData = [selectedIpdcVariant];
            } else if (pv.type === 'property') {
              // color
              let selectedColorVariant = pv.value.find(
                (pvValue: any) => pvValue.id === this.selectedColorVariantId
              );
              variantsData.push(selectedColorVariant);
            }
          });
        }

        this.itemExceedsStock = false;
        this.cartItem = {
          item_id: this.details.id,
          name: this.details.name,
          thumbnail_url: this.details.thumbnail_url,
          variants: variantsData,
          sale_price: this.largerPrice,
          offer_price: 0,
          id: this.details.id,
          max_order_quantity: this.details.max_order_quantity,
          quantity: this.details.quantity,
          slug: this.details.slug,
        };
        this.dataStoreService.addtoCart(this.cartItem);
        this.matSnackBar.open('Added To Cart', 'X', {
          duration: 2000,
        });
      }
    }
  }

  handleReviewSubmit() {
    this.showWriteReview = false;
  }

  // IPDC PRICE
  priceOptionChange(event: any) {
    if (event.value === '0') {
      if (this.colorVariationPrice !== 0) {
        // has color variant price

        // set ipdc price
        this.ipdcPrice =
          this.details.offer_price === 0
            ? this.details.sale_price
            : this.details.offer_price;

        // set larger price
        this.largerPrice =
          this.ipdcPrice > this.colorVariationPrice
            ? this.ipdcPrice
            : this.colorVariationPrice;
      } else {
        // has not color variant price
        this.largerPrice =
          this.details.offer_price === 0
            ? this.details.sale_price
            : this.details.offer_price;
      }
    } else {
      const idPrice = event.value.split('#');
      const price = idPrice[1];
      this.ipdcPrice = price;
      this.showIPDCPrice = true;
      this.hideSalePrice = false;
      this.largerPrice =
        price > this.colorVariationPrice ? price : this.colorVariationPrice;
    }
  }

  filterImageHandler(
    id: number,
    relatedImagesId: any,
    variationName: string,
    variationPrice: number
  ) {
    this.galleryImages = [];
    if (
      id === 0 &&
      relatedImagesId[0] === -1 &&
      variationName === '-1' &&
      variationPrice === 0
    ) {
      // clear filter
      this.currentImageColor = '';
      this.priceMode = '0';
      this.selectedColorVariantId = 0;
      this.hideSalePrice = true;
      this.showIPDCPrice = false;
      this.colorVariationPrice = 0;
      // this.largerPrice =
      //   this.details.offer_price === 0
      //     ? this.details.sale_price
      //     : this.details.offer_price;

      this.imagesForVariation.map((fimg: { id: number; url: string }) => {
        let temp = {
          small: `${this.item_pic}${fimg.url}`,
          medium: `${this.item_pic}${fimg.url}`,
          big: `${this.item_pic}${fimg.url}`,
        };
        this.galleryImages.push(temp);
      });
    } else {
      // img found
      this.showIPDCPrice = true;
      this.hideSalePrice = false;
      this.selectedColorVariantId = id;
      this.colorVariationPrice = variationPrice;
      this.largerPrice =
        variationPrice > this.ipdcPrice ? variationPrice : this.ipdcPrice; // 32000 > 30500
      this.currentImageColor = variationName;
      if (relatedImagesId.length !== 0) {
        const filteredImage = this.imagesForVariation.filter((item: any) =>
          relatedImagesId.some(
            (relatedImageId: number) => relatedImageId === item.id
          )
        );
        filteredImage.map((fimg: { id: number; url: string }) => {
          let temp = {
            small: `${this.item_pic}${fimg.url}`,
            medium: `${this.item_pic}${fimg.url}`,
            big: `${this.item_pic}${fimg.url}`,
          };
          this.galleryImages.push(temp);
        });
      } else {
        // no image found
        this.currentImageColor = variationName;
        this.imagesForVariation.map((fimg: { id: number; url: string }) => {
          let temp = {
            small: `${this.item_pic}${fimg.url}`,
            medium: `${this.item_pic}${fimg.url}`,
            big: `${this.item_pic}${fimg.url}`,
          };
          this.galleryImages.push(temp);
        });
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    location.reload() 
  }
}
