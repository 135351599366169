<header class="bg-white">
  <div class="topBar d-none d-md-block">
    <div class="container-fluid">
      <div class="row">
        <div class="col-3 col-lg-2 d-flex justify-content-around">
          <a [routerLink]="['']">
            <img style="width: 85px; padding: 5px 0;" src="assets/images/zarif-footer.png" alt="Brand Logo" />
          </a>
          <a [routerLink]="['']">
            <img style="width: 60px; padding: 5px 0;" src="assets/images/mmc-footer.png" alt="Brand Logo" />
          </a>
        </div>
        <div class="col-6 col-lg-7 d-flex flex-column justify-content-center">
          <form class="searchbar" autocomplete="off" [ngStyle]="{ background: color.$purpleBackground }">
            <button class="navbar-product-button">
              Products
            </button>
            <input type="text" matInput placeholder="Search your desire product..." [formControl]="searchControl"
              (ngModelChange)="handleSearch()" [matAutocomplete]="auto" />
            {{ search }}

            <mat-autocomplete (optionSelected)="filterChange($event)" autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of searchedCategories" [value]="option.name">
                {{ option.name }}
              </mat-option>
              <mat-option *ngFor="let option of searchedProducts" [value]="option.name">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>

            <button type="submit" class="navbar-search-button" [ngStyle]="{ background: color.$purple }"
              (click)="navigateTo()">
              <span class="material-icons d-flex align-content-center justify-content-center">
                search
              </span>
            </button>
          </form>
        </div>
        <div class="col-3 col-lg-3 icons d-flex flex-column justify-content-center">
          <div class="card-profile-icon">
            <a #tooltip="matTooltip" matTooltip="Shopping Cart" [matTooltipPosition]="'below'" matTooltipHideDelay="500"
              style="text-decoration: none" [routerLink]="['cart']">
              <mat-icon height="25px" style="
                  font-size: 20px;
                  margin: 5px;
                  outline-style: none;" matBadge="{{ itemNumberInCart }}" matBadgeColor="warn">shopping_cart
              </mat-icon>
            </a>
            <a #tooltip="matTooltip" matTooltip="Compare" [matTooltipPosition]="'below'" matTooltipHideDelay="500"
              style="text-decoration: none;
              display: flex;align-items: baseline;" [routerLink]="['compare']">
              <mat-icon height="25px" style="
                  font-size: 20px;
                  margin: 5px;
                  outline-style: none;" matBadge="{{ itemNumberInCompare }}" matBadgeColor="warn">add_to_queue
              </mat-icon>
              <h6 class="m-0">Compare</h6>
            </a>
            <a #tooltip="matTooltip" matTooltip="Login/Registration" [matTooltipPosition]="'below'"
              matTooltipHideDelay="500" *ngIf="!isLoggedIn" [routerLink]="['login']" class="unsign-user">
              <span class="material-icons" alt="Login">
                person_add
              </span>
            </a>
            <a #tooltip="matTooltip" matTooltip="My profile" [matTooltipPosition]="'below'" matTooltipHideDelay="500"
              *ngIf="isLoggedIn" [routerLink]="['profile']" class="unsign-user" [ngStyle]="{ color: color.$purple }">
              <span class="material-icons" alt="Login">
                person
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>