import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-checkout-mobile-card',
  templateUrl: './checkout-mobile-card.component.html',
  styleUrls: ['./checkout-mobile-card.component.css'],
})
export class CheckoutMobileCardComponent implements OnInit {
  @Input() itemData: any;

  configURL_media = environment.backEndBaseUrl;
  constructor() {}

  ngOnInit(): void {}
}
