<div class="d-flex justify-content-between card-container">
  <div>
    <img src="{{ configURL_media }}{{ item?.thumbnail_url }}" alt="{{ item?.name }}" style="height: 86px;"
      loading="lazy" />
  </div>
  <div class="pl-2">
    <a (click)="handleTitleClick(item?.slug)" [routerLink]="['/product-details', item?.slug]" class="name">
      {{ item?.short_name }}
    </a>
    <div class="d-flex justify-content-between mt-1">
      <div class="price" *ngIf="item?.offer_price === 0">
        <span class="offer-price" >৳{{ item?.sale_price | number }}</span>
      </div>

      <div class="price" *ngIf="item?.offer_price !== 0">
        <span class="offer-price" >৳{{ item?.offer_price | number }}</span>
        <span class="discount">৳{{ item?.sale_price | number }}</span>
      </div>
    </div>
  </div>
</div>