<div class="star-rating-icon">
    <!-- First Star -->
    <span *ngIf="rating >= 1" class="material-icons star-icon"> star </span>
    <span *ngIf="rating >= 0.5 && rating < 1" class="material-icons star-icon">
      star_half
    </span>
    <span *ngIf="rating < 0.5" class="material-icons star-icon">
      star_outline
    </span>
  
    <!-- Second Star -->
    <span *ngIf="rating >= 2" class="material-icons star-icon"> star </span>
    <span *ngIf="rating >= 1.5 && rating < 2" class="material-icons star-icon">
      star_half
    </span>
    <span *ngIf="rating < 1.5" class="material-icons star-icon">
      star_outline
    </span>
  
    <!-- Third Star -->
    <span *ngIf="rating >= 3" class="material-icons star-icon"> star </span>
    <span *ngIf="rating >= 2.5 && rating < 3" class="material-icons star-icon">
      star_half
    </span>
    <span *ngIf="rating < 2.5" class="material-icons star-icon">
      star_outline
    </span>
  
    <!-- Fourth Star -->
    <span *ngIf="rating >= 4" class="material-icons star-icon"> star </span>
    <span *ngIf="rating >= 3.5 && rating < 4" class="material-icons star-icon">
      star_half
    </span>
    <span *ngIf="rating < 3.5" class="material-icons star-icon">
      star_outline
    </span>
  
    <!-- Fifth Star -->
    <span *ngIf="rating >= 5" class="material-icons star-icon"> star </span>
    <span *ngIf="rating >= 4.5 && rating < 5" class="material-icons star-icon">
      star_half
    </span>
    <span *ngIf="rating < 4.5" class="material-icons star-icon">
      star_outline
    </span>
  </div>
  