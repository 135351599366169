import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutAddressService {
  constructor(private http: HttpClient) {}

  getDivision(slug: string): any {
    return this.http.get(environment.backEndBaseUrl + `eshop-zone/?parent=${slug}`).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((brands) => {
        return brands;
      })
    );
  }
}
