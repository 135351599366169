import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, retry } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ApiPaths } from 'src/app/enums/api-paths.enum';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  baseUrl = environment.backEndBaseUrl;
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  getOrderHistoryDetails(name: string): any {
    let url = `${this.baseUrl}${ApiPaths.GET_ORDER_HISTORY_DETAILS}${name}`;
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    );
  }

  placeOrder(order: any) {
    let url = `${this.baseUrl}${ApiPaths.PLACE_ORDER}`;
    return this.http
      .post(url, {
        ...order,
      })
      .pipe(
        retry(1),
        catchError((err) => {
          return throwError(err);
        }),
        map((orderRes) => {
          return orderRes;
        })
      );
  }

  customOrder(form: any) {
    let url = `${this.baseUrl}${ApiPaths.ITEM_REQUEST}`;
    return this.http.post(url, form).pipe(
      catchError((error) => {
        return of(false);
      }),
      map((posts) => {
        return posts;
      })
    );
  }

  fetchOrderHistoryData(): any {
    let url = `${this.baseUrl}${ApiPaths.ORDER_LIST}`;

    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((orderList) => {
        return orderList;
      })
    );
  }

  fetchOrderHistoryDetailsData(name: string): any {
    return this.http
      .get(environment.backEndBaseUrl + 'eshop-order/details/' + name + '/')
      .pipe(
        catchError((error) => {
          return of(false);
        }),
        map((posts) => {
          return posts;
        })
      );
  }

  getCancellationReasons(): any {
    let url = `${this.baseUrl}${ApiPaths.GET_CANCELLATION_REASONS}`;
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((reasons) => {
        return reasons;
      })
    );
  }
  postCancelledOrders(id: any): any {
    let url = `${this.baseUrl}${ApiPaths.POST_CANCELLED_ORDER}`;
    return this.http.post(url, id).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((res) => {
        return res;
      })
    );
  }
  orderTracking(status: string): any {
    let url = `${this.baseUrl}${ApiPaths.ORDER_TACKING}?status=${status}`;
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((res) => {
        return res;
      })
    );
  }

  orderReview(review: any): any {
    let url = `${this.baseUrl}${ApiPaths.ORDER_REVIEW}`;
    return this.http.post(url, review).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((res) => {
        return res;
      })
    );
  }

  DigitalPaymentValidation(): any {
    const session =
      isPlatformBrowser(this.platformId) && localStorage.getItem('session');
    let url = `${this.baseUrl}${ApiPaths.DIGITAL_PAYMENT_VALIDATION}?sessionkey=${session}`;
    // let token =
    //   isPlatformBrowser(this.platformId) && localStorage.getItem('token');

    return this.http.get(url).pipe(
      catchError((error) => {
        return of(error);
      }),
      map((post) => {
        return post;
      })
    );
  }

  BkashPaymentValidation(
    method: string,
    status: string,
    identifier: string
  ): any {
    let url = `${this.baseUrl}${ApiPaths.BKASH_PAYMENT_VALIDATION}?method=${method}&status=${status}&identifier=${identifier}`;
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((res) => {
        return res;
      })
    );
  }
  BkashPaymentReinitiate(
    order_no: string,
    prepayment_amount: number = 100
  ): any {
    let url = `${this.baseUrl}${ApiPaths.BKASH_PAYMENT_REINITIATE}?order_no=${order_no}&prepayment_amount=${prepayment_amount}`;
    return this.http.get(url).pipe(
      catchError((error) => {
        return of(error);
      }),
      map((res) => {
        return res;
      })
    );
  }

  Digitalpaymentreinitiate(
    order: string,
    prepayment_amount: number = 100
  ): any {
    let url = `${this.baseUrl}${ApiPaths.DIGITAL_PAYMENT_REINITIATE}`;
    let token =
      isPlatformBrowser(this.platformId) && localStorage.getItem('token');
    return this.http
      .post(url, {
        order_no: order,
        token: token,
        prepayment_amount,
      })
      .pipe(
        catchError((error) => {
          return of(error.ok);
        }),
        map((post) => {
          return post;
        })
      );
  }

  // paymentReinitiate
  paymentReinitiate(
    order: string,
    paymentGateway: string,
    prepayment_amount: number = 100
  ): any {
    if (paymentGateway === 'digital-payment') {
      // digital ssl
      let url = `${this.baseUrl}${ApiPaths.DIGITAL_PAYMENT_REINITIATE}`;
      let token =
        isPlatformBrowser(this.platformId) && localStorage.getItem('token');
      return this.http
        .post(url, {
          order_no: order,
          // token: token,
          prepayment_amount,
        })
        .pipe(
          catchError((error) => {
            return of(error.ok);
          }),
          map((post) => {
            return post;
          })
        );
    } else if (paymentGateway === 'b-kash') {
      // bkash
      let url = `${this.baseUrl}${ApiPaths.BKASH_PAYMENT_REINITIATE}?order_no=${order}&prepayment_amount=${prepayment_amount}`;
      return this.http.get(url).pipe(
        catchError((error) => {
          return of(error);
        }),
        map((res) => {
          return res;
        })
      );
    }
  }
}
