<div class="container" style="padding-top: 4rem">
    <div class="row">
        <div class="col-md-6">
            <div>
                <img class="img-responsive" src="assets/svg-images/about.svg" alt="About us banner"
                    loading="lazy" />
            </div>
        </div>
        <div class="col-md-6">
            <h1 class="about-us-title">About Zarif Communications</h1>
            <p class="about-us-desc">
                <span>Zarif Communications</span> has been founded on 4 August 2010. From then to now, Zarif Communications has won
                the heart of many people and now is a country-wide renowned brand. That has been possible due to the
                hard work Zarif Communications has done to satisfy its customers. Having the aim to satisfy customers,
                providing customers with their required products, and being true to their motto, “Customers Come First,”
                has brought Zarif Communications to the top choice for E-Commerce Sites in Bangladesh and is recognized
                as the largest Computer and Tech retailer. Zarif Communications has over 40 employees and is growing
                more and more, working diligently to fulfill the Main Criteria of Zarif Communication's Motto or
                Vision. Zarif Communications is located in Mymensingh. Zarif Communications has a total of 1 Physical
                outlets all over the country; selling genuine Tech products. Among them, 1 outlets are in Mymensingh as
                it's the Division of Bangladesh. We also have our successful E-Commerce website.
            </p>
        </div>
    </div>
    <div class="row" style="margin-top: 2rem; margin-bottom: 2rem">
        <div class="col-md-6">
            <h1 class="about-us-title">The Main Goal and Aim</h1>
            <p class="about-us-desc">
                We are <span>Zarif Communications</span>, and we are here to help you with all your technology needs. We aim to
                provide all the requirements of our customers and help them satisfy their needs, wants, and desires. We
                delight in seeing our customers happy and satisfied with our resiliency in providing them with their
                products.
            <p class="about-us-desc">
                Our complete focus is on the customers. We keep tabs and records on what our customers want,
                and we try our best to bring that to them. We are already providing our customers with a delivery system
                so that they can order online and receive their products from their area. They do not have to travel
                long distances to get their desired product.
            </p>
        </div>
        <div class="col-md-6">
            <div>
                <img class="img-responsive" src="assets/svg-images/goal.svg" alt="Goal"
                    loading="lazy" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div>
                <img class="img-responsive" src="assets/svg-images/services.svg" alt="Services"
                    loading="lazy" />
            </div>
        </div>
        <div class="col-md-6">
            <h1 class="about-us-title">Services Being Provided</h1>
            <p class="about-us-desc">
                We are a Tech-based product seller. We provide our customers with the best quality products at the most
                reasonable price. We have varieties of products that our customers can choose from. The product range
                starts from Desktop PC, Laptop, Gaming PC, Mobile Phones, UPS, Tablet PC, Graphics Tablet, Monitors,
                Office Equipment, Cameras, Security Cameras, Televisions, and many other products. Each of our products
                is checked and reviewed before it is sold to our Loyal Customers. You are our driving force to better
                ourselves in all aspects of the service-providing sector. We strive to become a Perfectionist Company
                that delivers everything, word for word.
            </p>
        </div>
    </div>
    <div class="row" style="margin-top: 2rem; margin-bottom: 2rem">
        <div class="col-md-6">
            <h1 class="about-us-title">Top-Selling Brands</h1>
            <p class="about-us-desc">
                We have many top-selling brands that gained our attention to focus on them. These brands are Antec,
                Asrock, Bitfenix, Cryorig, Deli, EKWB, ESET, Galax, Gamdias, GEiL, Infocus, KWG, Lian Li, MaxGreen,
                Noctua, Razer, RnM, Team, XFX, Zyxel to name a few. As being top-selling and demanding brands, you will
                be able to get the latest updated products and service facilities more. You will also get better
                after-sales service from us. If any trouble occurs with these brand products, we will be able to solve
                it very easily. After fixing the problem you will be able to get the product in pristine condition just
                like if it is still new. These Brand Products are very high-quality products that provide the best
                service to the customers. We ensure that you are getting the best quality product. You can freely buy
                top-selling Brand products without having to think twice about what you are buying.
            <p class="about-us-desc">
                We also provide our
                customers with the best pricing for the products compared to anywhere in Bangladesh. You can stay easy
                and relax knowing that one of our goals is to provide the customer with the best product at the most
                reasonable pricing. We ensure that our customers are satisfied with our product and the pricing.
            </p>
        </div>
        <div class="col-md-6">
            <div>
                <img class="img-responsive" src="assets/svg-images/top-selling.svg" alt="Top Selling"
                    loading="lazy" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div>
                <img class="img-responsive" src="assets/svg-images/top-brand.svg" alt="Top Brand"
                    loading="lazy" />
            </div>
        </div>
        <div class="col-md-6">
            <h1 class="about-us-title">Customer Satisfaction</h1>
            <p class="about-us-desc">
                We have been in the market for a long time, and we have come to know what the customers want and desire.
                We have made changes around our customers so that we will be able to fulfill the desires of each of our
                customers. We want to improve more and more to be able to give everyone their desired or dreamed
                products. We are providing online buying opportunities for our customers, and providing delivery service
                for all of our products all over Bangladesh. We provide the best after sells customer service to our
                customers to make them feel that we do care about their possession and provide them with the best
                solutions for their problems. </p>
        </div>
    </div>
    <div class="my-5 row justify-content-center">
        <h1 class="about-us-title" style="text-decoration: underline;text-underline-offset: 10px;">The Brand That Cares For You</h1>
        <p class="about-us-desc py-3">
            This is <span>ZARIF COMMUNICATIONS!</span> A Brand that is Truly concerned about its customers and loyally provides all
            the needs of the customers. Customers come first to this Company. Our customers will receive the best
            service and deals that Zarif Communications offers. To us, our customer's wants and needs take the top
            priority. We always have and will aim to provide the perfect result to our loyal customers. And our
            after-sales service will ensure that no one of our customers will come to us with the same issue twice. Come
            and Experience the service, product, and facilities Zarif Communications offers.</p>
    </div>
</div>

  <!-- Proprietor -->
  <!-- <div class="container">
    <div class="w-100 management-heading text-center mt-5">
        <h4>
          Our Proprietor
        </h4>
      </div>
    <div class="mb-5 px-3">
        <div class="row justify-content-center">
          <div class="col-md-12 col-lg-6">
            <div class="management px-0 align-items-center">
              <div class="mb-3 bg-white">
                <img class="img-fluid management-image" src="assets/images/proprietor.jpg" alt="Kaniz Fathama" loading="lazy" />
              </div>
              <div>
                <h2 class="text-center" style="font-size: 20px">Kaniz Fathama</h2>
                <h4 class="text-center">PROPRIETOR</h4>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div> -->

<!-- Business Document -->
<div class="container" style="padding-top: 2rem; padding-bottom: 2rem">
    <div class="row justify-content-center">
        <h1 class="business-document-header">Business Information</h1>
      <div class="w-100 business-document-heading text-center mt-5">
        <h1>Tax Certificate</h1>
        <img class="img-responsive" src="assets/business-document/BIN.png" alt="Tax Certificate" loading="lazy" />
      </div>
      <div class="w-100 business-document-heading text-center mt-5">
        <h1>Tin Certificate</h1>
        <img class="img-responsive" src="assets/business-document/TIN.png" alt="Tin Certificate" loading="lazy" />
      </div>
      <div class="w-100 business-document-heading text-center mt-5">
        <h1>Trade Licence</h1>
        <img class="img-responsive" src="assets/business-document/Trade-Licence.png" alt="Trade Licence" loading="lazy" />
      </div>
    </div>
  </div>