import { ApiPaths } from './../../enums/api-paths.enum';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  baseUrl = environment.backEndBaseUrl;

  constructor(private httpRequest: HttpClient) {}

  fetchProductDetails(slug: string): any {
    let url = `${this.baseUrl}${ApiPaths.PRODUCT_DETAILS}${slug}`;
    return this.httpRequest.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    );
  }

  fetchSearchedItemData(name: string): any {
    let url = `${this.baseUrl}${ApiPaths.SEARCH_PRODUCTS}${name}`;

    return this.httpRequest.get(url).pipe(
      map((posts) => {
        return posts;
      })
    );
  }
}
