<!-- NOTE: Rafeen Bhai code (Don't remove) -->
<!-- <mat-sidenav-container>
    <mat-sidenav class="sidenav-container" #side [fixedInViewport]="true" mode="over" position="start" role="navigation"
        autoFocus=false>
        <app-side-nav></app-side-nav>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-header-nav></app-header-nav>
        <app-header-category [sidenav]="side" style="text-align: center;"></app-header-category>
        <div style="min-height: 100vh;">
            <router-outlet (activate)='onActivate($event)'></router-outlet>
        </div>
        <app-footer></app-footer>
        <app-scroll-to-top></app-scroll-to-top>
    </mat-sidenav-content>
    <div class="example-container">
		<button class="mine" mat-fab color="primary">
			<mat-icon>check</mat-icon>
		</button>
	</div>
</mat-sidenav-container> -->

<!-- NOTE: My (Saddam) code -->
<mat-sidenav-container>
  <mat-drawer-container>
    <mat-sidenav class="sidenav-container" #side [fixedInViewport]="true" mode="over" position="start" role="navigation"
      autoFocus="false">
      <app-side-nav></app-side-nav>
    </mat-sidenav>

    <header class="fixed-top">
      <app-header-nav></app-header-nav>
      <app-header-category [sidenav]="side" [menubar]="menu">
      </app-header-category>
      <app-ticker></app-ticker>
    </header>

    <mat-drawer #menu mode="over" style="height: 100%">
      <app-menu-bar [menubar]="menu"></app-menu-bar>
    </mat-drawer>

    <div class="main-container" #scrollContainer>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
    <!-- <div class="d-lg-none">
    <button
      [routerLink]="['cart']"
      mat-raised-button
      matBadge="{{ itemNumberInCart }}"
      matBadgeColor="warn"
      matBadgePosition="before"
      class="fab-cart-btn"
      mat-fab
    >
      <mat-icon
        height="25px"
        style="outline-style: none; color: #fff; background: none; padding: 0"
      >
        shopping_cart</mat-icon
      >
    </button>
  </div> -->
    <app-footer></app-footer>
  </mat-drawer-container>
</mat-sidenav-container>