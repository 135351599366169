import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Color } from '../../classes/color';

@Component({
  selector: 'app-products-price-sort',
  templateUrl: './products-price-sort.component.html',
  styleUrls: ['./products-price-sort.component.css']
})
export class ProductsPriceSortComponent implements OnInit {
  color = new Color();
  @Input() length: number;
  @Input() headerName: string;
  @Output() priceSortClick = new EventEmitter();

  constructor() { 
    this.length = 0;
    this.headerName = '';
  }

  ngOnInit(): void {
  }

  sortingProductListClick() {
    this.priceSortClick.emit();
  }

}
