export class Color {
  // $primaryBackground = '#eaeaea';
  $primaryBackground = '#056683';
  $secondaryBackground = '#e53935';
  $cardBackground = 'lavender';
  $purpleBackground = '#06417C';
  $purple = '#06417C';
  $lightPurple = '#9d45e7';
  $dangerFont = '#0F4D8B';
  $dangerBackground = '#ED2939';
  $dangerText = '#E40E45';
  $pink = '#fe59d7';
  $warning = '#FF8000';
}
