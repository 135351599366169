import { HomeBrandCardComponent } from './home/home-brand-card/home-brand-card.component';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from '../app-routing.module';
import { ItemResultsComponent } from './item-results/item-results.component';
import { CartComponent } from './cart/cart.component';
import { ProfileComponent } from './profile/profile.component';
import { ConfirmorderComponent } from './confirmorder/confirmorder.component';
import { ItemdetailsComponent } from './itemdetails/itemdetails.component';
import { CategoryproductsComponent } from './categoryproducts/categoryproducts.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BranchesComponent } from './branches/branches.component';
import { RefundReturnPolicyComponent } from './refund-return-policy/refund-return-policy.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { OfferItemsViewComponent } from './offer-items-view/offer-items-view.component';
import { PaymentprocessingComponent } from './paymentprocessing/paymentprocessing.component';
import { BrandsComponent } from './brands/brands.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CarouselSliderComponent } from './home/carousel-slider/carousel-slider.component';
import { TickerComponent } from './ticker/ticker.component';
import { HomeGroupComponent } from './home/home-group/home-group.component';
import { HomeGroupProductsComponent } from './home-group-products/home-group-products.component';
import { CategorizedProductsComponent } from './categorized-products/categorized-products.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { OfferItemsComponent } from './offer-items/offer-items.component';
import { BrandProductsComponent } from './brand-products/brand-products.component';
import { CartMobileCardComponent } from './cart/components/cart-mobile-card/cart-mobile-card.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { CheckoutMobileCardComponent } from './confirmorder/checkout-mobile-card/checkout-mobile-card.component';
import { CategoriesSliderComponent } from './categories-slider/categories-slider.component';
import { CompareComponent } from './compare/compare.component';

@NgModule({
  declarations: [
    HomeComponent,
    RegisterComponent,
    LoginComponent,
    ItemResultsComponent,
    CartComponent,
    ProfileComponent,
    ConfirmorderComponent,
    ItemdetailsComponent,
    CategoryproductsComponent,
    ScrollToTopComponent,
    ErrorPageComponent,
    AboutUsComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    BranchesComponent,
    RefundReturnPolicyComponent,
    OfferItemsViewComponent,
    PaymentprocessingComponent,
    BrandsComponent,
    CampaignsComponent,
    HomeBrandCardComponent,
    CarouselSliderComponent,
    TickerComponent,
    HomeGroupComponent,
    HomeGroupProductsComponent,
    CategorizedProductsComponent,
    BreadcrumbComponent,
    OfferItemsComponent,
    BrandProductsComponent,
    CartMobileCardComponent,
    CheckoutMobileCardComponent,
    CategoriesSliderComponent,
    CompareComponent,
  ],
  imports: [SharedModule, AppRoutingModule, NgxGalleryModule],
  exports: [
    SharedModule,
    AppRoutingModule,
    ScrollToTopComponent,
    ErrorPageComponent,
    HomeBrandCardComponent,
    HomeComponent,
  ],
})
export class FeatureModule {}
