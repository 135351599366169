<!-- Package documentation: https://www.npmjs.com/package/@ngbmodule/material-carousel -->
<!-- Package demo: https://gbrlsnchs.github.io/material2-carousel/ -->
<div *ngIf="isBrowser" class="row" style="margin-top: 1px">
    <!-- LARGE SCREEN -->
    <ng-container *ngIf="screenSize > 500">
      <div class="col-12">
        <mat-carousel
          [interval]="3000"
          color="warn"
          [slides]="8"
          slideHeight="150px"
          [hideIndicators]=false
          [hideArrows]=false
        >
          <mat-carousel-slide
            #matCarouselSlide
            *ngFor="let slide of offerImages; let i = index"
            [image]="sliderImageURL + slide.image_url"
            overlayColor="#00000040"
            [hideOverlay]="true"
          >
            <div
              style="height: 80%; width: 100%"
              (click)="handleOfferSliderClick(slide)"
            ></div>
          </mat-carousel-slide>
        </mat-carousel>
      </div>
    </ng-container>
    <!-- SMALL SCREEN -->
    <ng-container *ngIf="screenSize <= 500">
      <div class="col-12">
        <mat-carousel
          [interval]="3000"
          color="warn"
          slideHeight="150px"
          [slides]="8"
          [maintainAspectRatio]="false"
        >
          <mat-carousel-slide
            #matCarouselSlide
            *ngFor="let slide of offerImages; let i = index"
            [image]="
              slide.mobile_image
                ? sliderImageURL + slide.mobile_image_url
                : sliderImageURL + slide.image_url
            "
            overlayColor="#00000040"
            [hideOverlay]="true"
          >
            <div
              style="height: 80%; width: 100%"
              (click)="handleOfferSliderClick(slide)"
            ></div>
          </mat-carousel-slide>
        </mat-carousel>
      </div>
    </ng-container>
  </div>
  