import { map, retry, catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ApiPaths } from './../../enums/api-paths.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TickerService {
  baseUrl = environment.backEndBaseUrl;

  constructor(private http: HttpClient) {}

  fetchTickerData(): any {
    let url = `${this.baseUrl}${ApiPaths.TICKER}`;
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((posts) => {
        return posts;
      })
    );
  }
}
