import { ConfigurationService } from './services/configuration.service';
import { LoginCheckLazyGuard } from './services/guards/login-check-lazy.guard';
import { LoginCheckGuard } from './services/guards/login-check.guard';
import { AuthGuardsGuard } from './services/guards/auth-guards.guard';
import { DataStoreService } from './services/data-store.service';
import { ApirequestService } from './services/apirequest.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [ApirequestService, DataStoreService, AuthGuardsGuard,
    LoginCheckGuard, LoginCheckLazyGuard, ConfigurationService]
})
export class CoreModule { }
