<div class="container-fluid">
    <div style="text-align: center; margin: 2rem" *ngIf="error">
      <div class="alert alert-danger spinner-style">
        <h1>Error occur in the server</h1>
        <p>{{ error.statusText }}</p>
      </div>
    </div>
  
    <div
      style="text-align: center; margin: 2rem"
      *ngIf="!serverResponse && filteredItems && filteredItems.length === 0"
    >
      <div class="spinner-style">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  
    <ng-container
      *ngIf="serverResponse && filteredItems && filteredItems.length === 0"
    >
      <app-not-found
        [msg]="'No product associated with this campaign'"
      ></app-not-found>
    </ng-container>
  
    <div *ngIf="isUpcomming" class="not-found-container">
      <img src="assets/images/upcoming_offer.png" style="height: 20rem" class="img-fluid" loading="lazy" />
      <h1  class="not-found-msg">
        {{ isUpcommingText }}
      </h1>
    </div>
  
    <div *ngIf="serverResponse && filteredItems?.length !== 0 && !isUpcomming" class="row">
      <div class="col-lg-2">
        <div style="background: white; margin-top: 1rem">
          <div *ngIf="filterTitle" class="price-slider-container">
            <div>
              <h3 class="filter-title"> {{filterTitle}} </h3>
              <div style="display: flex; justify-content: center">
                <table
                  mat-table
                  [dataSource]="dataSource"
                  class="price-slider-table"
                >
                  <ng-container matColumnDef="from">
                    <mat-cell
                      style="padding-right: 5px"
                      *matCellDef="let element"
                      class="range-control"
                    >
                      <input
                        class="price-input-min"
                        type="number"
                        step="10"
                        [(ngModel)]="slider1"
                        disabled
                      />
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="range">
                    <mat-cell *matCellDef="let element" class="range">
                      <ngx-slider
                        [(value)]="slider1"
                        [(highValue)]="slider2"
                        [options]="{
                          floor: min,
                          ceil: max,
                          step: 1,
                          hideLimitLabels: true,
                          hidePointerLabels: true
                        }"
                      ></ngx-slider>
                    </mat-cell>
                  </ng-container>
  
                  <ng-container matColumnDef="to">
                    <mat-cell *matCellDef="let element" class="range-control">
                      <input
                        class="price-input-max"
                        type="number"
                        step="10"
                        [(ngModel)]="slider2"
                        disabled
                      />
                    </mat-cell>
                  </ng-container>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div *ngIf="filteredItems" class="col-lg-10">
        <div style="margin-top: 1rem">
          <app-products-price-sort
            (priceSortClick)="sortingProductList()"
            [length]="filteredItems.length"
            [headerName] ="headerText"
          ></app-products-price-sort>
        </div>

        <div *ngIf="filteredItems">
            <div class="product-container">
              <ng-container *ngFor="let itm of filteredItems">
                <app-product-card
                  *ngIf="
                    (itm.sale_price >= slider1 || itm.sale_price === slider1) &&
                    (itm.sale_price <= slider2 || itm.sale_price === slider2)
                  "
                  [itemData]="itm"
                >
                </app-product-card>
              </ng-container>
            </div>
        </div>
        <div *ngIf="description?.length !==0"
          class="description"
          [innerHTML]="description | safeHtml"
        ></div>
      </div>
    </div>
  </div>
  