<div class="container" style="padding-top: 2rem; padding-bottom: 2rem">
    <div class="row">
      <div class="col-md-12">
        <h1 class="privacy-title">
          <strong>Privacy Policy</strong>
        </h1>
        <p>This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally identifiable information’ (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>
        <br>
        <strong>What personal information do we collect from the people that visit our website or blog?</strong>
        <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience.</p>
        <strong>When do we collect information?</strong>
        <p>We collect information from you when you subscribe to a newsletter, respond to a survey, fill out a form or enter information on our site.</p>
        <strong>How do we use your information?</strong>
        <p>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
        <span>• To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.</span> <br>
        <span>• To improve our website in order to better serve you.</span> <br>
        <span>• To administer a contest, promotion, survey or other site feature.</span> <br>
        <span>• We use an SSL certificate</span> <br>
        <br><br>
        <strong>Google</strong>
        <p>Googles advertising requirements can be summed up by Google's Advertising Principles. They are put in place to provide a positive experience for users. Visit Here</p>
        <strong>How does our site handle do not track signals?</strong>
        <p>We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</p>
        <strong>Does our site allow third party behavioral tracking?</strong>
        <p>It's also important to note that we allow third party behavioral tracking.</p>
        <strong>COPPA (Children Online Privacy Protection Act)</strong>
        <p>When it comes to the collection of personal information from children under 13, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation’s consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online. We do not specifically market to children under 13.</p>
        <strong>CAN SPAM Act</strong>
        <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations. We collect your email address in order to deliver fresh content.</p>
        <p>To be in accordance with CANSPAM we agree to the following: If at any time you would like to unsubscribe from receiving future emails, you can email us at
            zarifcommunications.com and we will promptly remove you from ALL correspondence.
            </p><br><br>
        <strong>Contacting Us</strong>
        <p>If there are any questions regarding this privacy policy you may contact us using the information below.
            Zarif Communications .
            1st floor, 38/1 Ram Babu Road sadar,
            Mymensingh
            Phone:01914099661
            </p>
    </div>
</div>