import { environment } from './../../../environments/environment';
import { DataStoreService } from './../../core/services/data-store.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { ApirequestService } from 'src/app/core/services/apirequest.service';
import { demoItem } from 'src/app/core/model/category.model';
import { ConfigurationService } from './../../core/services/configuration.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {

  screenSize: any;
  slides: any;
  sliderImageURL = environment.backEndBaseMediaUrl;
  itemDetails = []
  tickerMessages :any;
  items;
  groups: { group_order: number; group_name: string; items: {order: number; item: demoItem; }[]; }[]  ;

  constructor(private apiService: ApirequestService, private dataService: DataStoreService,  private configUrl : ConfigurationService) { }

  ngOnInit(): void {
    this.screenSize = window.innerWidth;

    // this.categories = this.dataService.getCategory();
    // console.log(this.categories);
    // this.items = this.dataService.getItem();
    // console.log(this.items);



    // if (this.dataService.homeGroupItems){
    //   this.groups = this.dataService.homeGroupItems;
    // }
    // else{
    //     this.apiService.fetchGoupData()
    //     .subscribe((groupdata) => {
    //       console.log(groupdata);
    //       this.groups = groupdata;
    //       this.dataService.homeGroupItems = groupdata;
    //   });
    // }

    // this.apiService.fetchOfferData()
    //     .subscribe((offerData) => {
    //       console.log(offerData);
    //       console.log(this.sliderImageURL);
    //       this.slides = offerData;
          
    //       this.dataService.offerData = offerData;
    //   });

    }


  offerItemPageView()
  {
    alert("Hello Saddam Vai")
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = window.innerWidth;
  }


}
