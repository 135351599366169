<div class="container">
    <div class="row">
      <div class="col-12">
        <div style="margin-top: 1rem; text-align: center" *ngIf="loading">
          <app-spinner></app-spinner>
        </div>
        <span *ngIf="cardOfferData?.length === 0 && response">
          <div style="margin-top: 1rem">
            <div class="alert alert-primary" role="alert">
              No campaigns are available right now!
            </div>
          </div>
        </span>
        <span *ngIf="cardOfferData?.length !== 0 && response">
          <div class="camp-title-container">
            <h1
              class="camp-title"
              [ngStyle]="{ borderColor: color.$primaryBackground }"
            >
              CAMPAIGN
            </h1>
          </div>
          <div class="row">
            <div
              *ngFor="let offerCard of cardOfferData; let i = index"
              [ngClass]="{
                'col-md-6': i === 0,
                'col-md-3': 0 < i && i <= 2,
                'col-md-4': i >= 3
              }"
              class="mb-4"
            >
              <app-offer-card [itemData]="offerCard"></app-offer-card>
            </div>
          </div
        ></span>
      </div>
    </div>
  </div>
  