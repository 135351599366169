<div class="row history-product-container">
  <div class="col-lg-12">
    <div class="mb-1 mt-1">
      <h5 class="mb-1" style="font-weight: 400">
        {{ itemData.item }}
      </h5>
      <div style="display: flex; justify-content: space-between" class="col-12">
        <p class="unit-price mb-0 text-muted text-uppercase">
          ৳ {{ itemData.unit_price | number }} X
          {{ itemData.quantity_in_unit }}
        </p>
        <div
          class="bottom-container d-flex justify-content-between align-items-end"
        >
          <h5 class="mb-0" style="color: #b12704">
            ৳ {{ itemData.item_total_price | number }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
