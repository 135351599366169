<div class="container-fluid" style="padding-top: 10px;">
    <app-carousel-slider></app-carousel-slider>
    <div *ngIf="screenSize > 500">
        <app-offer-cards></app-offer-cards>
    </div>
    <app-categories-slider></app-categories-slider>
    <app-home-group></app-home-group>
    <app-home-brand-card></app-home-brand-card>
</div>







