import { BrandProductsComponent } from './feature/brand-products/brand-products.component';
import { OfferItemsComponent } from './feature/offer-items/offer-items.component';
import { CategorizedProductsComponent } from './feature/categorized-products/categorized-products.component';
import { HomeGroupProductsComponent } from './feature/home-group-products/home-group-products.component';
import { CampaignsComponent } from './feature/campaigns/campaigns.component';
import { BrandsComponent } from './feature/brands/brands.component';
import { RefundReturnPolicyComponent } from './feature/refund-return-policy/refund-return-policy.component';
import { PrivacyPolicyComponent } from './feature/privacy-policy/privacy-policy.component';
import { BranchesComponent } from './feature/branches/branches.component';
import { TermsAndConditionComponent } from './feature/terms-and-condition/terms-and-condition.component';
import { AboutUsComponent } from './feature/about-us/about-us.component';
import { LoginCheckLazyGuard } from './core/services/guards/login-check-lazy.guard';
import { ErrorPageComponent } from './feature/error-page/error-page.component';
import { ItemdetailsComponent } from './feature/itemdetails/itemdetails.component';
import { CategoryproductsComponent } from './feature/categoryproducts/categoryproducts.component';
import { LoginCheckGuard } from './core/services/guards/login-check.guard';
import { ConfirmorderComponent } from './feature/confirmorder/confirmorder.component';
import { AuthGuardsGuard } from './core/services/guards/auth-guards.guard';
import { ProfileComponent } from './feature/profile/profile.component';
import { CartComponent } from './feature/cart/cart.component';
import { RegisterComponent } from './feature/register/register.component';
import { HomeComponent } from './feature/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './feature/login/login.component';
import { ItemResultsComponent } from './feature/item-results/item-results.component';
import { OfferItemsViewComponent } from './feature/offer-items-view/offer-items-view.component';
import { PaymentprocessingComponent } from './feature/paymentprocessing/paymentprocessing.component';
import { OrderHistoryDetailsComponent } from './feature/profile/order-history-details/order-history-details.component';
import { CompareComponent } from './feature/compare/compare.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', canActivate: [LoginCheckGuard], component: LoginComponent },
  {
    path: 'register',
    canActivate: [LoginCheckGuard],
    component: RegisterComponent,
  },
  { path: 'compare', component: CompareComponent },
  { path: 'cart', component: CartComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'terms-and-condition', component: TermsAndConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'branches', component: BranchesComponent },
  { path: 'offerItemsView', component: OfferItemsViewComponent },
  { path: 'refund-return', component: RefundReturnPolicyComponent },
  { path: 'paymentprocessing', component: PaymentprocessingComponent },
  // {path: 'test-order-history-details', component: TestOrderHistoryDetailsComponent},
  {
    path: 'profile',
    component: ProfileComponent,
    canLoad: [LoginCheckLazyGuard],
    canActivate: [AuthGuardsGuard],
    loadChildren: () =>
      import('./feature/profile/profile.module').then((m) => m.ProfileModule),
    // , children : [
    // {path : 'myprofile', canActivate: [] , component: MyprofileComponent, outlet: 'profileOutlet'},
    // {path : 'orderhistory', canActivate: [] , component: OrderhistoryComponent, outlet: 'profileOutlet'},

    // ]
  },
  {
    path: 'confirm-order',
    canActivate: [AuthGuardsGuard],
    component: ConfirmorderComponent,
  },
  // {path : 'myprofile', canActivate: [] , component: MyprofileComponent},
  {
    path: 'search/:name',
    component: ItemResultsComponent,
    data: { title: 'Search Result(s)' },
  },
  { path: 'offerItemsView/:name', component: OfferItemsViewComponent },
  { path: 'itemdetails/:id', component: ItemdetailsComponent },
  {
    path: 'order-history-details/:orderNumber',
    component: OrderHistoryDetailsComponent,
  },
  // {path : ':name', component: CategoryproductsComponent},
  { path: 'brands', component: BrandsComponent },
  { path: 'campaigns', component: CampaignsComponent },
  { path: 'home-group/:groupId', component: HomeGroupProductsComponent },
  { path: 'category/:categorySlug', component: CategorizedProductsComponent },
  { path: ':categorySlug', component: CategorizedProductsComponent },
  { path: 'brands/:brandSlug', component: BrandProductsComponent },
  { path: 'product-details/:productSlug', component: ItemdetailsComponent },
  { path: 'campaign/:offerSlug', component: OfferItemsComponent },
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
