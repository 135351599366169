import { retry, catchError, map } from 'rxjs/operators';
import { ApiPaths } from './../../enums/api-paths.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  baseUrl = environment.backEndBaseUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getSearchResults(searchKey: string): any {
    let url = `${this.baseUrl}${ApiPaths.SEARCH}${searchKey}`;
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((searchResult: any) => {
        return searchResult;
      })
    );
  }
}
