import { Component, OnInit } from '@angular/core';
import { BrandService } from 'src/app/core/services/brand.service';

@Component({
  selector: 'app-home-brand-card',
  templateUrl: './home-brand-card.component.html',
  styleUrls: ['./home-brand-card.component.css']
})
export class HomeBrandCardComponent implements OnInit {
  home_title = {
    title: 'Shop by brands',
    component: 'brands',
  };
  categoryCardsData: any;

  constructor(private brandService: BrandService) { }

  ngOnInit(): void {
    this.brandService.getHomeBrands().subscribe((brands: any) => {
      this.categoryCardsData = brands;
    })
  }

}
