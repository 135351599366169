import { retry, catchError, map } from 'rxjs/operators';
import { ApiPaths } from 'src/app/enums/api-paths.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  baseUrl = environment.backEndBaseUrl

  constructor(private httpRequest: HttpClient) {}

  getVoucher(voucherData: any): any {
    let token = localStorage.getItem('token');
    let url = `${this.baseUrl}${ApiPaths.VOUCHER}`;
    return this.httpRequest.post(url, voucherData).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((res) => {
        return res;
      })
    );
  }
}
