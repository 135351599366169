import { GoogleAnalyticsService } from './../../../core/services/analytics/google-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from './../../../../environments/environment';
import { OfferApiService } from './../../../core/services/offer-api.service';
import { Component, OnInit, PLATFORM_ID, Inject, HostListener } from '@angular/core';

@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.css']
})
export class CarouselSliderComponent implements OnInit {
  offerImages: any;
  isBrowser: boolean;
  screenSize: any;

  sliderImageURL = environment.backEndBaseMediaUrl;

  constructor(
    private offerApi: OfferApiService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  ngOnInit(): void {
    this.screenSize = window.innerWidth;
    this.offerApi.getSilderOffers().subscribe((offers: any) => {
      this.offerImages = offers;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = window.innerWidth;
  }

  handleOfferSliderClick(offer: any) {
    if (offer.offer_type !== 0) {
      this.router.navigate(['campaign', offer.slug]);
    }
  }

}
