import { MatSnackBar } from '@angular/material/snack-bar';
// import { TrackOrderPopupComponent } from './../../../../shared/components/track-order-popup/track-order-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { OrderService } from 'src/app/core/services/order.service';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Params } from '@angular/router';
import { MatRadioChange } from '@angular/material/radio';
import { ConfigurationService } from 'src/app/core/services/configuration.service';

@Component({
  selector: 'app-order-history-details',
  templateUrl: './order-history-details.component.html',
  styleUrls: ['./order-history-details.component.css'],
})
export class OrderHistoryDetailsComponent implements OnInit {
  columns: string[] = [
    'Name',
    'Unit Price',
    'Quantity',
    'Status',
    'Total Price',
  ];
  columnsMobile: string[] = ['name', 'quantity', 'totalPrice'];
  dataSource: any[] = [];
  mobile = false;
  orderHistoryDetails: any;
  orderDetails: any;
  orderItems: any;
  temp: any;
  totalPrice = 0;
  searchName: any;
  paramsubs: any;
  isCancelAllDisabled: boolean = false;
  errorOccurred: boolean = false;
  isReinitiatePaymentMode: boolean = false;
  forReinitiatePayment: boolean = false;
  reinitiatePaymentMode: string = '';
  partialPaymentMode: string = '';
  eligibleForPartialPayment: boolean = false;
  paymentButtonDisable: boolean = true;

  sslPayment: boolean;
  bKashPayment: boolean;
  cashOnDelivery: boolean;

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  groupBy = (key: any) => (array: any) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj.item[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, []);

  constructor(
    private route: ActivatedRoute,
    private orderService: OrderService,
    public matDialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: object,
    private snackBar: MatSnackBar,
    private _configuration: ConfigurationService

  ) {}

  ngOnInit(): void {



    this.route.queryParams.subscribe((res) => {
      this.forReinitiatePayment = res.reinitiate_payment
        ? res.reinitiate_payment.toLocaleLowerCase() === 'true'
        : false;

      if (this.forReinitiatePayment){
        this._configuration.getConfiguration('payment-config').subscribe(
          (configurationRes) => {
            const {
              ssl_payment: sslPayment,
              bkash_payment: bKashPayment,
              cash_on_delivery: cashOnDelivery,
            } = configurationRes;
            this.sslPayment = sslPayment;
            this.bKashPayment = bKashPayment;
            this.cashOnDelivery = cashOnDelivery;
          },
          (errorRes) => {
            const { success: isSuccess, message } = errorRes.error;
            if (!isSuccess) {
              this.snackBar.open(message, 'X', {
                duration: 2000,
              });
            }
          }
        );
      }
    });
    if (window.screen.width <= 500) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.searchName = this.route.snapshot.params.orderNumber;
    this.paramsubs = this.route.params.subscribe((params: Params) => {
      this.searchName = this.route.snapshot.params.orderNumber;
      this.orderService.getOrderHistoryDetails(this.searchName).subscribe(
        (post: any) => {
          this.orderHistoryDetails = post;
          if (
            (this.orderHistoryDetails.payment_method === 'bKash' ||
              this.orderHistoryDetails.payment_method === 'digital_payment') &&
            this.orderHistoryDetails.status !== 'Cancelled' &&
            this.orderHistoryDetails.prepayment_amount !==
              this.orderHistoryDetails.total_amount &&
            this.forReinitiatePayment //false
          ) {
            //reinitiate payment
            this.isReinitiatePaymentMode = true;
          } else {
            // order history details
            this.isReinitiatePaymentMode = false;
          }
          this.orderDetails = post;
          this.orderItems = post.items;
          this.orderItems = this.orderItems.map((itm: any) => {
            return {
              ...itm,
              price: itm.offer_price === 0 ? itm.sale_price : itm.offer_price,
            };
          });
          this.isCancelAllDisabled =
            this.orderHistoryDetails.status === 'Cancelled' ||
            this.orderHistoryDetails.status === 'Completed';
        },
        (error: any) => {
          if (error.status === 404) {
            this.errorOccurred = true;
          }
        }
      );
    });
  }
  openTrackOrderDialog() {
    // this.matDialog.open(TrackOrderPopupComponent);
  }
  // handle payment option change
  changeReinitiatePaymentMethod(event: MatRadioChange) {
    this.reinitiatePaymentMode = event.value;
    if (
      this.orderHistoryDetails.prepayment_amount == 0 &&
      this.reinitiatePaymentMode !== ''
    ) {
      this.eligibleForPartialPayment = true;
    } else {
      this.eligibleForPartialPayment = false;
      this.paymentButtonDisable = false;
    }
  }

  // handle partial payment change
  changePartialPaymentMethod(event: MatRadioChange) {
    this.partialPaymentMode = event.value;
    if (this.eligibleForPartialPayment && this.partialPaymentMode !== '') {
      this.paymentButtonDisable = false;
    }
  }

  // handle proceed to payment
  handleReinitiatePayment() {
    // decide payment amount
    let paymentAmount = this.partialPaymentMode === 'full-payment' ? 100 : 10;
    if (
      this.orderHistoryDetails.prepayment_amount !=
        this.orderHistoryDetails.total_amount &&
      this.orderHistoryDetails.prepayment_amount != 0 &&
      this.searchName !== ''
    ) {
      // not fully paid before
      this.orderService
        .paymentReinitiate(this.searchName, this.reinitiatePaymentMode)
        .subscribe(
          (post: any) => {
            if (post.success) {
              isPlatformBrowser(this.platformId) &&
                localStorage.setItem('session', post.session);
              window.location.href = post.gateway;
            }
          },
          (error: any) => {
            this.snackBar.open('Error occurs in the server', 'X', {
              duration: 2000,
            });
          }
        );
    } else {
      // partial payment
      this.orderService
        .paymentReinitiate(
          this.searchName,
          this.reinitiatePaymentMode,
          paymentAmount
        )
        .subscribe(
          (post: any) => {
            if (post.success) {
              isPlatformBrowser(this.platformId) &&
                localStorage.setItem('session', post.session);
              window.location.href = post.gateway;
            }
          },
          (error: any) => {
            this.snackBar.open('Error occurs in the server', 'X', {
              duration: 2000,
            });
          }
        );
    }
  }
}
