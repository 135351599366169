import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../core/services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  //-----------------

  mobileInput = true;
  userAuthentication = true;
  passwordInput = false;
  forgetEmailInput = false;
  forgetOtpInput = false;
  newPasswordInput = false;

  //-----------------

  wrongEmail = false;
  otpMsg = '';
  notSamePassword = false;
  updatePassMsg = '';
  isPasswordCorrect = true;

  //---------------------

  loginForm: FormGroup;
  loginFormForPasswordChange: FormGroup;
  mobilePattern = '01[3-9][0-9]{8}';
  passwordPattern = '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{3,}';
  emailPattern = '^w+@[a-zA-Z_]+?.[a-zA-Z]{2,3}';

  mobileNumber = 'm';
  passwordGiven = 'p';
  passwordHide = true;
  passwordHide2 = true;

  disable = true;
  emailForForgattingPassword = false;
  passwordValidationMessage = '';
  showPasswordValidationMessage = false;
  metaTitle: string = 'Login';

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar,
    public title: Title,
  ) {}

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      mobile: [
        null,
        [Validators.required, Validators.pattern(this.mobilePattern)],
      ],
      password: [null, [Validators.required]],
      emailForForgattingPassword: [null, [Validators.required]],
      forgetOtpInput: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
    });
    this.setTitle(this.metaTitle);
  }

  onSubmit() {
    if (!this.loginForm.valid) {
      return;
    }
    this.mobileNumber = this.loginForm.value.mobile;
    this.passwordGiven = this.loginForm.value.password;
  }

  // check whether the user has already registered or not
  isRegisteredUser() {
    let phoneNo = this.loginForm.controls.mobile;
    if (phoneNo.valid) {
      localStorage.setItem('mobile', phoneNo.value);
      this.authService.login(phoneNo.value).subscribe(
        (customerExistRes) => {
          const { account_exists: accountExists, token: phone } =
            customerExistRes;

          if (accountExists) {
            // localStorage.setItem('token', phone);
            this.passwordInput = true;
            this.userAuthentication = true;
            this.mobileInput = false;
          }
        },
        (errorRes) => {
          const {
            error: { account_exists: isAccountExists },
          } = errorRes;
          if (!isAccountExists) {
            this.userAuthentication = false;
          }
        }
      );
    }
  }

  passwordAuthentication() {
    if (this.loginForm.controls.password.valid) {
      this.authService
        .passwordAuthenticate(this.loginForm.controls.password.value)
        .subscribe(
          (passAuthRes) => {
            const { success: isSuccess, token } = passAuthRes;
            if (isSuccess) {

              this.isPasswordCorrect = true;
              this.router.navigate(['']);
              this.snackBar.open('You are successfully logged in', 'X', {
                duration: 1200,
                horizontalPosition: 'start',
                verticalPosition: 'bottom',
              });

              if (this.authService.url === '/login') {
                this.router.navigate(['']);
              }
            }
          },
          (errorRes) => {
            const { success: isSuccess, message } = errorRes.error;
            if (!isSuccess) {
              this.passwordValidationMessage = message;
              this.showPasswordValidationMessage = true;
            }
          }
        );
    }
  }

  getEmailForForgattingPassword() {
    this.passwordInput = false;
    this.forgetEmailInput = true;
  }

  // send OTP for password reset
  GetOTP() {
    this.wrongEmail = false;
    const email = this.loginForm.controls.emailForForgattingPassword;

    if (email.valid) {
      this.authService.SendOTP(email.value, false).subscribe(
        (sendOTPRes) => {
          const { success: isSuccess, message } = sendOTPRes;
          if (isSuccess) {
            this.forgetEmailInput = false;
            this.forgetOtpInput = true;
          }
        },
        (errorRes) => {
          const { success: isSuccess, message } = errorRes.error;
          if (!isSuccess) {
            this.wrongEmail = true;
            this.passwordValidationMessage = message;
          }
        }
      );
    }
  }

  setNewPassword() {
    if (this.loginForm.controls.forgetOtpInput.valid) {
      const email = this.loginForm.controls.emailForForgattingPassword.value;
      const otp = this.loginForm.controls.forgetOtpInput.value;
      this.authService.CheckOTP(email, otp, false).subscribe(
        (checkOTPres) => {
          const { success: isSuccess, message } = checkOTPres;
          if (isSuccess) {
            this.forgetOtpInput = false;
            this.newPasswordInput = true;
            this.otpMsg = message;
          }
        },
        (errorRes) => {
          const { message, success: isSuccess } = errorRes.error;
          if (!isSuccess) {
            this.otpMsg = message;
          }
        }
      );
    }
  }

  letHimLogIn() {
    var newPassword = this.loginForm.controls.newPassword.value;
    var confirmNewPassword = this.loginForm.controls.confirmPassword.value;

    if (newPassword === confirmNewPassword) {
      this.notSamePassword = false;

      this.authService.UpdateNewPassword(newPassword).subscribe(
        (updatePassRes) => {
          const { success: isSuccess, message } = updatePassRes;
          if (isSuccess) {
            this.updatePassMsg = message;

            this.newPasswordInput = false;
            this.userAuthentication = true;
            this.mobileInput = true;
            this.snackBar.open('Password successfully changed!', 'X', {
              duration: 1200,
              horizontalPosition: 'start',
              verticalPosition: 'bottom',
            });
          }
        },
        (errorRes) => {
          const { success: isSuccess, message } = errorRes.error;
          if (!isSuccess) {
            this.updatePassMsg = message;
          }
        }
      );
    } else {
      this.updatePassMsg = 'New password and confirm new password are not same';
    }
  }

  backButtonPressed() {
    if (this.passwordInput) {
      //--------Mobile Input-------
      this.mobileInput = true;
      this.userAuthentication = true;
      this.passwordInput = false;
      this.forgetEmailInput = false;
      this.forgetOtpInput = false;
      this.newPasswordInput = false;
    } else if (this.forgetEmailInput) {
      //--------Password Input-------
      this.mobileInput = false;
      this.userAuthentication = true;
      this.passwordInput = true;
      this.forgetEmailInput = false;
      this.forgetOtpInput = false;
      this.newPasswordInput = false;
    } else if (this.forgetOtpInput) {
      //--------Forget eMail Input-------
      this.mobileInput = false;
      this.userAuthentication = true;
      this.passwordInput = false;
      this.forgetEmailInput = true;
      this.forgetOtpInput = false;
      this.newPasswordInput = false;
    } else if (this.newPasswordInput) {
      //--------Mobile Input-------
      this.mobileInput = true;
      this.userAuthentication = true;
      this.passwordInput = false;
      this.forgetEmailInput = false;
      this.forgetOtpInput = false;
      this.newPasswordInput = false;
    } else {
      //--------Mobile Input-------
      this.mobileInput = true;
      this.userAuthentication = true;
      this.passwordInput = false;
      this.forgetEmailInput = false;
      this.forgetOtpInput = false;
      this.newPasswordInput = false;
    }
  }
}
