<div class="container">
    <div class="row my-3">
        <div class="col-md-3">
            <div class="profile-container">
                <div class="profile-nav-items">
                      <!-- <mat-selection-list [multiple]="false">
                        <mat-list-option [routerLink]="['myprofile']" >My Account</mat-list-option>
                        <mat-list-option [routerLink]="['orderhistory']" >My Orders</mat-list-option>
                        <mat-list-option [routerLink]="['']" (click)="onLogout()">Logout</mat-list-option>
                      </mat-selection-list> -->
                      <mat-list>
                        <mat-list-item style="cursor: pointer;" [routerLink]="['myprofile']">My Account</mat-list-item>
                        <mat-list-item style="cursor: pointer;" [routerLink]="['orderhistory']" >My Orders</mat-list-item>
                        <mat-list-item style="cursor: pointer;" [routerLink]="['']" (click)="onLogout()">Logout</mat-list-item>
                      </mat-list>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
    </div>
</div>