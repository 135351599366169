import { ApiPaths } from './../../enums/api-paths.enum';
import { HttpClient } from '@angular/common/http';
import { catchError, retry, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  baseUrl = environment.backEndBaseUrl;

  constructor(private http: HttpClient) { }

  getBrands():any {
    let url = `${this.baseUrl}${ApiPaths.BRANDS}`
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((brands) => {
        return brands;
      })
    )
  }

  getHomeBrands():any {
    let url = `${this.baseUrl}${ApiPaths.HOME_BRAND}`
    return this.http.get(url).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((brands) => {
        return brands;
      })
    )
  }

  getBrandedProducts(brandId:number,categoryId:number=0):any {
    let mainUrl='';
    if (categoryId ===0){
      mainUrl = `items?brand=${brandId}`
    }
    else{
      mainUrl = `items/?brand=${brandId}&category=${categoryId}`
    }
    return this.http.get(environment.backEndBaseUrl + mainUrl).pipe(
      retry(1),
      catchError((err) => {
        return throwError(err);
      }),
      map((brandedProducts) => {
        return brandedProducts;
      })
    )
  }
}
