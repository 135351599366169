import { isPlatformBrowser } from '@angular/common';
import { CategoryService } from './../../core/services/category.service';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-categories-slider',
  templateUrl: './categories-slider.component.html',
  styleUrls: ['./categories-slider.component.css']
})
export class CategoriesSliderComponent implements OnInit {

  categoryCardsData: any;
  isBrowser: boolean;
  screenSize: any;

  constructor(
    private categoryApi: CategoryService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) { 
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.screenSize = window.innerWidth;
    this.categoryApi.getCategoryCards().subscribe((categoryCard: any) => {
      this.categoryCardsData = categoryCard;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = window.innerWidth;
  }

}
