import { Color } from 'src/app/shared/classes/color';
import { CategoryDataService } from './../../core/services/storage/category-data.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CategoryService } from 'src/app/core/services/category.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  color = new Color;
  @Input() categorySlug: any;
  @Output() btnClick = new EventEmitter();
  raw_category: any;
  raws: any = [];
  childCategory: any;

  constructor(
    private router: Router,
    private categoryDataService: CategoryDataService,
    private categoryApi: CategoryService,
  ) { }

  ngOnInit(): void {
    this.raw_category = this.categoryDataService.rawCategories;
    this.generate(this.categorySlug);
    for (let i = 0; i < this.raw_category?.length; i++) {
      // if (this.raw_category[i].parent === this.categoryId) {
      if (this.raw_category[i].slug === this.categorySlug) {
        this.childCategory = this.raw_category[i].children;
      }
    }
  }
  generate(id: any) {
    for (let i = 0; i < this.raw_category?.length; i++) {
      if (this.raw_category[i].slug === id) {
        this.raws.push(this.raw_category[i]);
        if (this.raw_category[i].parent !== null) {
          for (let j = 0; j < this.raw_category?.length; j++) {
            if (this.raw_category[j].id === this.raw_category[i].parent){
              this.generate(this.raw_category[j].slug);  
              break;
            };
          };
          return;
        };
        return;
      };
    }
  }

  breadcrumbHandler(breadcrumb: any) {
    this.categoryApi.buttonClicked(breadcrumb);
  }

}
