<div *ngIf="raws.length !== 0" class="row">
  <div class="col-lg-12">
    <div class="page-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/']">Home</a>
          </li>
          <li *ngFor="let breadcrumb of raws.slice().reverse()" class="breadcrumb-item">
            <a (click)="breadcrumbHandler(breadcrumb.slug)" [routerLink]="['/category', breadcrumb.slug]">
              {{
              breadcrumb.name
              ? breadcrumb.name
              : breadcrumb.item_name
              }}</a>
          </li>
        </ol>
      </nav>
      <!-- HERE -->
    </div>

    <mat-chip-list>
      <span style="margin-bottom: 0.5rem" *ngFor="let childCat of childCategory; let i = index">
        <mat-chip color="accent" selected style="border-radius: 10px; cursor: pointer; background-color: #0F4D8B;"
          (click)="breadcrumbHandler(childCat.slug)">
          <a [routerLink]="['/', childCat.slug]"
            style="cursor: pointer; margin-bottom: 0; text-decoration: none; color: #fff;" for="sample">
            {{ childCat.name }}</a>
        </mat-chip>
      </span>
    </mat-chip-list>
  </div>
</div>