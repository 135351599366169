<div class="container pb-3 mb-container">
  <!-- <div *ngIf="!errorOccurred" class="row px-1"> -->
  <div *ngIf="!errorOccurred" class="px-1">
    <div class="col-12 mt-3">
      <!-- <div class="row">
        <div
          *ngIf="!isCancelAllDisabled && !isReinitiatePaymentMode"
          class="col-12"
          style="padding-right: 0"
        >
          <div class="order-cancel-btn-container">
            <a
              class="order-cancel-btn"
              mat-button
              color="primary"
              [routerLink]="['/cancel-order', searchName]"
            >
              Cancel order
            </a>
          </div>
        </div>
      </div> -->
      <div *ngIf="orderDetails" class="row order-info-container">
        <div class="col-12 order-info-title">
          <p class="order-info" style="color: #000254">Order Information</p>
        </div>
        <div class="col-md-6">
          <div class="order-info-data order-info-mb">
            <div class="order-info-data-row">
              <p class="order-info-text-left">Order Number :</p>
              <p
                style="color: #056683; font-weight: 400"
                class="order-info-text-right"
              >
                #{{ orderDetails.order_no }}
              </p>
            </div>
            <div class="order-info-data-row">
              <p class="order-info-text-left">Issues Date :</p>
              <p class="order-info-text-right">
                {{ orderDetails.created_at.slice(0, 10) }}
              </p>
            </div>
            <div class="order-info-data-row">
              <p class="order-info-text-left">Delivery Address:</p>
              <p class="order-info-text-right">
                {{ orderDetails.delivery_address }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="order-info-data">
            <div class="order-info-data-row">
              <p class="order-info-text-left">Total Amount :</p>
              <p
                class="order-info-text-right"
                style="color: #056683; font-weight: 400"
              >
                &#2547; {{ orderDetails.total_amount }}
              </p>
            </div>
            <div class="order-info-data-row">
              <p class="order-info-text-left">Paid amount:</p>
              <p
                class="order-info-text-right"
                style="color: #056683; font-weight: 400"
              >
                &#2547; {{ orderDetails.prepayment_amount }}
              </p>
            </div>
            <div class="order-info-data-row">
              <p class="order-info-text-left">Payment Type :</p>
              <p class="order-info-text-right">
                {{ orderDetails.payment_method }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="order-info-data-row">
            <p class="order-info-text-left">Status :</p>
            <p
              style="color: #056683; font-weight: 400"
              class="order-info-text-right"
            >
              {{ orderDetails.status }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Table view for Desktop -->
    <div class="desktop-view col-12 mb-2 px-0 mb-3">
      <app-sub-order-card [subOrder]="orderItems"></app-sub-order-card>
    </div>

    <!-- Mobile view -->
    <div class="mobile-view row">
      <span class="col-12">
        <div class="container" *ngFor="let item of orderItems">
          <app-order-history-details-mobile [itemData]="item"> </app-order-history-details-mobile>
        </div>
      </span>
    </div>
    <!-- maybe here -->
    <!--this section will visible when the component is in the payment mode -->
    <!-- <div *ngIf="isReinitiatePaymentMode" class="row"> -->
    <div *ngIf="isReinitiatePaymentMode">
      <div class="col-12" style="padding: 0">
        <h2 class="payment-title">Complete Your Payment</h2>
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="reinitiatePaymentMode"
        >
          <div class="payment-card-container">
            <div *ngIf="sslPayment" class="payment-card" style="margin-right: 30px">
              <div class="payment-image">
                <img
                  src="assets/payment-method-images/online-payment-icon.jpg"
                  loading="lazy"
                  alt="SSL payment"
                />
              </div>
              <div class="payment-text">
                <mat-radio-button
                  (change)="changeReinitiatePaymentMethod($event)"
                  value="digital-payment"
                >
                  Digital Payment
                </mat-radio-button>
              </div>
            </div>
            <div *ngIf="bKashPayment" class="payment-card">
              <div class="payment-image">
                <img
                  src="assets/payment-method-images/bkash-logo.png"
                  loading="lazy"
                  alt="SSL payment"
                />
              </div>
              <div class="payment-text">
                <mat-radio-button
                  (change)="changeReinitiatePaymentMethod($event)"
                  value="b-kash"
                >
                  bKash
                </mat-radio-button>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <!-- partial payment option -->
    <!-- <div class="d-block d-sm-none partial-sm-10"> -->
    <div class="partial-sm-10">
      <div *ngIf="eligibleForPartialPayment">
        <h2 class="payment-option">
          How much do you want to pay? Please select*
        </h2>

        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="partialPaymentMode"
        >
          <!-- <div style="padding-top: 0.5rem"> -->
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button
              (change)="changePartialPaymentMethod($event)"
              value="full-payment"
              >Full payment (100%)</mat-radio-button
            >
            <mat-radio-button
              (change)="changePartialPaymentMethod($event)"
              value="partial-payment"
              class="partial-sm-10"
              >Partial payment(10%)</mat-radio-button
            >
          </mat-radio-group>
          <!-- </div>? -->
        </mat-radio-group>
      </div>
    </div>
  </div>
  <!-- payment button -->
  <div *ngIf="isReinitiatePaymentMode" class="payment-btn">
    <!-- <div style="justify-content: flex-end; display: flex"> -->
    <button
      class="shop-checkout"
      [disabled]="paymentButtonDisable"
      mat-raised-button
      color="primary"
      (click)="handleReinitiatePayment()"
    >
      Proceed to Payment
    </button>
    <!-- </div> -->
  </div>
  <div
    *ngIf="errorOccurred"
    class="alert alert-warning"
    role="alert"
    style="margin-top: 8rem"
  >
    No order found regarding this order number!
  </div>
</div>
