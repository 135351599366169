<mat-menu style="min-height: 47px;" #childMenu="matMenu" [overlapTrigger]="false" yPosition="below">
    <!-- <button mat-menu-item [routerLink]="[name]">Show All</button> -->
    <!-- <mat-divider></mat-divider> -->
    <span *ngFor="let child of items">
      <!-- Handle branch node menu items -->
      <span *ngIf="child.children.length > 0">

        <button mat-menu-item color="primary" [mat-menu-trigger-for]="menu.childMenu"
        [routerLink]="[child.name]"
        >
        <!-- <mat-icon>{{child.iconName}}</mat-icon> -->
        <span style="padding-right: 1rem;">{{child.name}}</span>
      </button>
      <app-recursivemenu #menu [items]="child.children" [name]="child.name"></app-recursivemenu>
      </span>

      <!-- Handle leaf node menu items -->
      <span *ngIf="child.children.length === 0">

          <button mat-menu-item color="primary" [routerLink]="[child.name]">
            <!-- <mat-icon>{{child.iconName}}</mat-icon> -->
            <span>{{child.name}}</span>
          </button>
      </span>
    </span>
  </mat-menu>
