<!--Section: Block Content-->
<section>
    <div class="row">
      <!--Grid column-->
      <div class="col-lg-12">
        <!-- Card -->
        <div class="mb-1 mt-1">
          <div class="row m-0 p-1" style="border: 1px solid rgba(0, 0, 0, 0.1)">
            <div class="col-5">
              <div class="d-flex justify-content-center">
                <img
                  class="rounded"
                  src="{{ configURL_media }}{{ itemData.item.thumbnail }}"
                  alt="{{itemData.item.item_name}}"
                  loading="lazy"
                  height="100"
                />
              </div>
            </div>
  
            <div class="col-7">
              <h5 class="mb-1" style="font-weight: 400">
                {{ itemData.item.item_name }}
                <span *ngFor="let variant of itemData.item.variants">
                  <p *ngIf=" variant!==null" style="margin-top: 5px; padding-right: 1rem">
                    <span > <b>Variant name: </b> {{ variant.variation_name}}</span>
                  </p>
                </span>
              </h5>
              <div class="row mt-2">
                <div class="col-12 mb-2">
                  <p
                    *ngIf="itemData.item.offer_price === 0"
                    class="unit-price mb-0 text-muted text-uppercase"
                  >
                    ৳ {{ itemData.item.sale_price | number }} X
                    {{ itemData.quantity }}
                  </p>
                  <p
                    *ngIf="itemData.item.offer_price != 0"
                    class="unit-price mb-0 text-muted text-uppercase"
                  >
                    ৳ {{ itemData.item.offer_price | number }} X
                    {{ itemData.quantity }}
                  </p>
                  <!-- <div
                    class="
                      bottom-container
                      d-flex
                      justify-content-between
                      align-items-end
                    "
                  >
                    <h5 class="mb-0" style="color: #b12704">
                      ৳ {{ itemData.totalPrice | number }}
                    </h5>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
  
          <!-- <hr class="mb-4"> -->
        </div>
      </div>
    </div>
  </section>
  