<div class="container">
    <div class="row">
      <div class="col-12">
        <div
          style="text-align: center; margin-top: 1rem; text-transform: uppercase"
        >
          <h1
            style="
              font-family: inherit;
              font-weight: 700;
              line-height: 1.1;
              color: inherit;
              font-size: 24px;
              letter-spacing: 2px;
            "
          >
            Shop by Brands
          </h1>
          <div class="label"></div>
        </div>
        <div class="brand-container my-3">
          <div *ngFor="let categoryCardItem of categoryCardsData" class="h-100">
            <app-brand-card [brand]="categoryCardItem"></app-brand-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  